/* Popover */
.editEanPopover {
  .MuiPopover-paper {
    top: 43% !important;
    left: calc(78% / 2) !important;
    width: 22%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px !important;

    p {
      font-size: 14px;
      text-align: center;
    }
  }
}