.importedNfeHistoryDialog {
  .modalContent {
    &.onlyTable {
      table {
        thead {
          th {
            background: #313347;
            border-radius: 0;
            color: #FFF;

            &:first-of-type,
            &:last-of-type {
              border-radius: 0;
            }
          }
        }
      }
    }
  }

  .MuiDialog-paperWidthSm {
    max-width: 850px;
  }
}