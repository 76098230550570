/* Modals */
.orderModal {
  .tableTitle {
    font-family: "Lato";
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
  }

  table {
    td,
    th,
    input {
      font-size: 12px;
      font-family: "Lato";
    }

    hr {
      margin: 0;
    }
  }
}

/* New Order (Register) */
.newOrderTable {
  .materialTableDefault {
    td {
      div {
        button {
          padding: 0 0px 0px 3px;

          &:first-of-type {
            padding: 0 5px 0px 0;
            margin-left: -36px;
          }
        }
      }

      &.MuiTableCell-paddingNone {
        div {
          justify-content: flex-end;
        }
      }

      &.MuiTableCell-sizeSmall {
        div {
          button {
            &:first-of-type {
              margin-left: 0;
            }
          }
        }
      }
    }

    .MuiTableCell-paddingCheckbox {
      width: 6% !important;
    }
  }
}

/* Orders List */
.orderTabsBox {
  .defaultTab {
    border-radius: 4px 4px 0px 0px;
    padding: 5px 13px 5px;
    margin-bottom: -2px;
    background: #F2F2F4;
    color: #666770;
    margin-right: 5px;
    cursor: pointer;
    border: solid 1px #666770;
    border-bottom: none;

    p {
      font-weight: bold;
      font-family: "Lato";
      font-size: 12px;
    }

    &.active {
      background: #313347;
      color: #FFF;
      border: solid 1px #313347;
      border-bottom: none;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
}

/* Approve Orders List - Platform */
.approveOrdersListTable {
  nav {
    .MuiListItem-root {
      background: #F2F2F4;

      &:hover {
        background-color: rgba(80, 98, 240, 0.15);
      }
    }

    .orderModalItem {
      img,
      p {
        cursor: pointer;
      }
    }

    .MuiCollapse-container {
      &.opened {
        overflow: visible;
      }
    }
  }

  tr {
    .statusColumn {
      background: #F2F2F4;
      margin-top: -2px;
      cursor: pointer;
    }
    
    &:hover {
      .statusColumn {
        background-color: rgba(80, 98, 240, 0.15);
      }
    }
  }

  .refusedSkuAlert {
    background: #FCF5E3;
    border-radius: 4px;
    margin-left: 10px;
    padding: 0 5px;
    height: 4vh;
    display: flex;
    align-items: center;

    p {
      color: #996600;
      height: 100%;
      line-height: 2.1;
      font-weight: bold;
    }
  }
}

/* PopOver */
.approveOrderPopover {
  .MuiPopover-paper {
    padding: 20px !important;
    max-width: 440px !important;
  }
}

/* Restaurant orders Dialog */
.restaurantOrdersDialogTable {
  thead {
    tr {
      th {
        background: #313347;
        color: #FFF;
        padding-left: 16px !important;

        &:first-of-type {
          border-top-left-radius: 4px;
        }

        &:last-of-type {
          border-top-right-radius: 4px; 
        }
      }

      &:nth-of-type(2) {
        th {
          background: #F2F2F4;
          color: #666770;

          &:first-of-type {
            border-top-left-radius: 0;
          }

          &:last-of-type {
            border-top-right-radius: 0; 
          }
        }
      }
    }
  }

  td {
    font-size: 12px;
    background: #FFF;

    &.totalColumn {
      padding-bottom: 13px !important;
      padding-top: 10px !important;
    }
  }
}