.indicatorsPage {
  .headerTitle {
    .autocompleteSmall {
      .MuiInputBase-root {
        padding-bottom: 3px !important;
      }

      .MuiAutocomplete-endAdornment {
        top: 3px !important;
      }
    }
  }

  .MuiAutocomplete-popper {
    top: 29px;
    right: 0;
  }
}

.fullscreen {
  width: 99.8%;
  height: 100%;
  margin-left: 10px;
}