.closureAndSuggestionsPage {
  .cardBlueTitleDefault {
    display: flex;
    flex-direction: column;
    width: 30%;
    box-shadow: 0px 0px 8px rgba(4, 6, 26, 0.25);
    border-radius: 4px 4px 0 0;
    overflow: hidden;

    &:last-of-type {
      border-radius: 0 0 4px 4px;
    }

    .cardContent {
      background: #fff;
      padding: 12px;

      p {
        margin-bottom: 0;
        text-align: right;
      }

      .totalSalesInfo {
        margin-bottom: 10px;

        p {
          font-size: 16px;

          &:first-of-type {
            margin-bottom: 5px;
          }

          span {
            &:last-of-type {
              min-width: 25%;
              text-align: right;
            }
          }
        }
      }

      button {
        span {
          font-size: 12px;
        }

        &:disabled {
          background: #d0d3e2 !important;
          border: none !important;
          font-weight: bold;

          span {
            color: #666770;
          }
        }
      }

      .error {
        font-size: 12px;
        margin: 5px 0 15px 0;
      }

      .footerInfos {
        p {
          font-size: 12px;

          span {
            margin-left: 5px;
          }
        }
      }
    }

    &.closureCard {
      .footerInfos {
        margin-top: 10px;
      }
    }

    &.salesCard {
      .saleInfoLine {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        p {
          font-size: 16px;
          font-weight: 400;

          &:last-of-type {
            min-width: 25%;
            text-align: right;
            font-weight: bold;
          }
        }

        &.processStatus {
          margin-bottom: 12px;
        }
      }
    }

    &.suggestionsCard {
      button {
        margin: 0 0 0 auto;
      }
    }
  }
}
