.cockpitInfosPage {
  .cockpitDefaultContainer {
    display: flex;
    flex-direction: column;
  
    .cockpitDefaultInfoTitle {
      box-shadow: 8px 8px 8px 0px rgba(4, 6, 26, 0.3);
      border-radius: 10px;
      background: rgba(80, 98, 240, 1);
      padding: 5px 16px;
      margin-bottom: 16px;
  
      p {
        margin-bottom: 0;
        color: #FFF;
        font-weight: bold;
      }
    }

    .graphicContainer {
      padding: 0;
      display: flex;
      flex-direction: column;

      .graphicBox {
        display: flex;
        width: 100%;
        margin-bottom: 25px;

        &:last-of-type {
          margin-bottom: 0;
        }

        .graphicItem {
          padding: 5px 13px 30px 13px;
          width: 99%;
          height: 27.5vh;
          margin-right: 5.5vw;
          border-radius: 16px;
          background: #DADDF3;
          box-shadow: 8px 8px 8px 0px rgba(4, 6, 26, 0.30);
          
          &:last-of-type {
            margin-right: 0;
          }

          .graphicTitle {
            color: #0B1A8E;
            text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
            font-weight: 700;
            margin-bottom: 1vh;
            display: block;
            font-size: 16px;
            font-weight: bold;
            text-transform: uppercase;
          }

          .graphicTotal {
            color: #5062F0;
            font-weight: 700;
            font-size: 12px;
          }

          .graphValueTotal {
            color: #1F2445;
            font-weight: 700;
            font-size: 12px;
          }

          .graphicFilterTitle {
            color: #000319;
            font-size: 12px;
            line-height: 16px;
            font-weight: 700;
            margin-right: 7px;
          }

          canvas {
            width: 95% !important;
            // height: auto !important;
            margin: auto;
          }
        }
      }
    }
  }

  /* SALES AREA */
  .cockpitSalesInfosContainer {
    margin-bottom: 15px;
    margin-right: .5vw;
    width: 49%;
    position: relative;

    .headerOutsideTitle {
      display: flex;
      align-items: center;
      position: absolute;
      top: -32px;
      right: 10px;

      .cmvIcon {
        background: #5062F0;
        padding: 2px 13px;
        border-radius: 50px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 5px;
        font-size: 14px;
      }
  
      .title {
        margin-right: 10px;
        color: #0B1A8E;
        font-weight: bold;
        font-size: 16px;
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      }
  
      .percent {
        box-shadow: 0px 4px 4px 0px #00000040;
        color: #FFF;
        background: #08AA15;
        border-radius: 50px;
        padding: 3px 10px;
        font-size: 12px;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .graphicContainer {
      .graphicBox {
        .diffGraphic {
          margin-right: 6vw;
        }

        .graphicItem {
          &:first-of-type {
            canvas {
              // padding-bottom: 40px;
            }
          }
        }
      }

      .rightInfos {
        display: flex;

        .weather {
          display: flex;
          align-items: center;
          margin-right: 15px;

          img {
            margin-right: 3px;
          }

          span {
            font-size: 12px;
            display: flex;
            align-items: center;

            &:last-of-type {
              img {
                width: 13px;
              }
            }
          }
        }
      }
    }
  }

  /* SKUS AREA */
  .cockpitSkuInfosContainer {
    margin-bottom: 15px;
    margin-left: .5vw;
    margin-right: .5vw;
    width: 49%;

    .graphicContainer {
      .graphicBox {
        .diffGraphic {
          margin-right: 6vw;
        }

        .graphicItem {
          &:first-of-type {
            canvas {
              // padding-bottom: 40px;
            }
          }

          .barItem {
            position: relative;
            display: flex;
            align-items: center;
            color: #1F2445;
            margin-bottom: 1.2vh;
    
            &:last-of-type {
              margin-bottom: 0;
            }

            .descriptionBox {
              width: 25%;
              position: relative;
              margin-right: 1.9vw;
              text-align: right;
              padding-top: 5px;
            }

            .pTop {
              padding-top: 1vh;
            }

            .verticalDivider {
              position: absolute;
              left: 9.4vw;
              top: 0;
              border: solid 1px #FFF;
              height: 5.3vh;
            }
    
            .progressBarBorder {
              border-radius: 50px;
              width: 100%;
              height: 14px;
              display: flex;
              align-items: center;
    
              .progressBar {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                border-radius: 200px;
                box-shadow: 0px 4px 4px 0px #00000040;
                transition: 220ms all ease-in;
                height: 20px;

                span {
                  margin-right: 10px;
                }
              }
            }
          }
        }
      }
    }
  }

  /* PRODUCTION AREA */
  .cockpitProductionInfosContainer {
    width: 49%;
    margin-right: .4vw;

    .graphicContainer {
      .graphicBox {
        .graphicItem {
          height: 22.2vh;
        }
      }
    }
  }

  /* COUNTS AREA */
  .cockpitCountInfosContainer {
    width: 49%;
    margin-left: .4vw;
    margin-bottom: 15px;

    .graphicContainer {
      .graphicBox {
        .diffGraphic {
          margin-right: 6vw;
        }

        .graphicItem {
          height: 22.2vh;
          
          &:first-of-type {
            canvas {
              // padding-bottom: 40px;
            }
          }
        }
      }
    }
  }

  .cockpitProductionInfosContainer,
  .cockpitCountInfosContainer {
    .barInfosContainer {
      flex-direction: column;

      .barItem {
        flex-direction: column;
        margin-bottom: 5px;
        color: #1F2445;

        &:last-of-type {
          margin-bottom: 0;
        }

        .barInfos {
          display: flex;
          justify-content: space-between;

          span {
            font-size: 12px;
          }

          .legendInfos {
            span {
              font-weight: bold;
            }

            .percent {
              margin-left: 20px;
            }

            &.leftOverOrLack {
              .percent {
                margin-right: 2px;
                margin-left: 0;
              }
            }
          }
        }

        .progressBarBorder {
          border-radius: 50px;
          border: solid 1px #FFF;
          width: 100%;
          height: 14px;
          display: flex;
          align-items: center;

          .progressBar {
            border-radius: 200px;
            box-shadow: 0px 4px 4px 0px #00000040;
            transition: 220ms all ease-in;
            height: 12px;
          }
        }
      }
    }
  }
  
  /* RECEIVEMENT AREA */
  .cockpitReceivementInfosContainer {
    width: 100%;
  }

  @media (max-width: 850px) {
    .productionAndReceivementArea {
      flex-direction: column;
    }

    .cockpitDefaultContainer {
      .graphicContainer {
        .graphicBox {
          flex-direction: column;

          .graphicItem {
            width: 100%;
            margin: 0 0 30px;

            canvas {
              height: auto !important;
            }
          }
        }
      }

      &.cockpitCountInfosContainer {
        .graphicContainer {
          .graphicBox {
            .graphicItem {
  
              &:first-of-type {
                canvas {
                  padding-bottom: 0;
                }
              }
            }
          }
        }
      }
    }

    .cockpitReceivementInfosContainer {
      margin-left: 0;
    }
  }

  /* SHOPPING AREA */
  .cockpitShoppingInfosContainer {
    margin-left: .5vw;
    margin-right: .5vw;
    width: 49%;

    .graphicContainer {
      .graphicBox {
        .graphicItem {
          height: 51.9vh;

          .totalInfos {
            display: flex;
            align-items: center;

            span {
              font-size: 12px;
              font-weight: bold;
              color: #1F2445;
              display: block;
              width: 7vw;
              text-align: right;
              margin-left: 1.1vw;

              &:first-of-type {
                width: 10vw;
                margin-left: 0;
              }

              &:nth-of-type(2) {
                margin-left: .1vw;
              }

              &:last-of-type {
                width: 5vw;
                margin-left: 1.8vw;
                margin-right: .5vw;
                color: #FFF;
                font-weight: bold;
                border-radius: 50px;
                background: #5062F0;
                padding: 2px 10px;
                box-shadow: 0px 4px 4px 0px #00000040;
              }
            }
          }

          .shoppingDefaultActionButton {
            font-size: 10px;
            color: #9296AC;
            font-weight: bold;
            border-radius: 50px;
            background: #FFFFFF;
            margin-right: 5px;
            padding: 3px 10px;

            &:last-of-type {
              margin-right: 0;
            }

            &.active {
              color: #FFF;
              background: #F53D4C;
              box-shadow: 0px 4px 4px 0px #00000080;
            }

            &.deflationButton {
              &.active {
                background: #5062F0;
              }
            }
          }

          table {
            margin-top: 10px;

            .evolutionTag {
              display: flex;
              align-items: center;
              justify-content: flex-end;
              color: #FFF;
              font-weight: bold;
              border-radius: 50px;
              background: #5062F0;
              padding: 2px 10px;
              box-shadow: 0px 4px 4px 0px #00000040;
              max-width: 81%;
              margin-left: auto
            }

            th {
              padding-bottom: 8px;
            }

            td {
              &.description {
                height: 25px;
                padding: 3.7px 0;
              }
            }

            th,
            td {
              color: #1F2445;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}