/* Modals */
.storeConsumptionTable,
.stockPerStoreTable,
.storePurchaseSuggestionsTable,
.inflationDialogTable,
.tradingHistoryTable,
.skusQuotationTable,
.storeBuyIntentionTable {
  td {
    font-size: 12px;
  }
}

.tradingHistoryTable {
  .providersAndQuotationsLink {
    padding: 10px 16px 8px;
    width: 100%;
    text-align: right;

    p {
      color: #5062F0;
      font-family: 'Lato';
      font-size: 12px;
      cursor: pointer;
      margin-bottom: 0;
    }
  }
}

.storeBuyIntentionTable {
  .consumedField {
    width: 24%;

    svg {
      margin-right: 5px;
      width: 20px;
    }
  }
}