.newSideBarWrapper {
  margin-top: 20px;
}

.newSideBar {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 44px;
  height: auto;
  background-color: transparent;
  position: relative;

  .dropdown-wrapper {
    position: absolute;
    width: 174px;
    height: 100vh;
    left: 22px;
    background-color: transparent;
    z-index: 1499;
  }

  .dropdown {
    position: relative;
    display: inline-block;
  }

  .dropdown-content {
    border-radius: 12px !important;
    display: flex;
    left: 8px;
    position: absolute;
    background-color: white;
    z-index: 1500;
  }

  div {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    background-color: #0E1337;
    border-radius: 0px 12px 12px 0px;
  }

  .iconsGroup01 {
    width: 100%;
    margin-bottom: 10px;
    position: relative;

    div {
      border-radius: 12px;
      cursor: pointer;
      width: 100%;

      img {
        margin: 12.5px 0;
      }
    }

    div:hover {
      background-color: #5062F0;
    }

    .selectedIcon {
      background-color: #5062F0;
    }
  }
}