.App {
  text-align: center;
  margin: 0 auto;
  width: 480px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.header-logo-box {
  padding: 15px 20px 15px 13px;
  background: #5062F0;
  border-radius: 0px 30px 30px 0px;
}

.header-restaurants-box {
  display: flex;
  align-items: center;
  margin-left: 20px;

  svg {
    color: #FFF;
    width: 30px;
    height: 30px;
    margin-left: 10px;
    margin-top: 5px;
  }
}

.headerRight {
  display: flex;
  align-items: center;

  .headerInfosBox {
    margin-right: 24px;
  }
}

.headerInfosBox {
  border-left: solid 1px #ECEDF1;
  border-right: solid 1px #ECEDF1;
  padding: 15px 24px;
  display: flex;

  .headerInfos {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-right: 20px;

    p {
      margin-bottom: 0;
      font-size: 12px;
      color: #FFF;
    }
  }

  .headerInfosIcons {
    display: flex;

    .iconBox {
      width: 32px;
      height: 32px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      border: 1px solid #E6A117;
      border-radius: 50px;
      background: #FFF;

      &:first-of-type {
        margin-right: 12px;
        border: 1px solid #3DCC6E;

        img {
          width: 60%;
        }
      }

      .wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        width: 100%;
        bottom: 0;

        &.bloom {
          background: #3DCC6E;
        }

        &.diary {
          background: #E6A117;
        }
      }

      img {
        position: relative;
        width: 70%;
      }
    }
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.popover-settings .MuiPaper-rounded  {
  top: 65px !important;
  border-radius: 0px 0px 8px 8px !important;
}

@media only screen and (max-width: 1024px) {
  .popover-settings .MuiPaper-rounded  {
    left: calc(100% - 130px) !important;
  }
}

.dialog-search-header-sku .MuiDialog-paperWidthSm {
  max-width: 1150px;
}

.close-dialog-sku-header-search {
  background: none !important;
  margin-left: 61.5px !important;
  padding: 0px !important;
}

.close-dialog-sku-header-search span {
  margin: 0px !important;
}

.close-dialog-sku-header-search img {
  width: 13px !important;
}

.sku-search-title {
  background-color: rgb(15, 18, 37);
  padding: 6px 12px !important;
  cursor: move;
}

.sku-search-title span {
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-weight: normal;
  font-family: Lato;
  margin-right: 5px;
}

.sku-search-title .input-search-sku-site {
  display: none;
}

.sku-search-title .filterOpened {
  width: 414px;
  height: 44px;
  margin: 0px;
}

.sku-search-title .filterOpened div {
  margin: 0px;
  padding: 0px;
  height: 100%;
  width: 100%;
}

.sku-search-title .filterOpened {
  border-radius: 12px;
}

.sku-search-title .filterOpened div .MuiInputAdornment-root {
  width: auto;
}

.sku-search-title .filterOpened div .MuiInputBase-root {
  width: 414px;
}

.sku-search-title h2 {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}

.sku-search-title > .MuiTypography-root  > div {
  width: 414px;
}

.sku-search-title .filterOpened div::before, .sku-search-title .filterOpened .MuiInput-underline::after, .sku-search-title .filterOpened .MuiInput-underline:hover:not(.Mui-disabled)::before {
  border: none;
}

.sku-search-title .filterOpened input {
  padding: 12px;
}

.desktop-table-search-sku .header-title {
  background-color: #F2F3F8;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  color: #454A67;
  font-size: 9px;
  line-height: 12px;
  border-bottom: none;
}

.desktop-table-search-sku .header-title th {
  padding: 4px 12px;
}

.desktop-table-search-sku td {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 60px;
}

.desktop-table-search-sku tr {
  border-bottom: 1px solid #D0D3E2;
  height: 20px;
}

.desktop-table-sku-provider tr td {
  border-bottom: 1px solid #D0D3E2;
  height: 35px;
  line-height: 20px;
}

.pagination-and-btnCatalog {
  display: flex;
  justify-content: space-between;
}

.div-pagination {
  padding-left: 12px;
}

.div-btnCatalog {
  padding-right: 12px;
  margin: 12px 0 12px 0;
}

.btn-catalog-sku {
  padding: 12px;
  border-radius: 12px;
  border: none;
  font-size: 16px;
  font-family: Lato;
  font-weight: bold;
  font-style: normal;
}

.supplier-name {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 280px;
}

.sku-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 250px;
}

.sku-search-provider-modal {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 240px;
  margin-top: 5px;
  margin-bottom: -15px;
}

.pagination {
  font-family: "Lato";
  margin: 12px 0 10px 0;
  justify-content: flex-start;
  display: flex;
  align-items: center;

  button {
    border-radius: 8px !important;
  }

  .MuiPaginationItem-outlinedPrimary {
    border-color: #313347;
    
    svg {
      color: #313347!important;
    }

    &.Mui-selected {
      background: #5062F0!important;
      color: #FFF!important;
    }
  }
}

@media only screen and (min-width: 320px) {
  .search-modal {
    display: none !important;
  }
}

@media only screen and (min-width: 481px) {
  .search-modal {
    display: block !important;
  }
}

.onlyOnMobileBloom {
  display: none;
}

@media only screen and (max-width: 1279) {
  .onlyOnMobileBloom {
    display: block;
  }
}
