.fabButton {
  background-color: #5062F0;
  position: fixed;
  bottom: 10px;
  right: 10px;
  left: 10px;
  outline: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 12px;
  border-radius: 12px;
  color: white;
  box-shadow: 0px 2px 6px 2px rgba(1, 3, 17, 0.45);

  .confirmationButtonDate {
    z-index: 999;
    display: flex;
    align-items: center;
    width: 155px;
    div {
      .MuiFormControl-root {
        margin: 5px;
      } 
      background-color: white;
      border-radius: 4px;
      color: #9296AC;
      display: flex;
      justify-content: flex-start;

      .MuiInput-underline:before {
        border-bottom: none;
        color: #9296AC;
        transition: none;
      }
      .MuiInput-underline:after {
        border-bottom: none;
        color: #9296AC;
        transition: none;
      }
      
      input {
        color: #9296AC;
        width: 100%;
        margin: 0;
        padding: 0;
      }
    }
    
    p {
      margin: 0 8px 0 0 ;
    }
  }

  .confirmationButtonSend {
    display: flex;
    align-items: center;
    
    font-size: 16px;
    font-weight: 700;
    width: 100px;
    p {
      margin: 0 8px 0 16px;
    }
    div {
      height: 28px;
      width: 1px;
      background-color: white;
    }
  }

  button {
    color: white;
    padding: 0;
  }

  label {
    color: white;
    
  }

  .MuiFormLabel-root.Mui-focused {
    color: white;
  }

  input {
    font-size: 14px;
    border-color: white;
    width: 40%;
    color: white;
    margin: 0;
    padding: 0;
  }
}

.detailCard {
  display: block;
  margin: 10px 0 60px 0;
  border-radius: 4px;
  box-shadow: 0px 1px 4px 1px rgba(1, 3, 17, 0.45);
  font-weight: 700;

  @media screen and (min-device-width: 600px) {
    display: none;
  }
  
  .cardInfo {
    background-color: #1F2445;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
    border-radius: 5px 5px 0 0;
    
    p {
      margin: 0;
      font-size: 9px; 
    }

    div {
      display: flex;
      align-items: center;
      height: 100%;
      
      
      p:first-child {
        margin-right: 10px;
      }

      p:last-child {
        background-color: #5062F0;
        padding: 10px;
        border-radius: 4px;
        
      }
    }
  }

  .header {
    .nameAndStatus {
      margin: 8px 10px;
      .name {
        color: #1F2445;
        
        h1 {
          font-size: 16px;
          text-align: left;
        }
      }

      .status {
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
          margin: 0;
          font-weight: 300;
          font-size: 12px;
        }

        div {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 12px;
          
          p:first-child {
            margin-right: 10px;
            font-weight: 300;
          }
    
          p:last-child {
            background-color: #DADDF3;
            padding: 5px;
            color: #5062F0;
            border-radius: 2px;
          }
        }
      }
    }

    .values {
      display: flex;
      justify-content: space-between;
      margin: 0 10px;
      font-family: Arial, sans-serif;

      p {
        font-size: 12px;
        text-align: right;
      }

      h1 {
        @media screen and (max-device-width: 340px) {
          width: 130px;
          height: 40px;
        }
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-size: 14px;
        text-align: right;
        width: 150px;
        height: 40px;
      }

      div:first-child {
        p {
          margin: 0 8.4px 0 0;
          color: #91084C;
        }
  
        h1 {
          background-color: #F3DAE6;
          color: #91084C;
          padding: 10px;
          margin: 0;
        }
      }

      div:last-child {
        p {
          margin: 0;
          color: #089191;
        }
  
        h1 {
          background-color: #D9F2F2;
          color: #089191;
          padding: 10px;
          margin: 0;
        }
      }
    }

    .buttons {
      text-align: center;
      margin: 10px 0;

      p {
        color: #5062F0;
        font-size: 10pt;
        text-decoration: underline;
      }

      button {
        @media screen and (max-device-width: 358px) {
          padding: 10px 20px;
        }
        outline: none;
        font-weight: 700;
        font-size: 16px;
        color: #5062F0;
        background-color: transparent;
        border: 2px solid #5062F0;
        padding: 10px 40px;
        border-radius: 20px;
      }
    }
  }
  .lengthItens {
    background-color: #F2F3F8;
    width: 100%;
    padding-left: 10px;
    color: #454A67;
  }

  .cardProducts {
    padding: 20px 0px;
    border-bottom: 1px solid rgb(133, 133, 133);
    .productsName {
      font-size: 16px;
      font-weight: 500;
      display: flex;
      justify-content: space-between;
      margin: 0 10px;
      font-weight: 700;
    }

    .qtde {
      display: flex;
      background-color: #004C4C;
      border: 1px solid #004C4C;
      border-radius: 8px;
      p {
        padding: 3px 0;
        margin: 0;
        width: 60%;
        font-size: 9px;
        background-color: red;
        color: white;
        text-align: center;
        border-radius: 8px 0 0 8px;
      }
        div {
          border-radius: 8px 0 0 8px;
          

          &:first-child {
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #004C4C;
            border-radius: 8px 0 0 8px;
            background-color: #004C4C;
            color: white;
            font-size: 10px;
          }
          h1 {
            background-color: white;
            font-size: 10px;
            border-radius: 8px;
            margin: 1px;
            padding: 10px 20px;
            z-index: 1;
          }
        }
      }

    .productsInfos {
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin: 0 8px;

      .geral {
        display: flex;
        flex-direction: column;
      }

      .productsPrices {
        background-color: #D9F2F2;
        border: 1px solid white;
        padding: 2px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 114px;
        p {
          margin: 0;
          color: #004C4C;
          font-size: 10px;

          p {
            margin: 0;
            color: #089191;
            font-size: 12px;
            
          }
        }
      }
      
    }
    .productsInfosDetails {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      button {
        outline: none;
        margin: 10px 0;
        font-size: 16pt;
        color: #fff;
        background-color: transparent;
        border: none;
        padding: 10px 40px;
        border-radius: 20px;
        display: flex;
        justify-content: center;

        p {
          margin: 0;
        }
      }

      div:first-child {
        display: flex;
        justify-content: space-around;
        p {
          margin: 0;
        }
      }

      .detailsHeader {
        display: flex;
        justify-content: space-around;
      }
      
      .detailsQtde {
        display: flex;
        flex-direction: row;
        background-color: #F3DAE6;
        
      
        div {
          flex: 50%;
          display: flex;
          align-items: center;
          justify-content: space-between;  
          p, h1 {
            margin: 0;
            color: #91084C;
          }

          p {
            font-size: 9px;
            margin-left: 5px;
            color: #4D0026;
          }

          h1 {
            margin-right: 5px;
            font-size: 16px;
          }
        }

        .receiverProduct {
          background-color: #D9F2F2;
          padding: 10px;
          display: flex;
          flex-direction: column;
          align-items: flex-end;

          input[type=number]::-webkit-inner-spin-button { 
            -webkit-appearance: none;
          }
          
          input[type=number] { 
            -moz-appearance: textfield;
            appearance: textfield;
          }

          input {
            text-align: right;
            height: 44px;
            width: 133px;
            font-size: 16px;
            padding: 10px;
            border-radius: 10px;
            border: 1px solid #089191;
            background-color: white;
            color: #089191;
            outline: none;
          }
        }
      }
    }
  }
}

.floatButton {
  background-color: #5062F0;
  color: white;
  padding: 5px;
  width: 150px;
  height: 40px;
  border: 1px solid #5062F0;
  border-radius: 5px;
  position: fixed;
  right: 15px;
  bottom: 10px;
}
