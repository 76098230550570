.homeInfosPage {
  .homeDefaultContainer {
    display: flex;
    flex-direction: column;
  
    .homeDefaultInfoTitle {
      box-shadow: 8px 8px 8px 0px rgba(4, 6, 26, 0.3);
      border-radius: 10px;
      background: rgba(80, 98, 240, 1);
      padding: 5px 16px;
      margin-bottom: 16px;
  
      p {
        margin-bottom: 0;
        color: #FFF;
        font-weight: bold;
      }
    }

    .graphicContainer {
      padding: 0 16px;
      display: flex;
      flex-direction: column;

      .graphicBox {
        display: flex;
        width: 100%;
        margin-bottom: 25px;

        &:last-of-type {
          margin-bottom: 0;
        }

        .graphicItem {
          padding: 13px 13px 0 13px;
          width: 27.35vw;
          margin-right: 5.5vw;
          border-radius: 16px;
          background: #DADDF3;
          box-shadow: 8px 8px 8px 0px rgba(4, 6, 26, 0.30);
          
          &:last-of-type {
            margin-right: 0;
          }

          .graphicTitle {
            color: #0B1A8E;
            text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
            font-weight: 700;
            margin-bottom: 10px;
            display: block;
          }

          .graphicFilterTitle {
            color: #000319;
            font-size: 12px;
            line-height: 16px;
            font-weight: 700;
            margin-right: 7px;
          }

          canvas {
            width: 95% !important;
            height: auto !important;
            margin: auto;
          }
        }
      }
    }
  }

  /* COUNTS AREA */
  .homeCountInfosContainer {
    width: 100%;
    margin-bottom: 30px;

    .graphicContainer {
      .graphicBox {
        .diffGraphic {
          margin-right: 6vw;
        }

        .graphicItem {
          &:first-of-type {
            canvas {
              // padding-bottom: 40px;
            }
          }
        }
      }
    }
  }

  /* PRODUCTION AREA */
  .homeProductionInfosContainer {
    width: 100%;
  }

  /* RECEIVEMENT AREA */
  .homeReceivementInfosContainer {
    width: 100%;
    margin-left: 4vw;

    @media (max-width: 1366px) {
      margin-left: 3.2vw;
    }

    @media (min-height: 850px) {
      margin-left: 4.4vw;
    }
  }

  @media (max-width: 850px) {
    .productionAndReceivementArea {
      flex-direction: column;
    }

    .homeDefaultContainer {
      .graphicContainer {
        .graphicBox {
          flex-direction: column;

          .graphicItem {
            width: 100%;
            margin: 0 0 30px;

            canvas {
              height: auto !important;
            }
          }
        }
      }

      &.homeCountInfosContainer {
        .graphicContainer {
          .graphicBox {
            .graphicItem {
  
              &:first-of-type {
                canvas {
                  padding-bottom: 0;
                }
              }
            }
          }
        }
      }
    }

    .homeReceivementInfosContainer {
      margin-left: 0;
    }
  }
}