.quotationManagerTableCardInfos {
  margin: -5px 0 15px 0;

  .cardDefault {
    width: 29.5%;

    p,
    span {
      display: flex;
      align-items: center;
    }

    p {
      font-size: 12px;
      color: #1F2445;
    }

    span {
      font-size: 10px;
      color: #9296AC;
      font-weight: bold;

      &.navyBlueSpan {
        color: #1F2445;
      }
    }

    .titleInfo {
      margin-bottom: 10px;

      img {
        width: 20px;
      }
    }

    .progressBarInfoContainer {
      span {
        width: 110px;
        display: flex;
        justify-content: flex-end;
      }

      .progressBarBox {
        p {
          &:first-of-type {
            margin-right: 8px;
          }

          &:last-of-type {
            margin-left: 8px;
            width: 85px;
            display: flex;
            justify-content: flex-end;
          }
        }

        .progressBar {
          width: 110px;
          height: 24px;
          border-radius: 16px;
          overflow: hidden;
          background: #DADDF3;
          border: 1px solid #0B1A8E;

          .progress {
            border-radius: 16px;
            height: 100%;
            background: #1F2445;
          }
        }
      }

      &:last-of-type {
        border-top: solid 2px #E6E7F0;
        margin-top: 5px;
        padding-top: 5px;

        .progressBar {
          background: #D4F7EB;
          border: 1px solid #089163;

          .progress {
            background: #1DC98F;
          }
        }
      }
    }

    .multipleProgressBarContainer {
      margin-top: 18px;

      .multipleProgressBarLabelsAndValues {
        .multipleProgressBarItem {
          display: flex;
          flex-direction: column;
          width: 33.33%;
          align-items: center;
          justify-content: center;

          span {
            margin-bottom: 5px;
          }

          &:first-of-type {
            align-items: flex-start;
          }

          &:last-of-type {
            align-items: flex-end;
          }
        }
      }

      .multipleProgressBar {
        margin-top: 5px;
        width: 100%;
        height: 12px;
        border-radius: 8px;
        overflow: hidden;
        display: flex;
        
        div {
          height: 100%;
        }

        .firstProgress {
          background: #454A67;
          border-radius: 8px 0px 0px 8px;
        }

        .secondProgress {
          background: #F2B90D;
        }

        .thirdProgress {
          background: #08AA15;
          border-radius: 0px 8px 8px 0px;
        }
      }
    }
  }
}

/* Dialog Approve */
.approveConfirmationDialog {
  .MuiPaper-root {
    max-width: 1000px;
  }

  table {
    thead {
      th {
        font-size: 12px;
      }
    }
  }
}

/* Dialog Order Status */
.quotationOrderStatusDialog {
  .MuiPaper-root {
    max-width: 950px;
  }
}

/* Dialog Options */
.dialog-option {
  .MuiDialog-paper {
    overflow: visible;
    margin: 0;

    .approveProductTab {
      left: -36px;
      top: 47px;
      background: #00094C;
      box-shadow: 0px 1px 4px 1px rgba(1, 3, 17, 0.45);
      border-radius: 8px 0px 0px 8px;
      padding: 4px;
    }
  }
}

.quotationManagerTable {
  .fakeTrLine {
    width: 200px;
    transition: all 0.3s;

    span {
      color: #FFF !important;
      margin-top: -1px;

      &.cursorPointer {
        margin-top: -12.5px;
        display: block;
        margin-left: 17px !important;
      }
    }

    img {
      display: block;
    }
  }
}

.quotationManagerStatusFilterContainer {
  margin: 0 0 -1px 1px;
  z-index: 9;
  position: relative;

  .statusFilterBox {
    background: #FFFFFF;
    box-shadow: 0px 1px 4px 1px rgba(1, 3, 17, 0.45);
    border-radius: 4px 4px 0px 0px;
    padding: 4px 11px;

    .boxTitle {
      font-size: 10px;
      margin-bottom: 4px;
    }

    .boxTitleWithRealValue {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #F2F3F8;
      box-shadow: 0px 1px 4px 1px rgba(1, 3, 17, 0.45);
      border-radius: 6px;
      margin-bottom: -5px;
      margin-top: 0px;
      width: 115px;
      height: 23px;
    }
  }

  .item {
    margin-right: 8px;
    display: flex;
    box-shadow: 0px 1px 4px 1px rgba(1, 3, 17, 0.45);
    border-radius: 6px;
    overflow: hidden;
    width: 118px;

    &:last-of-type {
      margin-right: 0;
    }

    p {
      font-size: 12px;
    }

    span {
      font-size: 10px;
    }

    .circleNumber {
      border-radius: 0 4px 0 0;
      padding: 4px 12px 2px;
      overflow: hidden;
      position: relative;
      display: flex;
      justify-content: center;
      background: #1F2445;
      width: 36px;

      p {
        color: #FFF;
      }

      &.bgNoQuantity {
        background: #AA0816;
      }

      &.bgToApprove {
        background: #AA8208;
      }

      &.bgApproved {
        background: #08AA15;
      }

      &.bgIncluded {
        background: #7583F0;
      }

      &.bgInProgress {
        background: #9296AC;
      }

      &.bgReceived {
        background: #006608;
      }

      &.bgPartially {
        background: #089163;
      }

      &.bgReceivedDivergence {
        background: #F2B90D;
      }

      &.defaultBg {
        background: #454A67;
      }

      &.selected {
        background: #5062F0 !important;
      }
    }

    .itemTitle {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #454A67;
      padding: 4px 10px;
      width: 118px;

      &.selected {
        background: #5062F0 !important;
      }

      span {
        color: #FFF;
        text-align: center;
      }
    }
  }
}