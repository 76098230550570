/* Write Off Releases Dialog */
.writeOffReleasesDialog {
  width: 100%;

  .input-search-sku-site {
    display: none;
  }

  .MuiPaper-root {
    width: 90%;
    max-width: 100%;
  }

  .MuiAlert-root {
    width: 100%;
  }

  .pageTitleAndFilterSection {
    display: block !important;
  }
  
  .filterBtn {
    display: none;
  }

  table {
    margin-bottom: 60px;

    .MuiInputBase-root {
      padding-left: 0 !important;
    }

    td {
      &.typeField {
        .MuiInputBase-root {
          padding-top: 2px;
        }
      }
    }

    .defaultStatusChip {
      box-shadow: 0px 4px 4px 0px #00000040;
      background: #E6E7F0;
      border-radius: 12px;
      width: calc(100% / 2);
      height: 22px;
      line-height: 1.8;
      transition: all 0.3s;

      span {
        color: #9296AC;
        font-weight: bold;
        font-size: 12px;
        width: 100%;
        text-align: center;
        display: block;
      }

      &.discardChip,
      &.consumeChip {
        cursor: pointer;
        user-select: none;
        
        &.active {
          box-shadow: 4px 4px 4px 0px #00000080;

          span {
            color: #010311;
          }
        }
      }

      &.discardChip {
        margin-right: 10px;
        
        &.active {
          background: #FFF2CC;
        }
      }
      
      &.consumeChip {
        &.active {
          background: #D9EAF2
        }
      }
    }
  }

  tbody {
    tr {
      &.dividerBg {
        background: rgba(146, 150, 172, .2)
      }
    }
  }

  .actionButtons {
    position: fixed;
    width: 99%;
    bottom: 0;
    background: #F6F6F8;
    padding: 10px 8px 10px 15px;
    margin: 0 0 0 -16px;
    z-index: 999
  }
}

@media only screen and (max-width: 1024px) {
  .writeOffReleasesDialog {
    table {
      td {
        padding-top: 5px !important;
        padding-bottom: 5px !important;

        &.divider {
          padding-top: 0 !important;
          padding-bottom: 0 !important;
        }
        
        &.quantityField {
          padding-right: 12px !important;
          
          .MuiInputBase-root {
            background: #FFF;
          }
        }
      }

      .defaultStatusChip {
        height: 25px;
        line-height: 2;
      }
    }
  }
}