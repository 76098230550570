/* Table List */
.productionPage {
  .manuallyViewOrderBox {
    margin-left: auto;
    margin-right: -22px;

    svg {
      width: 15px;
      height: 15px;
      color: #FFF;
    }
  }

  .tabletFilterInfo {
    @media only screen and (max-width: 1024px) {
      label {
        color: #FFF !important;
        width: auto !important;
      }

      .ant-select-selection-placeholder {
        color: #FFF !important;
      }

      .productionCourtsSelect {
        margin: 1px 0 -2px 10px;

        label {
          display: none;
        }

        .ant-select {
          background: #FFF !important;

          input,
          .ant-select-selection-placeholder {
            padding-left: 10px;
            color: #454A67 !important;
          }
        }
      }
    }
  }

  .subHeaderFilters {
    @media only screen and (max-width: 1024px) {
      display: none !important;
    }
  }

  .cardsWithoutReviewBox {
    svg {
      width: 15px;
      height: 15px;
      color: #1F2445;
    }
  }

  .productionSwitch {
    margin-left: auto;

    span {
      color: rgb(14, 19, 55);
      font-size: 12px;
      font-weight: bold;
    }
  }
}

/* Popover Image */
.productionImagePopover {
  position: absolute;
  right: -65px;
  top: -30px;
  width: 570px;
  height: 570px;

  .mainSkuImage {
    width: 480px;
    height: 480px;
  }
}

.itemProductionTableDescription {
  td {
    font-size: 12px !important;
    font-weight: normal !important;
  }
}

.productMain {
  td {
    font-size: 12px;
    font-weight: 700 !important;
    color: #0B1A8E;
  }
}

.white-placeholder::placeholder{
  color: rgb(255, 255, 255) !important;
  opacity: 1 !important;
}

.tableColorHover {
  background-color: #FFF !important;
}

.productionTable {
  .headerTitle {
    th {
      font-size: 9px !important;

      span {
        img {
          width: 9px;
        }
      }
    }

    th:first-of-type {
      border-top-left-radius: initial!important;
    }

    th:last-of-type {
      border-top-right-radius: initial!important;
    }

    td {
      background: #333541!important;
    }

    input {
      &::placeholder {
        color: #FFF !important;
      }
    }
  }

  tr {
    &:hover {
      .tableColorHover {
        background: #FFF !important;
      }
    }
  }

  td {
    font-size: 12px !important;
  }

  .defaultBox {
    display: flex;
    align-items: center;
    flex-direction: column;

    .upperLine,
    .bottomLine {
      width: 100%;
      min-height: 28px;

      input {
        background: #FFF;
      }
    }

    .upperLine {
      display: flex;
    }

    .bottomLine {
      margin-top: 5px;
    }
  }

  .productionButtonStatus {
    display: flex;
    justify-content: flex-start;
    padding: 0 8px;

    .finishedTimeCircle {
      width: 45px;
      height: 45px;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.5);
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin: auto -9px auto auto;

      span {
        font-size: 10px;
      }

      &.positiveTime {
        background: rgba(0, 102, 8, 1);

        span {
          color: #FFF;
        }
      }

      &.noTime {
        background: rgba(247, 213, 110, 1);

        span {
          color: rgba(14, 19, 55, 1)
        }
      }
    }
  }

  .MuiCollapse-container {
    &.expanded {
      height: auto !important;
    }
  }

  .dropDownTable {
    th {
      color: #77798B;
    }

    tr {
      vertical-align: baseline;
    }

    tbody {
      td {
        input {
          background: #FFF;
          height: 28px;
          padding: 0 3px 0 0;
        }

        .MuiInputBase-root {
          .MuiInputAdornment-root {
            display: none;
          }
        }

        .autocompletePtChild0 {
          input {
            height: 26px;
          }
        }

        .autocompleteSmall {
          .MuiInputBase-root {
            background: #FFF;
            padding: 0 0 0 8px !important;

            input {
              padding: 0;
            }
          }
        }
      }
    }
  }
}


/* Product Transference Dialog */
.productTransferenceDialog {
  width: 34%;

  .dateSmall {
    margin-top: 17px;

    svg {
      margin-top: 5px;
      width: 18px;
    }

    .MuiInputBase-root {
      padding: 2px 0 6.5px 0 !important;
    }
  }
}

.tableTransfer {
  font-size: 12px !important;

  td,th {
    color: #A0A1A7;
    font-size: 12px !important;
    font-weight: 400;
  }

  .lineBottom {
    border-bottom: 1px solid #A0A1A7;
  }
}

.positiveValue {
  color: #5062F0;
  font-weight: 400;
  font-size: 12px !important;
  margin: 0;
  text-align: end;
}

.negativeValue {
  color: #F53D4C;
  font-weight: 400;
  font-size: 12px !important;
  margin: 0;
  text-align: end;
}

.updateStock {
  width: 200px;
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: end;
  
  .titleUpdate {
    font-size: 12px;
    font-weight: 700;
  }

  .valueUpdate {
    font-size: 12px;
    font-weight: 400;
  }
}