@import "../common/variables.scss";

.unifySkusPopoverDialog {
  p,
  label,
  span {
    font-size: 14px;
  }

  td {
    color: $lightBlue;
  }

  .Mui-checked {
    svg {
      color: $lightBlue;
    }
  }

  label {
    margin: 0 10px 0 0 !important;

    .MuiRadio-root {
      padding: 5px 5px 5px 0;
    }

    .MuiTypography-root {
      font-weight: bold;
    }
  }

  .optionsList {
    max-height: 200px;
    overflow: scroll;
  }
}

.comparationDialog {
  #skusBox {
    input {
      color: #FFF !important;
      margin-bottom: -4px;

      &::placeholder,
      &::-webkit-input-placeholder {
        color: #FFF;
        opacity: .8;
      }
    }

    .MuiInput-underline,
    .MuiInput-underline:before,
    .MuiInput-underline:after,
    .MuiInput-underline:hover:not(.Mui-disabled):before,
    .MuiInput-underline:hover:not(.Mui-disabled):after {
      border-color: #FFF !important;
      border-width: 1px;
      margin-bottom: -4px;
    }
  }
}

.similarSkuDialog {
  .brandMatch {
    background: rgba(80, 98, 240, .2);
  }

  &.controllerIndex {
    z-index: 1250 !important;
  }
}


