.performedCmvInfosDialog {
  .cmvPerformedTabsBox {
    display: flex;
    padding-top: 10px;
    padding-left: 22px;
    background: #F6F6F8; /* Garante um fundo sólido */
    position: sticky; /* Fixa as abas durante o scroll */
    top: 0; /* Gruda no topo da área rolável */
    z-index: 10; /* Garante que as abas fiquem acima da tabela */

    .defaultTab {
      background: #FFF;
      border: .5px solid #1F2445;
      border-radius: 4px 4px 0 0;
      padding: 2px 10px;
      margin-right: 5px;
      min-width: 240px;
      box-shadow: 0px 1px 4px 1px #01031173;
      cursor: pointer;
        
      span {
        color: #1F2445;
        font-weight: bold;
        font-size: 12px;
      }

      &.active {
        background: #5062F0;
        cursor: auto;
        
        span {
          color: #FFF;
        }
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  table {
    td {
      font-size: 12px;
    }

    thead {
      position: sticky;
      top: 35px;
    }
  }
}