.nfceImageImportDialog {
  width: 100%;

  .MuiPaper-root {
    margin: 10px;
  }

  .imageContainer {
    max-width: 340px;
    box-shadow: 0px 0px 8px 0px #04061A40;

    .imageBox {
      width: 340px;
      height: 295px;
      background: rgba(146, 150, 172, .7);
      display: flex;
      align-items: center;
      justify-content: center;

      label {
        width: 100%;
        height: 100%;
        background: rgba(146, 150, 172, .7); 
      }

      video {
        width: 100%;
        height: 100%;
        margin: 0px !important;
      }

      img {
        height: auto;
        width: auto !important;
        max-height: 100%;
        max-width: 100%;
        margin: auto;
        display: block;
      }
    }

    .cameraActions {
      padding: 10px;

      .switchCamIconBox,
      .camIconBox {
        cursor: pointer;
        border: solid 1px #9296AC;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        transition: all 400ms ease;

        img {
          width: 25px;
          height: 25px;
        }

        &.active {
          background: #5062F0;
          border: solid 1px #5062F0;
        }
      }
    }
  }
}