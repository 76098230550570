.cmvMkpRadio,
.projRealRadio {
  right: 0;
  display: flex;
  align-items: center;
  z-index: 1000;

  label {
    color: #313347 !important;
    margin: 0;
  }

  &.sticky {
    position: fixed;
    top: 61px;
  }

  .MuiGrid-item {
    padding: 0 !important;
  }

  p {
    .MuiFormControlLabel-label {
      font-family: "Lato"; 
      font-size: 10px; 
      margin-left: 10px;
    }
  }

  span {
    &.MuiTypography-root {
      font-size: 11px;
    }
  }
}

.subFilterArea {
  margin-top: -10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}

.salesInvoicesSequenceAlert {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #F53D4C;
  border-radius: 4px;
  padding: 2px;
  width: 77%;

  span {
    font-size: 12px;
  }
}

.costSubFiltersBox {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 23%;
}

.cmvMkpRadio {
  margin: 0px 0px 0 10px;
  padding-left: 5px;
  border-left: solid 1px #73004C; 

  #cmvOption {
    margin-right: 5px;
  }

  #mkpOption {
    margin-right: 0;
  }

  &.sticky {
    right: 16px;
  }
}

.projRealRadio {
  #projOption {
    margin-right: 5px;
    margin-left: 10px;
  }

  &.sticky {
    right: 19px;
  }
}

.MultipleCheckBoxAside {
  position: absolute;
  z-index: 1000;
  right: 20px;
  top: 70px;

  label {
    margin-top: 14px;
  }
}

.operatingExpensesTable {
  table {
    thead {
      tr {
        &:last-of-type {
          display: none;
        }
      }

      .category {
        width: 45%;
      }

      .items {
        width: 35%;
      }

      .consumption {
        text-align: right;
      }
    }

    tbody {
      td {
        &.right {
          span {
            display: block;
            text-align: right;
          }
        }
      }
    }
  }
}

/* List */
.menuListTable {
  padding: 0;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 0px 8px rgba(4, 6, 26, 0.25);

  .blackLink,
  .whiteLink {
    text-decoration: underline;
    padding: 8px 0;

    td {
      text-decoration: underline;
    }
  }

  .blackLink {
    color: #313347;

    td {
      color: #313347;
    }
  }

  .whiteLink {
    color: #FFF;

    td {
      color: #FFF;
    }
  }

  table {
    width: 100%;
    border-spacing: 0;
    font-family: "Lato";
    font-size: 11px;
    background: #fff;
    border-collapse: collapse;

    .defaultHeader {
      background: #313347;
      color: #FFF;
      position: sticky;
      top: -10px;
      z-index: 999;  
      padding: 8px 12px;
      border-right: 1px solid #BFC0CB;
    }

    .itens {
      border-right: none;
    }

    .vendas {
      background: #001373;
      border-bottom: 1px solid #BFC0CB;
      border-left: 1px solid#001373 !important;
      border-right: none;
    }
    
    .custo {
      background: #73004C;
      border-bottom: 1px solid #BFC0CB;
      border-left: 1px solid #73004C !important;
    }

    tr {
      :last-child {
        td {
          background: #fff;
          border-bottom: 0;
        }
      }

      &:hover {
        td {
          background: #A8B1F8 !important;
          color: #FFF !important;

          td {
            background: transparent;
          }
        }
      }
    }

    thead {
      tr {
        th {
          &:first-of-type {
            border-top-left-radius: 4px;
          }

          &:last-of-type {
            border-top-right-radius: 4px;
          }

          &.categoryHeader {
            width: 14.5vw;
            max-width: 14.5vw;
            z-index: 9;
          }
        }

        th,
        td {
          &.defaultSubtitle {
            border-bottom: 1px solid #BFC0CB;
            position: sticky;
            top: 21px;
            z-index: 999;
            background: #F6F6F8;
            color: #77798B;
            text-align: left;   
            vertical-align: bottom;
            text-align: right;

            p {
              text-align: right;
            }

            &.column-sales-average {
              border-left: 1px solid #001373;
            }

            &.column-total-cost {
              border-left: 1px solid #73004C;
            }

            &.categoryColumn {
              width: 15%;
            }

            &.column-sales-average-discount,
            &.column-percentage-discount,
            &.column-quantity,
            &.column-total,
            &.column-margin,
            &.column-cost,
            &.column-cmv {
              border-left: none !important;
            }

          }
        }
      }
    }

    tbody {
      tr {
        height: 41px;
        
        td {
          background: #F2F4FF;

          &:nth-of-type(1),
          &:nth-of-type(2) {
            background: #FCFCFC;
          }

          &:nth-of-type(9),
          &:nth-of-type(10),
          &:nth-of-type(11),
          &:nth-of-type(12) {
            background: #FFF2FB;
          }
        }

        &:nth-of-type(2n) {
          td {
            background: #D5D9F1;

            &:nth-of-type(1),
            &:nth-of-type(2) {
              background: #EEEEEE;
            }

            &:nth-of-type(9),
            &:nth-of-type(10),
            &:nth-of-type(11),
            &:nth-of-type(12) {
              background: #F0D4E7;
            }
          }
        }

        &.itemBg {
          td {
            background: #9CA5D1;

            &:nth-of-type(1),
            &:nth-of-type(2) {
              background: #D2D2D2;
            }

            &:nth-of-type(9),
            &:nth-of-type(10),
            &:nth-of-type(11),
            &:nth-of-type(12) {
              background: #D09BBE;
            }
          }

          &:nth-of-type(2n) {
            td {
              background: #B7BEE0;
  
              &:nth-of-type(1),
              &:nth-of-type(2) {
                background: #E0E0E0;
              }
  
              &:nth-of-type(9),
              &:nth-of-type(10),
              &:nth-of-type(11),
              &:nth-of-type(12) {
                background: #E0B6D2;
              }
            }
          }
        }
      }
    }

    th,
    td {
      background: #fff;
      margin: 0;
      padding: 4px;
      border-bottom: 1px solid #BFC0CB;

      span {
        display: flex;
        align-items: center;
      }

      :last-child {
        border-right: 0;
      }

      &.withoutLeftBorder {
        border-left: none !important;
      }

      &.withoutRightBorder {
        border-right: none !important;
      }

      &.withRightDefaultBorder {
        border-right: solid 1px #BFC0CB !important;
      }

      &.withLeftDefaultBorder {
        border-left: solid 1px #BFC0CB !important;
      }
    }
  }

  .action {
    background: #fff;
    width: 100px;
  }

  .column-total-cost,
  .column-cost,
  .column-mkp,
  .column-cmv {
    border-left: 1px solid #73004C !important;
    border-right: none !important;
  }

  .column-sales-average,
  .column-sales-average-discount,
  .column-percentage-discount,
  .column-quantity,
  .column-total,
  .column-margin {
    border-left: 1px solid#001373 !important;
    border-right: none !important;
  }

  .defaultSubtitle {
    .inactive {
      visibility: hidden;
      transition: all 50ms ease;
    }

    &:hover {
      .inactive {
        visibility: visible;
        transition: all 50ms ease;
      }
    }
  }

  .column-item {
    .notesDialogIcon {
      svg {
        width: 13px;
        margin-right: 5px;
      }
    }
    
    a {
      // display: inline-block;
      vertical-align: -1px;
      margin-left: 5px;
    }
  }
  
  .column-sales-average,
  .column-sales-average-discount {
    width: 6%;
  }

  .column-total-cost {
    width: 7%;
  }

  .column-percentage-discount,
  .column-quantity,
  .column-margin,
  .column-cmv,
  .column-mkp {
    width: 5%;
  }

  .column-total,
  .column-cost {
    width: 7%;
  }

  .column-mkp {
    border-left: 1px solid #BFC0CB;
  }
}

/* Register Modal */
.menuModal {
  width: 100%;

  .MuiDialog-paperWidthMd {
    max-width: 83.2%;
  }
  
  .MuiGrid-spacing-xs-5 {
    margin: 0;
    width: 100%;

    .MuiGrid-root {
      &:first-of-type {
        padding-left: 0;
      }

      &:last-of-type {
        padding-right: 0;
      }
    }
  }

  form {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    .so-treeSelect-result {
      padding: 9px 20px 1px 0;
    }

    .productTableModal {
      &.materialTableDefault {
        .MuiInputBase-root {
          input {
            padding: 8px 0 5px 0 !important;
          }
        }
  
        .itemWithModal {
          .MuiInputBase-root {
            margin-top: 2px;
          }
        }
      }
    }
  }
}

/* Process Non Integrated Sales Dialog */
.processNonIntegratedSalesDialog {
  .datePicker {
    width: 30%;
  }

  .MuiAlert-root {
    width: auto !important;
  }

  .bloomSalesBox,
  .pdvSalesBox {
    width: 35%;
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    padding-top: 13px;

    span {
      text-align: right;
      font-size: 12px;

      &:first-of-type {
        margin-bottom: 9px;
      }
    }
  }

  .MuiPaper-root {
    max-width: 100%;
    width: 620px;
  }

  .mainInfosBox {
    margin-bottom: 16px;
  }

  .fakeDivider {
    border-top: solid 1px #000319;
    margin-left: -16px;
    width: calc(100% + 32px);
    margin-bottom: 14px;
  }

  .salesInfosCircularProgressBar .MuiCircularProgress-root {
    width: 20px !important;
    height: 20px !important;
  }

  .processListInfos {
    display: flex;
    flex-direction: column;

    .processItem {
      border-bottom: solid 1px #D0D3E2;
      padding-bottom: 10px;
      margin-bottom: 10px;
      font-size: 12px;
    }

    &:last-of-type {
      .processItem {
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
  }
}

/* CONFIG COLUMNS POPOVER */
.menuColumnConfig {
  .MuiPaper-root {
    width: 150px;
  }
}