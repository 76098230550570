/* Small Tablet */
.smallTabletMainContainer {
  .dailyCounts {
    .salesAndDiffFilterBox,
    .salesAndDiffFilterBox {
      p,
      span {
        font-size: 12px !important;
      }
    }

    .salesAndDiffFilterBox {
      height: 47px;
    }

    .countMainInfosBox {
      height: 45px;

      .countInfos {
        .countOtherInfos {
          .lineInfo {
            td {
              font-size: 12px !important;
            }
          }
        }
      }
    } 
  }
  
  .countsSubHeader {
    .totalDiffBox {
      margin-right: 6px;
    }

    .diffInfosContainer {
      .leftOverAndLackBox {
        .leftOverBox {
          margin-right: 5px !important;
        }
      }
    }
  }
}