.cropDataVerySmallBox {
  width: 35px;
  height: 26.25px;
  display: flex;
  justify-content: center;
  background: #d0d3e2;
  border-radius: 4px;
  overflow: hidden;
  margin-right: 10px;
}

.cropDatatooltip {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  background: none;

  img {
    max-width: 266.67px;
    max-height: 200px;
    border-radius: 4px;
  }
}

.cropDataSmallBox {
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #d0d3e2;
  border-radius: 4px;
  overflow: hidden;
  margin-right: 10px;

  &.mr0 {
    margin-right: 0;
  }

  p {
    font-size: 12px;
    margin-bottom: 0;
    color: #000000;
    text-align: center;
  }

  img {
    width: auto;
    max-width: 100%;
    max-height: 100%;
  }

  &.skuImgPreview {
    border: solid 1px #9296ac;
  }

  &.largeSize {
    width: 380px;
    height: 380px;
  }
}

.cropDataMediumBox {
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #d0d3e2;
  border-radius: 4px;
  overflow: hidden;

  &.mr0 {
    margin-right: 0;
  }

  p {
    font-size: 12px;
    margin-bottom: 0;
    color: #000000;
    text-align: center;
  }

  img {
    width: auto;
    max-width: 100%;
    max-height: 100%;
  }

  &.skuImgPreview {
    border: solid 1px #9296ac;
  }

  &.largeSize {
    width: 380px;
    height: 380px;
  }
}
.tooltipMultipleImages {
  background: white;
  width: 272px;
  height: 212px;
  border: 1px solid #9296ac;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mr0 {
  margin-right: 0;
}

.mainSkuImage {
  border: 1px solid #f2f3f8;
  border-radius: 4px;
  height: 196px;
  width: 196px;
  margin-left: 8px;

  p {
    font-size: 12px;
    margin-bottom: 0;
    color: #000000;
    text-align: center;
  }

  img {
    width: auto;
    max-width: 100%;
    max-height: 100%;
  }

  &.mediumSize {
    width: 196px;
    height: 196px;
  }
}

.sideInfos {
  height: 196px;
  width: 59px;
  margin-left: 4px;
  margin-right: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
}

.additionalImage {
  border: 1px solid #f2f3f8;
  border-radius: 4px;
  height: 56px;
  width: 56px;
  margin-bottom: 4px;

  p {
    font-size: 12px;
    margin-bottom: 0;
    color: #000000;
    text-align: center;
  }

  img {
    width: auto;
    max-width: 100%;
    max-height: 100%;
  }

  &.smallSize {
    width: 56px;
    height: 56px;
  }
}

.infoSkus {
  border: 1px solid #9296AC;
  border-radius: 4px;
  background-color: #9296AC;
  width: 100%;
  height: 16px;
  text-align: center;
  font-size: 9px;
  font-weight: bold;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
