/* Daily Counts */
.dailyCounts {
  .diaryCountsProductAutocomplete {
    margin-left: 20px;
    margin-top: 6px;
    position: relative;

    input {
      width: 140px;
    }

    .MuiAutocomplete-popperDisablePortal {
      top: 20px;
      left: 70px;
      width: 300px !important;
    }
  }

  .countTabsContainer {
    position: relative;
    width: 764px;

    @media (max-width: 1280px) {
      width: 690px;
    }

    &.sticky {
      position: sticky;
      top: 68px;
      z-index: 100;
      background: transparent;
    }

    .countTitle {
      display: flex;
      align-items: center;

      td {
        font-size: 14px !important;
        margin-left: 5px !important;
      }
    }

    .countTabItem {
      cursor: pointer;
      border: 0.5px solid #1F2445;
      padding: 4px 0px 4px 8px;
      box-shadow: 0px 1px 4px 1px rgba(1, 3, 17, 0.45);
      border-radius: 4px 4px 0px 0px;
      height: 20px;
      display: flex;
      align-items: center;

      &.active {
        background: #5062F0;
        border: 1px solid transparent;

        p {
          color: #FFF;
        }
      }
    }

    .countTabItem:disabled {
      border: 1px solid transparent;
      
      img {
        opacity: 0.5;
      }
    }

    .onLoading{
      border-color:#b8bbcc !important;
      cursor: default;
      p{
        color:#b8bbcc !important;
      }
    }

    .arrowCustomButton {
      padding-left: 0;
      width: 20px;
      height: 20px;
      justify-content: center;
      align-items: center;
      background: #FFFFFF;
      box-shadow: 0px -2px 4px 0px rgba(1, 3, 17, 0.25);
      border-radius: 4px 4px 0px 0px;

      p {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: #1F2445;
        font-weight: bold;
        font-size: 12px;
        height: 100%;
        line-height: 1.6;
      }
    }

    .tabsCarouselContainer {
      display: flex;
      position: relative;
      padding-right: 5.1%;

      @media (max-width: 870px) and (orientation: portrait) {
        padding-right: 4.7%;
        max-width: 655px !important;
      }

      @media (max-width: 602px) and (orientation: portrait) {
        padding-right: 6%;
        max-width: 502px !important;
      }
    }

    .carousel-button-group {
      position: absolute;
      width: 102%;
      top: 0;
      right: -3px;
      justify-content: end;

      @media (max-width: 870px) and (orientation: portrait) {
        width: auto;
        right: -35px;
        height: 45px;
        z-index: 9;

        .countTabItem {
          height: 45px;
          width: 35px;
        }
      }
    }

    .withArrows {
      @media (max-width: 870px) and (orientation: portrait) {
        right: -86px;
        height: 45px;

        .ReportButton {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 45px;
          width: 35px;
        }
      }

      @media (max-width: 602px) and (orientation: portrait) {
        right: -73px;
      }
    }

    .react-multi-carousel-list {
      z-index: 9;
      width: 100%;
      max-width: 100%;
      margin-left: 0;
      margin-right: 0;
      padding-left: 0;

      li {
        height: 20px;
        overflow: hidden;
        width: 193.3px !important;
        background: #FFFFFF;
        box-sizing: border-box;
        box-shadow: 0px 1px 4px 1px rgba(1, 3, 17, 0.45);
        border-radius: 4px 4px 0px 0px;
        margin-right: 5px;
        display: flex;
        align-items: center;

        &:last-of-type {
          margin-right: 0;
        }

        .countTabItem {
          width: 100%;
        }

        td {
          font-size: 11px !important;
        }

        p {
          color: #1F2445;
          font-weight: bold;
          font-size: 12px;
        }

        @media (max-width: 1280px) {
          width: 150px !important;
          height: 45px;

          p,
          td {
            font-size: 14px !important;
            line-height: 1.2;
          }

          .countTabItem {
            height: 45px;
            align-items: flex-start;
            padding: 4px 4px 4px 8px;
          }
        }
      }
    }
  }

  .gridTableCount {
    justify-content: space-between;

    .diaryCountProgressBar {
      justify-content: center;

      .withoutError {
        border-color: #5062f0;
        margin-right: 5px;
        width: 30%;
        height: 20px;
    
        &.MuiLinearProgress-colorSecondary {
          border: solid 1px #5062f0;
          background: transparent;
          box-shadow: 0px 4px 4px 0px #00000040;
        }
    
        .MuiLinearProgress-barColorSecondary {
          background-color: #5062f0;
        }
      }
    }

    @media (max-width: 870px) and (orientation: portrait) {
      margin-top: 6px;
      flex-direction: row;

      .diaryCountProgressBar {
        .withoutError {
          width: 40%;
        }

        span {
          font-size: 14px;
        }
      }
    }
  }

  .countMainInfosBox {
    box-shadow: 0px 4px 4px 0px #000000BF;
    overflow: hidden;
    width: 35%;
    border: solid 1px #5062F0;
    border-radius: 4px;

    .countInfos {
      display: flex;
      font-size: 12px;
      width: 100%;
      height: 53px;

      .countDateInfo {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 4px;
        margin: 2px 9px 2px 2px;
        border: solid 1px #5062F0;
        border-radius: 4px;
      }

      .countOtherInfos {
        width: 100%;

        .lineInfo {
          border-bottom: solid 1px #5062F0;
          padding-bottom: 4px;
          padding-top: 4px;

          &:first-of-type {
            padding-top: 3px;
          }

          &:last-of-type {
            padding-bottom: 3px;
            border-bottom: none;
          }
        }
      }
    }

    @media (max-width: 870px) and (orientation: portrait) {
      width: 36%;
      border: solid 1px #5062F0; 
      height: 52px;
      align-items: center;
      box-shadow: 0px 4px 4px 0px #000000BF;

      .countInfos {
        height: auto;

        span {
          text-transform: uppercase;
          width: 100% !important;
        }

        .countDateInfo {
          border: solid 1px #5062F0; 
          padding: 2px 10px;
          margin: 1px 10px 1px 2px;
          font-size: 14px;
        }

        .countOtherInfos {
          .lineInfo {
            padding: 0px 1px 1px 0 !important;

            span,
            td {
              font-size: 14px !important;
            }
          }
        }
      }
    }
  }

  .otherStock {
    @media (max-width: 870px) and (orientation: portrait) {
      width: 8%;
    }
  }

  .realStock {
    @media (max-width: 870px) and (orientation: portrait) {
      width: 5%;
    }

    .pConsumedTablet {
      @media (min-width: 870px) {
        display: none;
      }
    }

    .pConsumedDesk {
      @media (max-width: 870px) and (orientation: portrait) {
        display: none;
      }
    }
  }

  .percentStock {
    @media (max-width: 870px) and (orientation: portrait) {
      width: 6%;
    }

    .pConsumedTablet {
      @media (min-width: 870px) {
        display: none;
      }
    }

    .pConsumedDesk {
      @media (max-width: 870px) and (orientation: portrait) {
        display: none;
      }
    }
  }

  .teoricValue {
    @media (max-width: 870px) and (orientation: portrait) {
      width: 6%;
    }

    .pTeoricValueTablet {
      @media (min-width: 870px) {
        display: none;
      }
    }

    .pTeoricValueDesk {
      @media (max-width: 870px) and (orientation: portrait) {
        display: none;
      }
    }
  }

  .othersContent {
    @media (max-width: 870px) and (orientation: portrait) {
      flex-direction: row;
      align-items: flex-start;
      line-height: 1.8;
    }
  }

  .realContentDesk {
    @media (max-width: 870px) and (orientation: portrait) {
      display: none !important;
    }
  }

  .realContentTablet {
    @media (min-width: 870px) {
      display: none !important;
    }

    @media (max-width: 870px) and (orientation: portrait) {
      margin-left: 15px;
      text-align: end;
    }
  }

  .tooltipOuthersQuoter {
    @media (max-width: 870px) and (orientation: portrait) {
      display: none;
    }
  }

  .valueTeoricTablet {
    @media (max-width: 870px) and (orientation: portrait) {
      margin-left: 20%;
    }
  }

  .reportSpan {
    @media (max-width: 870px) and (orientation: portrait) {
      display: none !important;
    }
  }

  .content-report-tablet {
    margin-bottom: 0;

    @media (min-width: 870px) {
      display: none !important;
    }
  }

  .othersStocksVolume {
    margin-right: 5px;
    font-size: 10px;
    display: flex;
    flex-direction: column !important;

    @media (max-width: 870px) and (orientation: portrait) {
      flex-direction: row !important;
      margin-right: 0px;

      span {
        margin-right: 5px;
      }
    }
  }

  .iconInfo {
    display: none;

    @media (max-width: 825px) {
      display: flex;
    }
  }

  .content-report-desk {
    margin-bottom: 0;

    // @media (max-width: 870px) and (orientation: portrait) {
    //   display: none !important;
    // }
  }

  .second-line {
    @media (max-width: 870px) and (orientation: portrait) {
      height: 50px;
    }
  }

  .categoryFilter {
    width: 26%;
    height: 53px;
    margin: 5px 15px 0 15px;
    border-radius: 4px;
    padding: 0;

    @media (max-width: 870px) and (orientation: portrait) {
      width: 23%;
      border: none;
      box-shadow: none;
      height: 52px;
      margin: 0 10px;

      p {
        font-size: 14px !important;
      }

      div {
        justify-content: center;
      }
    }

    img {
      position: absolute;
      top: -26px;
      right: 0;
    }

    label {
      margin-bottom: 3px;
    }

    .MuiIconButton-root {
      padding: 0 5px 0px 0 !important;
    }

    .ordinationAndUserFilter {
      position: relative;

      .MuiAutocomplete-popperDisablePortal {
        top: 20px;
        right: 0px;
        width: 285px !important;
      }
    }

    .autocompleteSmall {
      width: auto;
      min-width: 190px;
      height: 20px;
      margin-top: -22px;

      @media (max-width: 870px) and (orientation: portrait) {
        width: 110px;
        min-width: 0;
      }

      .MuiInputBase-root {
        padding-bottom: 3px !important;
      }

      .MuiAutocomplete-endAdornment {
        button {
          top: 4px !important;
        }
      }

      &.userAutocomplete {
        margin-top: -6px;
        margin-left: 32px;
        width: 46.5%;

        @media (max-width: 870px) and (orientation: portrait) {
          margin-left: 15px;
          width: 29%;
        }
      }
    }

    .MuiCheckbox-root {
      svg {
        font-size: 16px;
      }
    }

    .orderButton {
      svg {
        font-size: 16px;
      }
    }
  }

  .salesAndDiffFilterBox {
    overflow: hidden;
    width: 35%;
    height: 53px;
    border-radius: 4px;
    padding-top: 0 !important;
    background: #FFF;
    border: solid 1px #5062F0;
    box-shadow: 0px 4px 4px 0px #000000BF;

    @media (max-width: 870px) and (orientation: portrait) {
      width: 37%;
      margin: 0;
      height: 52px;

      p,
      span {
        font-size: 14px !important;
      }

      .salesBox {
        .salesInfos {
          justify-content: space-between;
          width: 50% !important;
        }
      }
    }

    .salesBox {
      background: #5062F0;
      padding: 1px 6px 6px 6px;
      margin-bottom: 1px;

      p {
        font-size: 12px;
        color: #FFF;
      }

      img {
        box-shadow: 0px 2px 2px 0px #00000040;
      }

      .salesTitle {
        margin-right: 15px;

        &:last-of-type {
          margin-right: 0px;
        }
      }

      .salesInfos {
        width: 38%;

        span {
          font-size: 8px;
        }
      }
    }
  }

  .orderButton {
    margin-bottom: 0 !important;
    margin-right: 0;

    &:first-of-type {
      margin-right: 20px;
    }

    span {
      padding: 0 1px;
      font-family: "Lato";
      font-size: 12px;
    }

    svg {
      color: #5062f0;
    }
  }

  table {
    .textFieldSmall {
      padding: 4px 0;

      .MuiInputBase-root {
        background: #FFF;
      }

      input {
        text-align: right;
        padding-right: 0;
      }

      input::-webkit-input-placeholder {
        padding-right: 2px;
      }
    }

    .dateSmall {
      input {
        padding-bottom: 5px;
      }
    }

    td,
    th {
      font-size: 11px !important;
      vertical-align: middle;
    }

    th {
      font-size: 10px !important;
    }

    @media (max-width: 870px) and (orientation: portrait) {
      thead {
        top: 151px !important;
      }

      .mobileQuantityField {
        .MuiInputBase-root {
          background: #FFF;
          margin-top: 2px;
          height: 40px;
          padding-right: 6px;
        }
      }
      
      tbody {
        td,
        p,
        span,
        input {
          font-size: 14px !important
        }

        td {
          vertical-align: top;
          
          .second-line {
            div {
              align-items: flex-start;

              .ml10 {
                margin-top: 2px;
              }
            }

            td {
              line-height: 1.8;
            }
          }

          &.description {
            height: 43px;
            vertical-align: top !important;
          }

          .mobileSkuDescription {
            td {
              line-height: 1.8;
            }
          }
        }
      }

      .singleCountItem {
        .othersStocksVolume {
          flex-direction: row !important;

          span {
            font-size: 11px;
          }

          .iconInfo {
            margin-top: 4px;
          }

          img {
            box-shadow: 0px 4px 4px 0px #00000040;
            height: 20px;
            border-radius: 4px;
          }
        }
      }
    }
  }

  .fixedBackButtonContainer {
    position: fixed;
    left: 0;
    bottom: 15px;
    min-width: 44px;
    height: 48px;

    .fixedBackButton {
      padding: 12px 6px !important;
      border: 1px solid #333541 !important;
      border-radius: 0px 12px 12px 0px !important;
      min-width: 44px;
      height: 48px;
      background: #5062F0 !important;
      color: #FFF !important;

      span {
        text-transform: uppercase;
      }
    }
  }

  .mobileTableHeader {
    background: #FFF;
    width: 100%;
    box-shadow: 0px 0px 8px 0px #04061A40;
    margin-bottom: -20px;
    padding-bottom: 30px;
    border-radius: 4px 4px 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .diaryCountsProductAutocomplete {
      margin-left: 6px;
      margin-top: 0;
      width: 34.5%;

      label {
        margin-top: 3px !important;
        text-transform: uppercase;
      }

      .autocompleteSmall {
        width: 100%;

        .MuiInputBase-root {
          padding: 4px 65px 4px 0 !important;
        }
      }
    }
  }

  @media (max-width: 870px) and (orientation: portrait) {
    .fakeWhiteBg {
      height: 150px !important;
    }

    .mobileTableHeader {
      margin-bottom: -43px;
      padding-bottom: 50px;

      p,
      span,
      label,
      input {
        font-size: 14px !important;
      }

      .diaryCountsProductAutocomplete {
        width: auto;
      }
    }
  }
}

/* Column Config Popover */
.columnConfigPopover {
  .MuiPopover-paper {
    left: 172px !important;
    top: 232px !important;

    @media (max-width: 870px) and (orientation: portrait) {
      left: 132px !important;
      top: 329px !important;
    }
  }
}

/* Stock Details Popover */
.stockDetailsPopover {
  @media (max-width: 870px) and (orientation: portrait) {
    .MuiPopover-paper {
      width: auto;
      padding: 10px 5px;
      right: 4%;
      left: auto !important;

      .defaultBox {
        width: 75px;
      }

      img {
        width: 44px !important;
        height: 44px;
      }

      span {
        font-size: 14px;
        margin-top: 2px;
      }
    }
  }
}

.countsCircularProgressBar {
  .MuiCircularProgress-root {
    padding: 8px 8px 0 8px;
    margin-bottom: 5px;
  }
}

.colorFont {
  color: #000;
}

/*Counts List*/
.countsSubHeader {
  margin-bottom: 16px;
  
  @media (max-width: 870px) and (orientation: portrait) {
    margin-top: 5px;
    margin-bottom: 10px;
  }

  .productsOutOfCountBox {
    @media only screen and (max-width: 768px) {
      display: none;
    }

    @media (max-width: 870px) and (orientation: portrait) {
      display: none !important;
    }

    background: #C38D00;
    display: flex;
    padding: 5px 8px;

    p,
    span {
      color: #FFF;
      margin-bottom: 0;
      font-size: 12px;
      font-family: 'Lato';
    }

    span {
      cursor: pointer;
      text-decoration: underline;
      margin-left: 5px;
    }
  }

  .diffInfosContainer {
    width: 50%;
    display: flex;
    justify-content: flex-end;

    @media (max-width: 870px) and (orientation: portrait) {
      width: 100%;

      .leftOverAndLackBox {
        span {
          text-transform: uppercase;
        }

        .justifyEnd {
          span {
            padding: 2px 10px !important;
          }
        }
      }
    }

    @media (max-width: 480px) {
      flex-direction: column;

      .leftOverAndLackBox {
        flex-direction: column;
        margin: 0;
  
        .leftOverBox {
          margin: 5px 0;
        }

        .leftOverBox,
        .lackBox {
          .bold {
            padding-left: 0 !important;
          }
        }
      }
    }
  }

  .totalDiffBox {
    margin-right: 45px;

    .totalDiffBoxLabel,
    .totalDiffBoxValue {
      padding: 5px 6px;
    }

    .totalDiffBoxValue {
      border-radius: 50px;
      box-shadow: 0px 4px 4px 0px #00000040;
    }

    @media (max-width: 870px) and (orientation: portrait) {
      span {
        text-transform: uppercase;
      }

      .totalDiffBoxValue {
        padding: 2px 10px;
      }
      
      .totalDiffBoxLabel {
        padding-left: 0px;
      }
    }
  }
}

/*Count*/
.theoricalInCount {
  @media only screen and (max-width: 1000px) {
    display: none;
  }

  background: #FFF2CC;
  border: 1px solid #F7D56E;
  border-radius: 4px;
  display: flex;
  padding: 2px 6px;
  line-height: 1.4;

  p,
  span {
    color: #AA8208;
    margin-bottom: 0;
    font-size: 12px;
    font-family: 'Lato';
  }
}

.countsList {
  tbody {
    @media (max-width: 870px) and (orientation: portrait) {
      .playCountBox {
        align-items: flex-start;
  
        button {
          margin-top: 7px;
        }
      }

      td {
        vertical-align: top;
        
        .playByCount {
          td {
            line-height: 1.4;
            margin-top: 6px;
            padding-right: 8px;
          }
        }

        &.countTypeColumn {
          span {
            line-height: 1.4;
            margin-top: 6px;
            display: block;
          }
        }

        .playByStorageLocation {
          td {
            line-height: 1.4;
            margin-top: 6px !important;
            display: block;
          }
        }
      }

      .hasDiffTheoreticalBox {
        p {
          margin: 0 6px 0 0 !important;
        }
      }

      .hasDiffTheoreticalIcon {
        display: none !important;
      }
    }

    .hasDiffTheoreticalBox {
      p {
        margin: 0 6px 0 0 !important;
      }
    }
  }

  .notFinished {
    .MuiLinearProgress-barColorSecondary {
      background: #F2B90D !important;
    }
  }

  .withError {
    &.MuiLinearProgress-colorSecondary {
      border: solid 1px #e22121;
      box-shadow: 0px 4px 4px 0px #00000040;
    }

    .MuiLinearProgress-barColorSecondary {
      background-color: #e22121;
    }
  }

  .withoutError {
    border-color: #5062f0;

    &.MuiLinearProgress-colorSecondary {
      border: solid 1px #5062f0;
      background: transparent;
      box-shadow: 0px 4px 4px 0px #00000040;
    }

    .MuiLinearProgress-barColorSecondary {
      background-color: #5062f0;
    }
  }

  .withErrorBold {
    &.MuiLinearProgress-colorSecondary {
      border: solid 2px #e22121;
      box-shadow: 0px 4px 4px 0px #00000040;
    }

    .MuiLinearProgress-barColorSecondary {
      background-color: #e22121;
    }
  }

  .withoutErrorBold {
    &.MuiLinearProgress-colorSecondary {
      border: solid 2px #5062f0;
      background: transparent;
      box-shadow: 0px 4px 4px 0px #00000040;
    }

    .MuiLinearProgress-barColorSecondary {
      background-color: #5062f0;
    }
  }
}

/* Counts List Dialogs */
.outOfCountProductsDialog {
  th {
    z-index: 999;
  }

  .MuiFormControlLabel-root {
    margin-left: 0;
  }

  .MuiRadio-root {
    padding: 5px 10px 3px 15px;

    &:first-of-type {
      padding-left: 0;
    }
  }
}

/* Has Catalog Changes Dialog */
.hasCatalogChangesDialog {
  width: 37%;
  height: 110px;
  margin: auto auto 0 auto;
  bottom: 1px;

  .MuiPaper-root {
    width: 100%;
    position: relative;
    background: #FFF2CC;
    max-width: 500px;
  }

  .MuiAlert-message {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    p {
      color: #AA8208;
    }
  }

  svg {
    color: #AA8208;
  }

  .MuiAlert-action {
    position: absolute;
    right: 12px;
    bottom: 11px;
  }
  
  .modalHeader {
    background: #FFF2CC;

    p {
      color: #AA8208;
      text-transform: initial;
    }
  }

  .MuiDialog-scrollPaper {
    align-items: flex-end;
  }

  .MuiBackdrop-root {
    display: none;
  }

  @media (max-width: 870px) and (orientation: portrait) {
    width: 77%;
  }
}

/* Mobile Detailed Stock Dialog */
@media (max-width: 870px) and (orientation: portrait) {
  .stockDetailedDialog,
  .configPackageDialog,
  .discardDialog,
  .countPageExtractDialog {
    .MuiDialog-scrollPaper {
      align-items: flex-start;
      margin-top: 60%;
    }
  }

  .stockDetailedDialog {
    width: 100%;
  }
}

/* Consumed Entry and Exit Tooltip */
.consumedEntryBox,
.consumedExitBox {
  p,
  span {
    color: #FFF;
  }

  span {
    font-weight: bold;
  }

  .consumedEntryTitle {
    color: #7583F0;
  }

  .consumedExitTitle {
    color: #F76E79;
  }

  p {
    margin-bottom: 1px;
  }

  span {
    margin-bottom: 2px;
    display: block;
  }
}