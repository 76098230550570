.onHoverSideBar {
  box-shadow: 0px 2px 6px 2px rgba(1, 3, 17, 0.45);
  background-color: white !important;
  width: 195px;
  border-radius: 8px !important;
  overflow: hidden;
  align-items: flex-start !important;

  &.notifications {
    width: 225px;
  }

  .onHoverSideBarHeader {
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .hoverItemTitle {
      background: #5062F0;
      color: #FFF;
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
      padding: 8px 0px 8px 12px;
      width: 100%;
      border-radius: 8px 8px 0px 0px;
    }
  }

  .topDivider {
    border-top: solid 1px #5062F0;
    padding: 10px 0 0 !important;
    margin: 10px 12px 0 12px;
    width: calc(100% - 24px);
  }

  .topDivider + .topDivider {
    border-top: none;
    padding: 10px 12px 0 !important;
    margin: 0;
    width: 100%;
  }

  .hoverItemDefault {
    color: #0B1A8E;
    padding: 8px 12px 0;
    font-size: 16px;

    &:last-of-type {
      padding-bottom: 12px !important;
    }
  }
}

