.productTagsManagerModal {
  table {
    .headerTitle {
      th {
        &:first-of-type {
          padding: 0 35px !important;
        }
      }
    }

    svg {
      width: 18px;
    }

    label {
      margin-bottom: 0;

      .MuiIconButton-root {
        padding: 0 8px;
      }

      .MuiFormControlLabel-label {
        font-size: 12px;
      }
    }
  }

  .MuiDialogActions-root {
    button {
      font-size: 12px !important;
      padding: 6px 18px !important;
    }
  }
}