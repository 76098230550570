.pdfViewerDialog {
  .MuiDialog-paperWidthSm {
    max-width: 100%;
  }

  .textLayer,
  .annotationLayer {
    display: none;
  }
}
