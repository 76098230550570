.tagsListContainer {
  display: flex;
  flex-direction: column;
  width: 100%;

  .tagsListTable {
    .headerSubtitle {
      .subtitle {
        font-size: 9px;
      }
    }
  }

  tr {
    display: table;
    table-layout: fixed;
    width: 100%;
  }

  tbody {
    height: 100px;
    overflow: auto;
    display: block;
  }

  .three-dots {
    display: block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
