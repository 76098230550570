.miseEnPlacePage {
  margin-top: 25px;

  .fullScreenButton {
    position: absolute;
    top: 15px;
    right: 220px;
  }

  .fullscreen-enabled {
    &:not(:root):fullscreen::backdrop {
      background: #FFF !important;
      position: absolute !important;
      height: 100vh !important;
      width: 100%;
    }

    .sortableContainer {
      margin-top: 60px;
    }
  }

  .fakeSelectButton {
    display: flex;
    align-items: center;
    position: absolute;
    right: 5px;
    top: 11px;
    background-color: transparent;

    img {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      width: 40px;
      box-shadow: 4px 4px 4px 0px #000000BF;
      padding: 5px 10px 7px;
      border: solid 1px #454A67;
      border-radius: 0 0 12px 12px;
      background: transparent;

      &.selectedCourt {
        background: #FFF;
      }
  
      &.active {
        background: #5062F0;
  
        span {
          color: #FFF;
        }
      }
    }

    span {
      text-transform: uppercase;
      color: #5062F0;
      font-weight: bold;
      font-size: 14px;
      margin-right: 10px;
    }
  }

  .sortableContainer {
    width: 100%;
    margin-right: 60px;
  }
}

.miseEnPlaceCard {
  box-shadow: 3px 3px 6px 0px #000000BF;
  border: solid 4px #5062F0;
  border-radius: 0 0 18px 18px;
  width: calc(100% / 2 - 8px);
  height: 120px;
  display: flex;
  margin-right: 15px;
  margin-bottom: 13px;
  float: left;
  position: relative;

  span {
    user-select: none;
  }

  .leftInfosBox {
    padding: 5px 7px;
    width: 41.5%;
    display: flex;
    flex-direction: column;
    border-right: solid 1px #1F2445;

    .imageBox {
      border-radius: 4px;
      width: auto;
      height: 54px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      overflow: hidden;

      .cardOrderNumber {
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 4px 4px 4px 0px #000000BF;
        position: absolute;
        background: #5062F0;
        border-radius: 4px;
        top: 0;
        left: 0px;
        width: 14%;
    
        span {
          color: #FFF;
          font-size: 12px;
        }
      }

      img {
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
      }

      &.hasNotImage {
        img {
          width: auto;
          height: 100%;
          max-width: 100%;
          max-height: 100%;
        }
      }

      &.imageFakeBg {
        background: #DADDF3;
      }

      &.internalProduct {
        border: 1px solid rgba(8, 145, 145, 1);
      }

      &.externalProduct {
        border: 1.5px solid rgba(80, 98, 240, 1);
        box-shadow: 4px 4px 4px 0px #000000E5;
      }
    }

    p {
      display: flex;
      align-items: center;
      font-weight: bold;
      color: #1F2445;
      margin-top: 0px;
      font-size: 12px;
      line-height: 1.3;
      margin-top: 2px;

      td {
        font-size: 12px !important;
      }
    }
  }

  .rightInfosBox {
    padding: 5px 7px;
    width: 58.5%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: rgba(242, 185, 13, .15);

    .title,
    .subtitle {
      z-index: 1;
    }

    .fakeBorderCover {
      width: 100%;
      border-radius: 2px 2px 6px 6px;
      background: #1F2445;
      margin-bottom: -5px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 2px 18px;
      height: 17.6px;
      z-index: 1;

      .productionCardInfo {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        border-radius: 4px 4px 0 0;
        padding: 1px 5px 1px 0px;
 
        span {
          color: #F2B90D;
          font-size: 11px;   
          font-weight: bold; 
          line-height: 1.3;
          position: relative;
          margin: -1px 0 1px 0
        }
    
        img {
          margin-right: 7px;
          width: 15px;
          z-index: 9;
        }
      }
    }

    .progressBox {
      border: 2px solid #1F2445;
      border-radius: 0 0 12px 12px;
      padding: 8px 10px 0 10px;
      overflow: hidden;
      width: 88%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      height: 85.4px;
      position: relative;

      .alertIcon {
        left: 5px;
        top: 8px;
        width: 18px;
        position: absolute;
      }

      span {
        font-weight: bold;
        color: #010311;
        font-size: 16px;
        margin-bottom: 8px;
      }

      .fakeBackgroundCover {
        width: 100%;
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 0;
      }
    }
  }

  &:nth-of-type(2n) {
    margin-right: 0;
  }
}

.miseEnPlaceCourtOptionsDialog {
  .MuiDialog-scrollPaper {
    align-items: flex-start;
    margin-top: 150px;
  }

  .MuiPaper-root {
    max-width: 800px;
    width: 450px;
    box-shadow: 4px 4px 4px 0px #000000BF;
    border-radius: 8px;
  }

  .modalContent {
    padding: 0;

    .courtOption {
      padding: 10px 20px;
      border-bottom: solid 1px #454A67;

      span {
        font-weight: bold;
        color: #5062F0;
      }

      &.active {
        background: #E6E7F0;

        span {
          color: #9296AC;
        }
      }
    }
  }
}

/* Tablet Horizontal */
@media screen and (min-width: 600px) and (max-width: 1366px) and (orientation: landscape) {
  .miseEnPlacePage {
    margin-top: 30px;

    .fakeSelectButton {
      right: 8px;
      top: 10px;
    }

    .miseEnPlaceCard {
      width: calc(100% / 3 - 10px);
      margin-right: 15px;
      
      &:nth-of-type(2n) {
        margin-right: 15px;
      }

      &:nth-of-type(3n) {
        margin-right: 0;
      }
    }
  }
}