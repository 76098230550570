.commonInput{
  > div{
    padding-top: 25px !important;
  }
  &.minorInput{
    >label{
      margin-left: auto;
    }
    > div{
      width: 80px;
      margin-left: auto;
    }
  }
} 

.backButton.personal{
  height: 19px;
}
.so-treeSelect-indicator{
  top: 73%;
}
.labelRight{
  label{
    margin-left: auto;
  }
}

.textFieldStock {
  width: 100%;
  
  div {
    input {
      padding-top: 10px !important;
    }
  }
}

.inLineTextfield{
  display: flex !important;
  flex-wrap: nowrap !important;
  flex-direction: row !important;
  padding-right: 16px !important;
  align-items: baseline !important;
  >label{
    transform: none !important;
    position: relative;
    width: 225px !important;
  }
  >div{
    width: 68px !important;
  }
}

.headerTable{
  background-color: #000 !important;
  tr{
    background-color: #313347 !important;
    th, span{
      font-size: 9px !important;
    }
  }
}

.personalDatePicker .dateSmall {
  >div{
    margin-top: 25px;
    flex-direction: row-reverse;
    width: 68px;
    .MuiInputAdornment-positionEnd{
      margin-left: 0px !important;
      margin-right: 4px;
    }
  }
  span.MuiIconButton-label{
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M5.55556 1C5.8317 1 6.05556 1.22386 6.05556 1.5V2.3H9.94444V1.5C9.94444 1.22386 10.1683 1 10.4444 1C10.7206 1 10.9444 1.22386 10.9444 1.5V2.3H12.2778C13.2575 2.3 14 3.1354 14 4.1V13.2C14 14.1646 13.2575 15 12.2778 15H3.72222C2.74245 15 2 14.1646 2 13.2V4.1C2 3.1354 2.74245 2.3 3.72222 2.3H5.05556V1.5C5.05556 1.22386 5.27941 1 5.55556 1ZM5.05556 3.3H3.72222C3.35196 3.3 3 3.62866 3 4.1V6.2H13V4.1C13 3.62866 12.648 3.3 12.2778 3.3H10.9444V4.1C10.9444 4.37614 10.7206 4.6 10.4444 4.6C10.1683 4.6 9.94444 4.37614 9.94444 4.1V3.3H6.05556V4.1C6.05556 4.37614 5.8317 4.6 5.55556 4.6C5.27941 4.6 5.05556 4.37614 5.05556 4.1V3.3ZM13 7.2H3V13.2C3 13.6713 3.35196 14 3.72222 14H12.2778C12.648 14 13 13.6713 13 13.2V7.2Z" fill="%23313347"/></svg>');
  background-repeat: no-repeat;
  background-size: contain;
  svg{
    visibility: hidden;
  }
}
}

.customToggle{
  >span.MuiSwitch-root{
    .MuiIconButton-label{

    }
    .MuiSwitch-track{
      width: 24px;
      height: 16px;
    }
  }
  .MuiSwitch-switchBase.Mui-checked{
    transform: translateX(10px);
  }
  .MuiFormControlLabel-label{
    margin-left: -10px;
  }
}

.personalInputDaily .MuiAutocomplete-popupIndicator{
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2IiBmaWxsPSJub25lIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik04IDRDOC4yNzYxNCA0IDguNSA0LjIyMzg2IDguNSA0LjVWNy41SDExLjVDMTEuNzc2MSA3LjUgMTIgNy43MjM4NiAxMiA4QzEyIDguMjc2MTQgMTEuNzc2MSA4LjUgMTEuNSA4LjVIOC41VjExLjVDOC41IDExLjc3NjEgOC4yNzYxNCAxMiA4IDEyQzcuNzIzODYgMTIgNy41IDExLjc3NjEgNy41IDExLjVWOC41SDQuNUM0LjIyMzg2IDguNSA0IDguMjc2MTQgNCA4QzQgNy43MjM4NiA0LjIyMzg2IDcuNSA0LjUgNy41SDcuNVY0LjVDNy41IDQuMjIzODYgNy43MjM4NiA0IDggNFoiIGZpbGw9IiMzMzM1NDEiLz4KPC9zdmc+");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  svg{
    visibility: hidden;
  }
}

.personalInputDaily {
  margin-bottom: 10px;
  
  .MuiFormLabel-root {
    top: 25px;
  }
}

.customCategorySelector {
  &.selectSmall {
    border-bottom: none !important;
  }

  .ant-select-selector {
    padding-top: 0 !important
  }

  .ant-select-selection-item {
    line-height: 32px;
  }

  input {
    height: 24px;
  }
}

.commonInput.right{
  label{
    text-align: right;
    width: 100%;
  }
}