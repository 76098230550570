/* Mobile */
@media screen and (min-device-width: 320px) and (max-device-width: 1024px), screen and (max-device-width: 1366px) and (min-device-height: 1024px) {
  .MuiDrawer-paperAnchorDockedLeft {
    display: none !important;
  }
  
  .MuiDrawer-paperAnchorLeft {
    background: #080B30;
    padding: 0;
    width: 290px;

    .sidebar {
      padding: 0;

      .sidebar-list-menu {
        background: #080B30;

        .sidebar-item {
          background: #080B30;
        }
      }
    }
  }
}