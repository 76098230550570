.monthlyEvolutionPage {
  .evolutionTag {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    max-height: 18px;
    color: #FFF;
    font-weight: bold;
    border-radius: 50px;
    background: #5062F0;
    padding: 3px 10px;
    box-shadow: 0px 4px 4px 0px #00000040;
  }
  
  .totalInfosHeader {
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
    width: 68.45%;

    .fakeDatePicker {
      .dateSmall {
        margin: 0 !important;

        .MuiInputBase-root {
          padding: 0 !important;

          fieldset {
            border-bottom: none;
          }

          svg {
            display: none;
          }

          .MuiInputAdornment-root {
            display: none;
          }

          input {
            text-align: right;
            border-bottom: solid 1px;
            width: 75%;
            margin: auto 0 auto auto;
            padding: 4px 0 3px 0;
            font-weight: bold;
            color: #5062F0;
            cursor: pointer;
          }
        }
      }
    }

    .oldInfos {
      background: #FFF2CC;
      border: 2px solid #AA8208;
      border-right: none;
      border-bottom: none;
      border-radius: 2px 0 0 0;

      span {
        width: 15%;
      }
    }

    .oldInfos,
    .evolutionInfos {
      width: 50.1%;
      padding: 5px;
      display: flex;
      align-items: center;

      span {
        font-size: 12px;
        text-align: right;

        &.customMarginRight {
          margin-right: 2px;
        }

        &.fakeDatePicker {
          padding-left: 0;
          text-decoration: underline;
          width: 10.9%;
        }

        &.totalCost {
          width: 16.8%;
        }

        &.evolutionTag {
          width: 14.3%;
        }

        &.totalLast {
          width: 18%;
          padding-left: 8px;
        }

        &.totalValue {
          width: 15%;
          padding-left: 8px;
        }

        &.totalDiff {
          width: 17%;
          padding-left: 8px;
        }

        &.totalProvider {
          width: 7%;
          padding-left: 8px;
        }
      }
    }

    .evolutionInfos {
      width: 49.9%;
      background: #DADDF3;
      border: 2px solid #00094C;
      border-bottom: none;
      border-radius: 0 2px 0 0;

      span {
        width: 15.5%;
      }
    }
  }
}