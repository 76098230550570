/* Typography */
h1,
h2,
h3,
h4,
h5,
h6,
p,
i,
figcaption,
a,
span,
li,
label,
strong,
select,
td,
th,
div {
  &.fontFamilyDefault {
    font-family: 'Lato';
  }

  &.bold {
    font-weight: 700;
  }

  &.semiBold {
    font-weight: 500;
  }

  &.italic {
    font-style: italic !important;
  }

  &.withStroke {
    text-shadow: 2px 0 0 #000, -2px 0 0 #000, 2px 0 #000, 0 -2px 0 #000, 1px 1px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,1px 1px 5px #000;
  }

  &.fontWeightNormal {
    font-weight: normal !important;
  }
}

.fontSizeDefault {
  font-size: 12px !important;
}

.fontSize9 {
  font-size: 9px !important;
}

.fontSize10 {
  font-size: 10px !important;
}

.fontSize11 {
  font-size: 11px !important;
}

.fontSize14 {
  font-size: 14px !important;
}

.fontSize16 {
  font-size: 16px !important;
}

.childInputFontSize16 {
  input {
    font-size: 16px !important;
  }
}

.childTdFontSize10 {
  td {
    font-size: 10px !important;
  }
}

.childTdFontSize11 {
  td {
    font-size: 11px !important;
  }
}

.childTdFontSize14 {
  td {
    font-size: 14px !important;
  }
}

.childTdFontSize16 {
  td {
    font-size: 16px !important;
  }
}

.childInputFontSize20 {
  input {
    font-size: 20px !important;
  }
}

.childTdFontSize18 {
  td {
    font-size: 18px !important;
  }
}

.childTdFontSize20 {
  td {
    font-size: 20px !important;
  }
}

.childTdFontSize22 {
  td {
    font-size: 22px !important;
  }
}

.childBold {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  i,
  figcaption,
  a,
  span,
  li,
  label,
  strong,
  select,
  td,
  th,
  div {
    font-weight: bold;
  }
}
