@media (max-width: 870px) and (orientation: portrait) {
  .extractModal {
    max-width: 100%;
    width: 100%;

    .theoryStockHeaderMenu {
      .MuiPaper-root {
        margin: 0;
        max-width: 100%;
        width: 93%;
      }
      
      .MuiGrid-grid-sm-3 {
        max-width: 19%;
        flex-basis: 19%;
      }

      .MuiGrid-grid-sm-2, 
      .MuiGrid-grid-sm-3,
      .MuiGrid-grid-sm-4 {
        padding-right: 10px !important;
      }

      .MuiGrid-grid-sm-2 {
        max-width: 20%;
        flex-basis: 20%;
      }
    }

    .theoryStockSubHeaderMenu {
      .productFilterAutocomplete {
        width: 75%;

        .MuiFormControl-root {
          width: 80%;
        }
      }

      .lastSevendDaysViewBox {
        width: 25% !important;
        display: flex;
        justify-content: flex-end;
        padding-right: 16px;
      }
    }
  }
}