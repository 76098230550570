@import './variables';

/* Inputs With Modal Options */
.itemWithModal {
  .MuiAutocomplete-root {
    width: 90%;

    .MuiFormControl-root {
      width: 100% !important;
    }
  }

  .modalButton {
    padding: 5px 0 5px 10px;
  }
}

.itemWithModalSecondary {
  .MuiAutocomplete-root {
    width: 100%;

    .MuiFormControl-root {
      width: 100% !important;
    }
  }

  .modalButton {
    padding: 5px 0 5px 10px;
  }
}

/* Input Without Legend */
.inputWithoutLegend {
  fieldset {
    top: 0;

    legend {
      display: none;
    }
  }
}

/* Custom Restaurant Multiple Select */
.restaurantsCustomSelect {
  display: flex;
  margin: 0 auto;
  width: 30%;

  .MuiInputLabel-outlined {
    transform: translate(192%, 16px) scale(1.25) !important;
    color: $materialBlue;

    &.MuiInputLabel-shrink {
      transform: translate(207%, -7px) scale(1) !important;
    }
  }

  .MuiAutocomplete-inputRoot {
    padding: 10px 5px !important;
    border-radius: 100px;
    border-color: $materialBlue;

    fieldset {
      legend {
        text-align: center;
      }
    }

    .MuiAutocomplete-tag {
      background: $materialBlue;
      color: $white;

      svg,
      span {
        color: $white;
      }
    }
  }
}

/* Filter Container */
.filterContainer {
  overflow: hidden;

  .MuiExpansionPanelDetails-root {
    .filterBox {
      max-width: calc(100% + 8px);
      flex-basis: calc(100% + 8px);
    }

    .textFieldSearchIcon {
      width: 13px;
      bottom: 22px;
      position: absolute;
    }
  }
}

.rangeFilter {
  width: 15%;
  input {
    padding-top: 5px;
  }

  span {
    font-family: "Lato";
    font-style: italic;
    font-weight: bold;
    font-size: 11px;
    color: #313347;
    margin: 5px 10px 0;
  }

  .inputBox {
    div {
      width: 30%;
      margin-right: 0;

      div {
        width: 100%;
      }

      .MuiInputAdornment-positionStart {
        display: none;
      }
    }
  }
}

/* Fake Label */
.fakeLabel {
  font-family: "Lato";
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  color: #00020F;
  margin-bottom: 0;
}

/* Autocomplete Small */
.autocompleteSmall,
.autocompleteSmallProducts,
.textFieldSmall,
.dateSmall {
  input,
  label,
  textarea {
    font-size: 12px;
    font-family: "Lato";
  }

  input {
    &::placeholder,
    &::-webkit-input-placeholder {
      font-style: italic;
      color: #77798B;
      opacity: .5 !important;
    }
  }

  label[data-shrink=false]+.MuiInputBase-formControl .MuiInputBase-input::-webkit-input-placeholder {
    opacity: .5 !important;
  }

  label {
    transform: translate(0px, -15px) scale(1) !important;
    color: #000;
    font-weight: bold;
  }

  fieldset {
    border: none;
    border-bottom: solid 1px #313347;
  }

  &.right {
    label {
      width: 100%;
    }

    label,
    input {
      text-align: right;
    }
  }

  &.autocompletePtChild0 {
    .MuiInputBase-root {
      padding-top: 0 !important;
    }
  }
}

.autocompleteCourtMobile {
  input,
  label,
  textarea {
    font-size: 12px;
    font-family: "Lato";
  }

  input {

    &::placeholder,
    &::-webkit-input-placeholder {
      font-style: italic;
      color: #fff;
      opacity: .5 !important;
    }

    color: #fff;
    padding: 5px 0 0 0 !important;
  }

  .MuiAutocomplete-endAdornment {
    padding: 0 !important;
  }

  fieldset {
    border: none;
    border-bottom: solid 1px #fff !important;
    height: 30px;
    border-radius: 0;
  }

  &.right {
    input {
      text-align: right;
    }
  }

  &.autocompletePtChild0 {
    .MuiInputBase-root {
      padding-top: 0 !important;
    }
  }

  @media (max-width: 850px) {
    .MuiInputBase-root {
      padding: 0 65px 0 9px !important
    }
  }
}

.autocompleteSmall {
  .MuiAutocomplete-inputRoot {
    border-radius: 0 !important;
    padding: 5px 65px 7px 0px;
    padding-left: 0 !important;
    flex-wrap: unset;

    &.Mui-focused {
      flex-wrap: wrap;
    }
  }

  .MuiAutocomplete-tag {
    margin: 0 3px;
    background: rgba(80, 98, 240, 0.25);

    span {
      color: #5062F0;
    }
  }

  .MuiChip-root,
  .MuiChip-label {
    font-size: 12px;
    height: 18px;
    display: flex;
    align-items: center;
    margin-top: 1px;
  }

  input {
    padding-left: 0 !important;
    padding-bottom: 0 !important;
  }

  &.date {
    .MuiInputBase-root {
      border-radius: 0;
    }

    input {
      margin-bottom: 6px;
    }
  }

  &.p0 {
    .MuiAutocomplete-inputRoot {
      padding-right: 0px !important;
    }
  }

  &.withoutLabel {
    label {
      display: none !important;
    }

    label+.MuiInput-formControl {
      margin-top: 9px !important;
      margin-bottom: 7px !important;
    }
  }

  &.multipleNoExpand {
    .Mui-focused {
      height: 27px;
      overflow: hidden;

      .MuiAutocomplete-tag {
        margin-bottom: 5px !important;
        position: relative;
        z-index: 999;
        margin-top: 3px !important;

        &:first-of-type {
          margin-bottom: -3px !important;
          margin-top: 3px !important;
        }
      }

      input {
        position: absolute;
        top: 4px;
        width: 100%;

        &::placeholder,
        &::-webkit-input-placeholder {
          opacity: 0 !important;
          color: #FFF;
        }
      }
    }
  }
}

.autocompleteSmallProducts {
  .MuiAutocomplete-inputRoot {
    border-radius: 0 !important;
    padding: 5px 65px 7px 0px;
    flex-wrap: unset;

    &.Mui-focused {
      flex-wrap: wrap;
    }
  }

  .MuiAutocomplete-tag {
    margin: 0 3px;
    background: rgba(80, 98, 240, 0.25);

    span {
      color: #5062F0;
    }
  }

  .MuiChip-root,
  .MuiChip-label {
    font-size: 12px;
    height: 18px;
    display: flex;
    align-items: center;
    margin-top: 1px;
  }

  input {
    padding-left: 5px !important;
    padding-bottom: 5px !important;
  }

  &.date {
    .MuiInputBase-root {
      border-radius: 0;
    }

    input {
      margin-bottom: 6px;
    }
  }

  &.p0 {
    .MuiAutocomplete-inputRoot {
      padding-right: 0px !important;
    }
  }

  &.withoutLabel {
    label {
      display: none !important;
    }

    label+.MuiInput-formControl {
      margin-top: 9px !important;
      margin-bottom: 7px !important;
    }
  }

  &.multipleNoExpand {
    .Mui-focused {
      height: 27px;
      overflow: hidden;

      .MuiAutocomplete-tag {
        margin-bottom: 5px !important;
        position: relative;
        z-index: 999;
        margin-top: 3px !important;

        &:first-of-type {
          margin-bottom: -3px !important;
          margin-top: 3px !important;
        }
      }

      input {
        position: absolute;
        top: 4px;
        width: 100%;

        &::placeholder,
        &::-webkit-input-placeholder {
          opacity: 0 !important;
          color: #FFF;
        }
      }
    }
  }
}

.verySmall {
  width: 70px !important;
  .MuiAutocomplete-inputRoot {
    border-radius: 0 !important;
    padding: 5px 30px 7px 0px !important;
    flex-wrap: unset;

    &.Mui-focused {
      flex-wrap: wrap;
    }
  }

  .MuiAutocomplete-popupIndicator {
    padding: 2px;
    margin-right: -10px;
  }

  // .MuiAutocomplete-clearIndicator {
  //   margin-right: -15px;
  // }

  .MuiAutocomplete-tag {
    margin: 0 3px;
    background: rgba(80, 98, 240, 0.25);

    span {
      color: #5062F0;
    }
  }

  .MuiChip-root,
  .MuiChip-label {
    font-size: 12px;
    height: 18px;
    display: flex;
    align-items: center;
    margin-top: 1px;
  }

  input {
    padding-left: 0 !important;
    padding-bottom: 0 !important;
  }

  &.date {
    .MuiInputBase-root {
      border-radius: 0;
    }

    input {
      margin-bottom: 6px;
    }
  }

  &.p0 {
    .MuiAutocomplete-inputRoot {
      padding-right: 0px !important;
    }
  }

  &.withoutLabel {
    label {
      display: none !important;
    }

    label+.MuiInput-formControl {
      margin-top: 9px !important;
      margin-bottom: 7px !important;
    }
  }

  &.multipleNoExpand {
    .Mui-focused {
      height: 27px;
      overflow: hidden;

      .MuiAutocomplete-tag {
        margin-bottom: 5px !important;
        position: relative;
        z-index: 999;
        margin-top: 3px !important;

        &:first-of-type {
          margin-bottom: -3px !important;
          margin-top: 3px !important;
        }
      }

      input {
        position: absolute;
        top: 4px;
        width: 100%;

        &::placeholder,
        &::-webkit-input-placeholder {
          opacity: 0 !important;
          color: #FFF;
        }
      }
    }
  }
}

/* Remove arrow down and up of inputs with type number */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.textFieldSmall {
  &.withSearchIcon {
    input {
      padding-left: 20px;
    }
  }

  &.right {
    .MuiInputBase-formControl {
      input {
        text-align: right;
        padding-right: 0;
      }
    }
  }

  &.disabledTextWithoutStyle {
    input {
      color: #000;
    }

    fieldset {
      border: none;
    }
  }



  .MuiInputBase-formControl {
    padding-left: 0;

    .MuiInputAdornment-marginDense {
      display: none;
    }

    input {
      text-align: left;
    }

    fieldset {
      padding-left: 0;
    }
  }

  .MuiSelect-selectMenu {
    min-height: auto;
    padding-top: 8px !important;
    padding-left: 0;
    padding-bottom: 4px;
    font-size: 12px;
    font-family: "Lato";

    &:focus {
      background: transparent;
    }
  }

  .MuiInputBase-root {
    border-radius: 0 !important;
  }

  input {
    padding-bottom: 1px;
    padding-left: 0;
    margin-bottom: 5px;
  }
}

.selectSmallBox {
  label {
    color: #000;
    font-weight: bold;
    font-size: 12px;
    font-family: "Lato";
    position: absolute;
    top: -18px;
  }

  .selectSmall {
    border: none !important;
    border-bottom: solid 1px !important;
    border-radius: 0 !important;

    .MuiSelect-select {
      padding: 6px 20px 6px 0;
      font-size: 12px;
      font-family: "Lato";

      &:focus {
        background: transparent !important;
      }
    }

    &.so-input-focus {
      border-color: #000 !important;
      background: transparent;
    }

    .so-treeSelect-result {
      padding-left: 0;
    }

    span {
      font-size: 12px;
      font-family: "Lato";
      margin-bottom: 8px;
    }

    &:focus {
      border-color: #000 !important;
      box-shadow: none !important;
    }

    fieldset {
      display: none;
    }
  }
}

.checkBoxSmall {
  padding: 0 5px 3px 0 !important;

  svg {
    color: #5062F0;
  }
}

.dateSmall {
  width: 100%;

  .MuiInputBase-root {
    border-radius: 0;
  }

  input {
    padding-left: 0;
  }

  .MuiInputBase-adornedEnd {
    border-radius: 0 !important;
    padding: 5px 0 7px 0px !important;

    input {
      padding: 5px 0 0 0;
    }

    svg {
      width: 20px;
    }
  }

  .MuiInputAdornment-positionEnd {
    button {
      padding: 0;
    }
  }

  &.noBorder {
    fieldset {
      border: none;
    }
  }

  .MuiFormHelperText-root {
    display: none;
  }
}

.MuiCheckbox-root {
  svg {
    color: #5062F0;
  }
}

.MuiMenu-list {
  li {
    &.MuiListItem-root {
      font-size: 12px;
      font-family: "Lato";
    }
  }
}

/* DatePicker */
.MuiPopover-root {
  &.initialDate {
    .MuiPopover-paper {
      top: 180px !important;
      left: 138px !important;
    }
  }

  &.finalDate {
    .MuiPopover-paper {
      top: 180px !important;
      left: 283px !important;
    }
  }
}

.borderLeftGray {
  border-left: solid 1px #BFC0CB;
}