@import url('https://fonts.googleapis.com/css?family=Lato&display=swap');

body {
  background: #E5E5E5;
  color: #222222;
  margin: 0;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
