.technicalSheetRegisterContainer {
  .mainContainers {
    flex: 1;

    // &:nth-of-type(2) {
    //   flex: 4;
    //   margin-left: 10px;
    // }
  }

  .productRightCard {
    width: 100%;

    .cropDataMediumBox {
      height: 135px;
    }

    .defaultButton {
      border-radius: 10px !important;
      padding: 2px 5px !important;
      height: 20px;
      margin-top: 5px;
    }
  }
}

/* ASIDE / TECHNICALSHEET */
.sheetSearchContent  {
  table {
    width: calc(100% + 32px) !important;
    margin-left: -16px;
    margin-bottom: -15px;
    border-bottom-left-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
    border-top-right-radius: 0 !important;
    border-top-left-radius: 0 !important;
    overflow: hidden;

    .headerSubtitle {
      th {
        top: 0 !important;
        background: #313347 !important;
        color: #FFF !important;
      }
    }

    .headerBorder {
      .titleLineBorder {
        top: 29px !important;

        hr {
          border: none;
        }
      }
    }

    tr {
      td {
        padding-left: 12px;

        &:last-of-type {
          padding-left: 0;
          padding-right: 12px;
        }

        span {
          background: #5062F0;
          padding: 5px;
          border-radius: 50px;
          color: #FFF;
          width: 20px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 5px;
        }
      }
    }
  }

  button {
    span {
      background: transparent !important;
    }
  }
}

.technicalSheetModal {
  .itensTable {
    table {
      &.MuiTable-root {
        tr {
          td,
          th {
            &:last-of-type {
              text-align: right;
              padding-right: 0;
            }
          }
        }
      }
    }
  }
}

/* Main Container */
.cardsMainContainer {
  position: relative;

  .MuiGrid-spacing-xs-5 {
    margin: 0;

    .MuiGrid-root {
      &:first-of-type {
        padding-left: 0;
      }

      &:last-of-type {
        padding-right: 0;
      }
    }
  }

  .MuiInputBase-root,
  .ant-select {
    background: #FFF;
  }

  .ant-select-selection-placeholder {
    margin-top: 5px;
  }

  .MuiFormLabel-root {
    transform: translate(0px, -20px) scale(1) !important;
  }
  
  .selectSmallBox {
    label {
      top: -20px
    }

    .ant-select-selection-item {
      margin-top: 3px;
    }
  }

  .productionPercentAcceptedField {
    .MuiInputBase-root {
      width: 80%;
    }
  }

  .productionScheduleDaysContainer {
    background: #FFF;
  }
  
  .productionScheduleDaysTagsBox {
    display: flex;
    align-items: center;
    justify-content: center;

    .productionScheduleDay {
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 4px;
      width: 20px;
      height: 20px;
      margin-right: 2px;
      cursor: pointer;
  
      span {
        color: #454A67;
        font-size: 12px;
      }
  
      &.active {
        background: #7583F0;
        box-shadow: 0px 4px 4px 0px #00000040;
  
        span {
          color: #FFF;
        }
      }
  
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
  
  .actionButtons {
    position: absolute;
    bottom: -56px;
    right: 0px;
    width: auto;
  }
}