/* Mobile */
@media screen and (min-device-width: 320px) and (max-device-width: 1024px), screen and (max-device-width: 1366px) and (min-device-height: 1024px) {
  header {
    z-index: 9999 !important;

    .MuiToolbar-regular {
      justify-content: flex-start !important;

      .MuiIconButton-edgeStart {
        margin: 0;
        display: block !important;

        svg {
          color: #FFF;
          height: 30px;
          width: 30px;
          margin-right: 3px;
        }
      }
    }

    .header-logo-box {
      a {
        display: flex;
        align-items: center;
      }
    }

    .headerRight {
      position: absolute;
      right: 0;
      padding-left: 20px;
      border-left: solid 1px #FFF;
      height: 100%;

      .headerInfosBox {
        display: none;
      }
    }
  }

  .restaurantHeaderSelect {
    .MuiMenu-paper {
      left: 218px !important;
      width: 50%;
    }
  }
}

/* Tablet */
@media only screen and (min-device-width: 768px) and (max-device-width: 1366px) {
  header {
    .MuiToolbar-regular {
      .MuiIconButton-edgeStart {
        svg {
          height: 40px;
          width: 40px;
        }
      }
    }

    .header-logo-box {
      border-left: solid 1px #FFF;
    }
  }
}

/* Phones */
@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {
  header {
    .header-logo-box {
      background: none !important;
      border-radius: 0;
    }

    .header-restaurants-box {
      display: none;
    }
    
    section {
      &:nth-of-type(1) {
        margin: 0px;
        display: flex;
        width: calc(100% - 114px);
        justify-content: center;
      }
    }

    .headerRight {
      border-left: none;
      justify-content: flex-end;
      width: 30%;
      padding-right: 12px;

      .userProfileImageBox {
        padding-right: 0 !important;
        margin-left: 15px;
      }

      section {
        .userName {
          display: none !important;
        }
      }
    }
  }

  .header-restaurants-box {
    &.responsive {
      display: block;
      position: fixed;
      top: 65px;
      background: #080B30;
      padding: 12px 10px;
      margin: 0;
      width: 100%;
      z-index: 1050;
    }
  }

  .restaurantHeaderSelect {
    .MuiMenu-paper {
      left: 0px !important;
      top: 119px !important;
      width: 100%;
      max-width: 100%;
      border-top-right-radius: 0;
      border-top-left-radius: 0;
    }
  }
}