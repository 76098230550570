/* Home Info */
.homeInfosContainer {
  padding-top: 18px;
  background: #E5E5E5;
}

/* Home Card Modal */
.homeCardModal {
  padding: 0 !important;
  width: 35% !important;
  border-radius: 4px;
}

/* Home Resume */
.homeResume {
  display: flex;
  flex-direction: column;
  background: #E5E5E5;

  p,
  a,
  span { 
    font-family: 'Lato';
  }

  /* Cards Container */
  .cardsContainer {
    display: flex;
    justify-content: space-between;
    margin: 20px 20px 40px;

    &.hide {
      margin-bottom: 0;
    }

    /* General and Default Cards */
    .homeCard {
      width: 200px;
      margin-right: 10px;
      background: #FFFFFF;
      border-radius: 4px;
      padding: 15px 12px 10px 12px;
      height: 485px;
      position: relative;

      &:hover {
        background: linear-gradient(0deg, rgba(80, 98, 240, 0.1), rgba(80, 98, 240, 0.1)), #FFFFFF;
      }

      &:nth-of-type(2) {
        display: none;
      }

      &:last-of-type {
        margin-right: 0;
      }

      &.operationalExapanded {
        background: rgba(47, 133, 144, 0.1);
      }

      &.managementExapanded {
        background: rgba(119, 55, 149, 0.10);
      }

      .currentStep {
        background: #5062F0;
        padding: 4px 12px;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
        position: absolute;
        top: -14px;
        left: 0;
        width: 100%;

        p {
          margin-bottom: 0;
          color: #FFF;
          font-size: 12px;
        }
      }

      .cardTitle {
        p,
        a {
          color: #5062F0;
          font-weight: bold;
          font-size: 16px;
          text-transform: uppercase;
        }

        p {
          margin-bottom: 5px;
          text-transform: uppercase;
        }

        span {
          color: rgba(80, 98, 240, 0.75);
          font-size: 12px;
          margin-bottom: 0;
          display: block;
        }
      }

      .missionsContainer,
      .indicatorsContainer {
        &.expanded {
          height: calc(100% - 57px);
          overflow: auto;

          .itemDefault {
            height: auto !important;
          }
        }

        .missionsBox,
        .indicatorsBox {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          border-radius: 4px;
          margin-bottom: 10px;
          padding: 10px;
          
          p {
            color: #FFF;
            margin-bottom: 10px;
            text-align: center;
            font-size: 12px;
            font-weight: bold;
            width: 100%;
          }

          a {
            color: #FFF;
            display: flex;
            font-size: 12px;
            align-items: center;
            margin-bottom: 5px;

            &:last-of-type {
              margin-bottom: 0;
            }

            span {
              background: #FFF;
              padding: 5px;
              border-radius: 50px;
              margin-right: 5px;
              height: 15px;
              width: 15px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 10px;
              line-height: .1;
            }
          }
        }

        .missionsBox {
          background: #2F8590;

          .itemDefault {
            &:hover {
              background: rgba(47, 133, 144, 0.1) !important;
            }
          }

          a {
            span {
              color: #2F8590;
            }
          }
        }

        .indicatorsBox {
          background: #773795;

          .itemDefault {
            &:hover {
              background: rgba(119, 55, 149, 0.1) !important;
            }
          }

          a {
            span {
              color: #773795;
            }
          }
        }
      }

      .defaultInfos {
        display: flex;
        justify-content: space-between;

        .defaultInfoBox {
          display: flex;

          .infoIconBox,
          .infoValue {
            display: flex;
            align-items: center;
          }

          .infoIconBox {
            background: #5062F0;
            border-radius: 20px 0px 0px 20px;
            padding: 5px 8px;

            img {
              height: 10px;
            }
          }

          .infoValue {
            background: rgba(80, 98, 240, 0.75);
            border-radius: 0px 20px 20px 0px;
            padding: 4px 18px 4px 4px;

            p {
              color: #FFF;
              margin-bottom: 0;
              font-size: 12px;
            }
          }

          &:last-of-type {
            margin-left: 5px;
            
            .infoIconBox {
              img {
                height: 15px;
              }
            }
          }
        }
      }

      .missionsContainer {
        margin-top: 10px;
        min-height: 173px;

        &.expanded {
          padding-right: 5px;

          &::-webkit-scrollbar-track {
            background-color: rgba(47, 133, 144, 0.25);
            background: rgba(47, 133, 144, 0.25) !important;
          }
          
          &::-webkit-scrollbar {
            width: 4px;
            height: 4px;
            background-color: rgba(47, 133, 144, 0.25);
            background: rgba(47, 133, 144, 0.25) !important;
          }
          
          &::-webkit-scrollbar-thumb {
            background-color: #2F8590 !important;
            background: #2F8590 !important;
          }
        }
      }

      .indicatorsContainer {
        margin-top: 22px;
        min-height: 183px;

        &.expanded {
          padding-right: 5px;

          &::-webkit-scrollbar-track {
            background-color: rgba(119, 55, 149, 0.25);
            background: rgba(119, 55, 149, 0.25) !important;
          }
          
          &::-webkit-scrollbar {
            width: 4px;
            height: 4px;
            background-color: rgba(119, 55, 149, 0.25);
            background: rgba(119, 55, 149, 0.25) !important;
          }
          
          &::-webkit-scrollbar-thumb {
            background-color: #773795 !important;
            background: #773795 !important;
          }
        }
      }

      &.generalCard {
        .cardTitle {
          a {
            span {
              margin: 7px 0 12px;
              text-transform: initial;
            }
          }
        }

        .indicatorsContainer {
          margin-top: 28px;
        }
      }

      /* Default Item Card */
      &.cardItem {
        .cardTitle {
          a {
            margin-bottom: 0;
            width: 100%;
            display: flex;
            flex-direction: column;
            text-transform: uppercase;

            &.blackTitle {
              color: #313347;
            }
          }

          img {
            color: #313347;
            height: 15px;
            cursor: pointer;
          }

          span {
            margin-bottom: 8px;
            font-size: 12px;
            text-transform: initial;
          }
        }

        .missionsContainer,
        .indicatorsContainer {
          .missionsBox,
          .indicatorsBox {
            background: transparent;
            padding: 0;

            .missionTitleBox,
            .indicatorTitleBox {
              width: 100%;
              border-radius: 4px;
              padding: 5px 0;
              margin-bottom: 8px;

              p {
                margin-bottom: 0;
              }
            }

            .itemListContainer {
              height: auto;
              overflow: hidden;
              width: 100%;

              .itemDefault {
                display: flex;
                margin-bottom: 2px;
                padding: 2px 0;
                border-radius: 4px;
                cursor: pointer;
                height: 55px;
                overflow: hidden;

                &:hover {
                  background: rgba(47, 133, 144, 0.1);
                }

                p,
                span {
                  font-size: 12px;
                }

                p {
                  text-align: left;
                  margin-bottom: 0;
                  color: #000000;
                  font-weight: normal;
                }

                span {
                  color: #FFF;
                  padding: 5px;
                  border-radius: 50px;
                  margin-right: 5px;
                  height: 15px;
                  width: 15px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-size: 10px;
                  line-height: .1;
                  margin-top: 3px;
                }

                &.empty {
                  height: 112px;

                  p {
                    text-align: center;
                    width: 100%;
                  }
                }

                &.singleItem {
                  height: 112px;
                }
              }
            }

            .itemsFullList {
              margin-top: 8px;
              border-radius: 50px;
              width: auto;
              padding: 1px 8px;
              cursor: pointer;
              
              p {
                margin-bottom: 0;
                font-size: 10px;
                text-transform: uppercase;
                color: #FFF;
              }
            }
          }

          .missionsBox {
            .missionTitleBox {
              background: #2F8590;
            }

            .itemListContainer {
              .itemDefault {
                span {
                  background: #2F8590;
                }
              }
            }

            .itemsFullList {
              background: #2F8590;
            }
          }

          .indicatorsBox {
            .indicatorTitleBox {
              background: #773795;
            }

            .itemListContainer {
              .itemDefault {
                span {
                  background: #773795;
                }
              }
            }

            .itemsFullList {
              background: #773795;
            }
          }
        }
      }
    }
  }

  /* Footer Hint */
  .footerHint {
    margin: 10px 0 -15px 0;
    background: #7C89F4;
    display: flex;
    justify-content: center;
    padding: 10px 0;
    position: fixed;
    bottom: 15px;
    width: calc(100% - 50px);

    p {
      color: #FFF;
      margin-bottom: 0;
      font-size: 16px;
    }

    svg {
      position: absolute;
      right: 20px;
      color: #FFF;
      cursor: pointer;
    }

    &.hide {
      display: none;
    }
  }
}
