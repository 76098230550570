@import "../common/variables.scss";

.cardDefault {
  background: #FFF;
  box-shadow: 0px 0px 8px rgba(4, 6, 26, 0.25);
  border-radius: 4px;
  padding: 30px 16px 16px;

  &.withoutInputs {
    padding-top: 16px;
  }

  label {
    font-size: 12px;
    font-family: "Lato";
    font-weight: 700;
    margin-bottom: 0;

    &.blue {
      color: #5062F0;
    }
  }

  /* Product Right Card */
  &.productRightCard {
    padding: 12px !important;
    .MuiGrid-container {
      &:last-of-type {
        .MuiGrid-item {
          border-bottom: none;
        }
      }
    }

    &.spacingCard {
      padding: 3px 12px !important;
    }

    .MuiGrid-item {
      border-left: solid 1px #ECEDF1;
      border-bottom: solid 1px #ECEDF1;
      padding: 5px;
      display: flex;
      align-items: center;

      label {
        margin-bottom: 0;
      }

      &:first-of-type {
        border-left: none;

        label {
          padding-left: 0;
        }
      }
    }
  }

  .item {
    display: flex;
    align-items: center;

    svg {
      color: #5062F0
    }

    label,
    span {
      color: #313347;
      font-size: 12px;
      font-family: "Lato";
    }

    label {
      margin-bottom: 0;

      .MuiButtonBase-root {
        padding: 0 5px;
      }
    }
  }

  &.widthImageBox {
    width: 100%;
  }
}

.cardProductsImagePopover {
  .MuiPaper-root {
    width: 14% !important;
    padding: 8px !important;
    left: 571px !important;
    top: 500px !important;
  }

  .cropDataMediumBox {
    height: 100px;
  }

  button {
    font-weight: 700;
    padding: 2px 10px !important;
    height: 25px;
  }
}

.cardBlueTitleDefault {
  display: flex;
  flex-direction: column;

  .cardHeader {
    display: flex;
    align-items: center;
    background: #313347;
    padding: 6px 12px;

    p {
      color: #FFF;
      font-family: "Lato";
      font-size: 12px;
      font-weight: bold;
      margin-bottom: 0;
    }
  }
}

.cardsMainTable {
  width: calc(100vw - 65px);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px !important;
    margin-left: 5px !important;
  .headerSubtitle {
    .itemColumn {
      width: 4.3%;
    }

    .totalColumn {
      width: 4.7%;
    }

    .cmvColumn {
      width: 4.5%;
    }

    .actualColumn {
      width: 6%;
    }

    .durationColumn {
      width: 7%;
    }

    .priceColumn {
      width: 6%;
    }

    .marginColumn {
      width: 6%;
    }

    .curveColumn {
      width: 2%;
    }

    .cardsLengthColumn {
      width: 3.7%;
    }

    .deleteColumn {
      width: 2%;
    }

    @media only screen and (min-width: 1030px) and (max-width: 1400px) {
      .itemColumn {
        width: 5%;
      }

      .totalColumn {
        width: 5%;
      }

      .cmvColumn {
        width: 5%;
      }
      
      .durationColumn {
        width: 6%;
      }
    }

    @media only screen and (min-width: 1370px) and (max-width: 1605px) {
      .itemColumn {
        width: 6%;
      }

      .totalColumn {
        width: 6%;
      }

      .cmvColumn {
        width: 6%;
      }

      .actualColumn {
        width: 7.155%;
      }

      .durationColumn {
        width: 6.45%;
      }
    }
  }

  .listTableContainer {
    .descriptionSku {
    }

    .listItems {
      width: 28.6%;
    }

    .listItemsCollapse {
      width: 30.1%;
    }

    .listQuantity {
      width: 10%;
    }

    .listQuantityCollapse {
      width: 9.7%;
    }

    .listConsumptionUnit {
      width: 5%;
    }

    .listType {
      width: 11.150%;
    }

    .listTypeCollapse {
      width: 10.9%;
    }

    .listItem {
      width: 7.7%;
    }

    .listItemCollapse {
      width: 7.5%;
    }
    
    .listTotal {
      width: 7.7%;
    }

    .listTotalCollapse {
      width: 7.5%;
    }

    .listCmv {
      width: 7.7%;
    }

    .listCmvCollapse {
      width: 7.55%;
    }

    @media only screen and (min-width: 1030px) and (max-width: 1400px) {
      .listItems {
        width: 23.7%;
      }

      .listItemsCollapse {
        width: 26.7%;
      }
  
      .listQuantity {
        width: 8%;
      }

      .listQuantityCollapse {
        width: 8%;
      }
  
      .listType {
        width: 10%;
      }

      .listTypeCollapse {
        width: 10%;
      }
  
      .listItem {
        width: 8.4%;
      }

      .listItemCollapse {
        width: 8.4%;
      }
      
      .listTotal {
        width: 8.4%;
      }

      .listTotalCollapse {
        width: 8.4%;
      }
  
      .listCmv {
        width: 8.3%;
      }

      .listCmvCollapse {
        width: 8.3%;
      }

      .listActual {
        width: 10%;
      }

      .listDuration {
        width: 10%;
      }
    }

    @media only screen and (min-width: 1370px) and (max-width: 1499px) {
      .listItems {
        width: 23.4%;
      }
      
      .listItemsCollapse {
        width: 26.1%;
      }
  
      .listQuantity {
        width: 7.8%;
      }

      .listQuantityCollapse {
        width: 7.8%;
      }
  
      .listType {
        width: 9.4%;
      }

      .listTypeCollapse {
        width: 9.4%;
      }
  
      .listItem {
        width: 8.7%;
      }

      .listItemCollapse {
        width: 8.7%;
      }
      
      .listTotal {
        width: 8.7%;
      }

      .listTotalCollapse {
        width: 8.7%;
      }
  
      .listCmv {
        width: 8.9%;
      }

      .listCmvCollapse {
        width: 8.9%;
      }

      .listActual {
        width: 10%;
      }

      .listDuration {
        width: 10%;
      }
    }

    @media only screen and (min-width: 1500px) and (max-width: 1605px) {
      .listItems {
        width: 23.7%;
      }
      
      .listItemsCollapse {
        width: 26.2%;
      }
  
      .listQuantity {
        width: 7.85%;
      }

      .listQuantityCollapse {
        width: 7.85%;
      }
  
      .listType {
        width: 9.4%;
      }

      .listTypeCollapse {
        width: 9.5%;
      }
  
      .listItem {
        width: 8.7%;
      }

      .listItemCollapse {
        width: 8.7%;
      }
      
      .listTotal {
        width: 8.7%;
      }

      .listTotalCollapse {
        width: 8.7%;
      }
  
      .listCmv {
        width: 9%;
      }

      .listCmvCollapse {
        width: 9%;
      }

      .listActual {
        width: 10%;
      }

      .listDuration {
        width: 10%;
      }
    }

    @media only screen and (min-width: 1606px) and (max-width: 2000px) {
      .listItems {
        width: 25.8%;
      }

      .listItemsCollapse {
        width: 27.7%;
      }
  
      .listQuantity {
        width: 8.4%;
      }

      .listQuantityCollapse {
        width: 8.4%;
      }
  
      .listType {
        width: 6%;
      }

      .listTypeCollapse {
        width: 6%;
      }
  
      .listItem {
        width: 6.9%;
      }

      .listItemCollapse {
        width: 6.9%;
      }
      
      .listTotal {
        width: 6.9%;
      }

      .listTotalCollapse {
        width: 6.9%;
      }
  
      .listCmv {
        width: 7%;
      }

      .listCmvCollapse {
        width: 7%;
      }

      .listActual {
        width: 10%;
      }

      .listDuration {
        width: 10%;
      }
    }

    @media only screen and (min-width: 1606px) {
      .listTableContainer .listQuantity {
        width: 8.7%;
      }
    }
  }

  .menuType {
    background-color: #DADDF3;
    border-radius: 12px;
    width: 57px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 8px;
    font-weight: 700;
    box-shadow: 0px 4px 4px 0px #00000040;
  }

  .recipeType {
    background-color: #D9EAF2;
    border-radius: 12px;
    width: 57px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 8px;
    font-weight: 700;
    box-shadow: 0px 4px 4px 0px #00000040;
  }

  .transfType {
    background-color: #FFF2CC;
    border-radius: 12px;
    width: 57px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 8px;
    font-weight: 700;
    box-shadow: 0px 4px 4px 0px #00000040;
  }
}

.unifyCardsDialog {
  p,
  label,
  span {
    font-size: 14px;
  }

  td {
    color: $lightBlue;
  }

  .Mui-checked {
    svg {
      color: $lightBlue;
    }
  }

  label {
    margin: 0 10px 0 0 !important;

    .MuiRadio-root {
      padding: 5px 5px 5px 0;
    }

    .MuiTypography-root {
      font-weight: bold;
    }
  }

  .optionsList {
    max-height: 200px;
    overflow: scroll;
  }
}

/* Cards View Dialog */
.cardViewDialog {
  .materialTableDefault  {
    thead {
      th {
        padding: 3px 12px !important;
      }
    }
  }

  .MuiGrid-root {
    .childTextAlignRight {
      label {
        width: 100%;
        text-align: right;
      }

      input {
        padding-right: 0;
      }
    }
  }
}