.miseEnPlacePortionCardDialog {
  .MuiDialog-paper {
    overflow: visible;
    border-radius: 8px 8px 18px 18px;
    max-width: 470px !important;
    width: 470px !important;
    height: 96%;
    max-height: 650px;
    margin: 15px;
  }

  .MuiDialog-scrollPaper {
    align-items: flex-start;
    margin-top: 200px;
  }

  .modalHeader {
    border-radius: 8px 8px 0 0;

    .quantityAlertIconBox {
      background: #FFF;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 25px;
      height: 25px;
      padding: 10px;
      margin-left: 10px;
    }
    
    h2 {
      display: flex;
      flex-direction: column;
      height: auto;
      color: #1F2445;

      p,
      span {
        font-size: 16px;
        line-height: 1.4;
        color: #1F2445;
      }
    }
  }

  .modalContent {
    box-shadow: 3px 3px 6px 0px #000000BF;
    border: solid 4px #5062F0;
    border-radius: 0 0 18px 18px;
    padding: 0;
  }

  .miseEnPlaceCard {
    box-shadow: none;
    border: none;
    border-radius: 0;
    width: 100%;
    height: 180px;
    margin: 0;
  
    .leftInfosBox {
      padding: 5px 7px;
      width: 34%;
  
      .imageBox {
        width: auto;
        height: 115px;

        .cardOrderNumber {
          display: flex;
          align-items: center;
          justify-content: center;
          box-shadow: 4px 4px 4px 0px #000000BF;
          position: absolute;
          background: #5062F0;
          border-radius: 4px;
          top: 0;
          left: 0px;
          width: 14%;
      
          span {
            color: #FFF;
            font-size: 12px;
          }
        }

        &.imageFakeBg {
          background: #DADDF3;
        }

        img {
          width: 100%;
          height: 100%;
          max-width: 100%;
          max-height: 100%;
          object-fit: cover;
        }
  
        &.hasNotImage {
          img {
            width: auto;
            height: 100%;
            max-width: 100%;
            max-height: 100%;
          }
        }
      }
  
      p {
        line-height: 1.4;
      }
    }
  
    .rightInfosBox {
      padding: 5px 7px;
      width: 66%;

      .fakeBorderCover {
        width: 100%;
        border-radius: 2px 2px 6px 6px;
        background: #1F2445;
        margin-bottom: -5px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 22px;
  
        .productionCardInfo {
          display: flex;
          align-items: center;
          border-radius: 4px 4px 0 0;
          padding: 3px 19px 3px 0;
   
          span {
            color: #F2B90D;
            font-size: 12px;   
            font-weight: bold; 
            position: relative;
          }
      
          img {
            margin-right: 7px;
            width: 13px;
            z-index: 9;
          }
        }
      }
  
      .progressBox {
        border: 2px solid #1F2445;
        border-radius: 0 0 12px 12px;
        padding: 8px 10px 0 10px;
        height: 210px;
        width: 83%;
        position: relative;
      }
    }
  }

  .replacementPositionDivider {
    background: #5062F0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 9px;

    span {
      color: #FFF;
      font-size: 12px;
    }
  }

  .miseEnPlaceValueBox {
    margin: 10px;
    padding: 10px;
    background: #1F2445;
    border-radius: 0 8px 8px 0;
    display: flex;
    align-items: center;

    .buttonPortionType {
      background: #FFF;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      border-radius: 4px;
      padding: 10px 20px;
      width: 50%;

      span {
        color: #5062F0;
      }
    }

    .subtractValueIcon {
      margin-right: 5px;
    }

    .addValueIcon {
      margin-left: 5px;
    }

    img {
      width: 40px;
      height: 40px;
    }

    .MuiFormControl-root,
    input {
      background: #FFF;
      height: 40px;
      padding: 0;
    }

    
    .MuiFormControl-root {
      width: 40%;
    }

    input {
      width: 100%;
      padding-right: 10px;
      font-size: 16px;
      font-family: 'Lato';
      color: #010311;
    }
  }

  .portionsInfos {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 0 10px;

    span {
      color: #454A67;
      text-transform: uppercase;
      font-size: 12px;

      &:first-of-type {
        margin-bottom: 5px;
      }
    }
  }

  .actionButtons {
    display: flex;
    justify-content: flex-end;
    padding: 10px 10px 0 10px;

    button {
      border-radius: 8px;
      font-size: 12px !important;

      &:first-of-type {
        margin-right: 30px;
      }
    }
  }

  .consumePreviewTable {
    overflow: auto;
    margin-top: 10px;
    max-height: 200px;

    table {
      box-shadow: none;
  
      td,
      span {
        font-size: 12px;
        color: #9296AC;
      }
  
      td {
        text-align: right;
        padding-left: 0 !important;
        background: #FFF;
      }
  
      tr {
        &:first-of-type {
          td,
          span {
            color: #454A67;
          }
        }
      }
    }
  }

  /* POPOVER */
  .portionInputTypeControlPopover {
    .MuiPopover-paper {
      top: auto !important;
      left: 14px !important;
      bottom: -83px;
      width: 100%;
      max-width: calc(100% - 28px);
    } 

    .descriptionBox {
      background: rgba(80, 98, 240, .2);
      border-bottom: solid 1px #454A67;
      width: 100%;
      padding: 10px;
      box-shadow: 0px 4px 4px 0px #00000040;
      
      td {
        color: #1F2445
      }
    }

    span {
      font-size: 12px;

      td {
        font-size: 16px !important;
      }
    }

    .actionAndInfosBox {
      padding: 10px;
      display: flex;
      width: 100%;
    }

    .popoverCardInfo {
      margin: 11px 0 26px 10px;
    }

    .buttonOptions {
      display: flex;
      flex-direction: column;
      width: 48%;

      button {
        width: 100%;
        margin-bottom: 15px;
        box-shadow: 0px 4px 4px 0px #00000040;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
}

@media only screen and (max-width: 820px) {
  .miseEnPlacePortionCardDialog {
    width: 94%;

    /* POPOVER */
    .portionInputTypeControlPopover {
      .MuiPopover-paper {
        top: auto !important;
        left: 14px !important;
        bottom: 45px;
        width: 100%;
        max-width: calc(100% - 28px);
      } 
    }
  }
}

/* Tablet Horizontal */
@media screen and (min-width: 600px) and (max-width: 1366px) and (orientation: landscape) {
  .miseEnPlacePortionCardDialog {
    .MuiDialog-scrollPaper {
      margin-top: 0px;
    }
  }

  .portionInputTypeControlPopover {
    .MuiPopover-paper {
      bottom: 7px !important;
    } 
  }
}