@import "../common/variables.scss";

.disabledCheckbox {
  svg {
    color: #818182
  }
}

.unifyProductsPopoverDialog {
  p,
  label,
  span {
    font-size: 14px;
  }

  td {
    color: $lightBlue;
  }

  .Mui-checked {
    svg {
      color: $lightBlue;
    }
  }

  label {
    margin: 0 10px 0 0 !important;

    .MuiRadio-root {
      padding: 5px 5px 5px 0;
    }

    .MuiTypography-root {
      font-weight: bold;
    }
  }

  .optionsList {
    max-height: 200px;
    overflow: scroll;
  }
}
