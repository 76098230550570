@import './variables';

header {
  .header-logo-box {
    border: none !important;
  }
  
  .MuiIconButton-edgeStart {
    display: none !important;
  }
}

.header-restaurants-box {
  &.responsive {
    display: none;
  }
}

.restaurantHeaderSelect {
  .MuiMenu-paper {
    top: 66px !important;
    left: 148px !important;
    width: 23%;

    li {
      width: 100% !important;
    }
  }
  
  .MuiMenu-list {
    padding: 0;

    li {
      font-family: 'Lato';
      overflow: hidden;
      font-size: 1rem;
      box-sizing: border-box;
      min-height: auto;
      font-weight: 400;
      line-height: 1.5;
      padding-top: 6px;
      white-space: nowrap;
      letter-spacing: 0.00938em;
      padding-bottom: 6px;
    }
  }

  .groupHeader,
  .subGroupHeader {
    color: $white;
  }

  .groupHeader {
    background: $navyBlue;
  }

  .subGroupHeader {
    background: #5062F0;
    padding-left: 32px;
  }

  .storeHeader {
    padding-left: 48px;
  }
}

@media only screen and (min-width: 320px) {
  .list-companies-text span, .groupHeader, .subGroupHeader {
      font-size: 16px !important;
  }
}

@media only screen and (min-width: 768px) {
  .list-companies-text span, .groupHeader, .subGroupHeader {
      font-size: 18px !important;
  }
}


@media only screen and (min-width: 1025px) {
  .list-companies-text span, .groupHeader, .subGroupHeader {
      font-size: 20px !important;
  }
}