h1, h2, h3, h4, h5, p, span {
    font-family: 'Lato', sans-serif !important;
    font-style: normal !important;
    font-feature-settings: 'tnum' on, 'lnum' on, 'liga' off, 'calt' off;
    text-transform: initial;
}

.chartCard {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 497px;
    height: 100%;
    padding: 10px;
    background: #FFFFFF;
    box-shadow: 0px 1px 4px rgba(1, 3, 17, 0.45);
    border-radius: 12px;
}

.title {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 16px !important;
    line-height: 20px;
    margin-top: 0px;
}

.descriptionSalesPurchase {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: auto;
}

.descriptionSales, .descriptionPurchases {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.descriptionTitle {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    margin-left: 8px;
}