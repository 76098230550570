/* Table List */
.skusPage {
  .fakeFilterRight {
    position: absolute;
    display: flex;
    align-items: center;
    top: 140px;
    right: 20px;
    width: 20%;

    .fakeLabel {
      font-size: 12px;
      width: 70%;
    }

    span {
      font-size: 12px;
    }

    .MuiFormControl-root {
      width: 60%;
      margin-bottom: 3px;

      input {
        padding-right: 13px !important;
      }
    }

    .variationAfterSymbol {
      font-weight: bold;
      margin-left: -10px;
      margin-bottom: -2px;
    }

    .variationFilterButton {
      border: 0.5px solid #AA0816;
      transition: all 400ms;
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 2px 5px;
      width: 50%;
      margin-left: 10px;
      cursor: pointer;
      box-shadow: 0px 4px 4px 0px #00000080;
      
      span {
        color: #AA0816;
        font-weight: bold;
      }

      &.active {
        transition: all 400ms;
        background: #AA0816;
        
        span {
          color: #FFF;
        }
      }

      &.disabledButton {
        transition: all 400ms;
        background: rgba(210, 211, 215, 1);
        border: solid 1px #b8bbcc;
        cursor: default;

        span {
          color: rgba(102, 103, 112, 1);
        }
      }
    }
  }

  .filterClosed {
    &.fakeFilterRight {
      display: none;
    }
  }

  .filterOpened {
    .skuWithoutProduct {
      margin: -10px 0 0 -30px;
    }
  }
}