/* Manual Filter */ 
.filterClosed {
  max-height: 0;
  transition: all 0.3s;
  margin-bottom: 0;
  overflow: hidden;
}

.filterOpened {
  max-height: 500px;
  transition: all 0.3s;
  margin-bottom: 16px;
}

.noMarginFilter {
  .filterOpened {
    margin-bottom: 0;
  }
}

/* Multiple Autocomplete Filter */
.multipleAutocompleteContainer {
  .autocompleteSmall {
    .MuiAutocomplete-inputRoot {
      overflow: hidden;
    }

    .Mui-focused {
      width: 100% !important;
      overflow-y: scroll;
      display: block !important;
      padding-right: 0 !important;

      .MuiAutocomplete-tag {
        float: left;
        margin-top: 2px !important
      }
    }
  }
}