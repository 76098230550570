.skuPreviewDialog {
  width: 100%;
  max-width: 100%;
  overflow: hidden;

  .MuiDialog-container {
    width: 490px;
  }

  .modalHeader {
    padding: 8px !important;

    .MuiIconButton-root {
      right: 5px !important;
    }
  }

  .boxTitle {
    background: #F2F2F4;
    padding: 4px 8px;
    font-size: 10px;
    font-weight: bold;
  }

  .defaultSkuInfoBox {
    position: relative;
    background: #FFF;

    .boxItem {
      padding: 0 8px 9px;
      align-items: center;

      &:first-of-type {
        padding-top: 9px;
      }

      .itemLabel,
      .itemContent {
        font-size: 10px;
        align-items: center;
        
        span {
          display: flex;
          align-items: center;
        }
      }

      .itemLabel {
        width: 20%;
        color: #9296AC;
        margin-right: 10px;
        text-align: right;
      }

      .itemContent {
        &.withBg {
          background: #DADDF3;
          color: #0F1225;
          padding: 2px 5px;
          font-weight: bold;
          width: 20%;
          text-align: right;
        }

        .textFieldSmall {
          width: 100%;

          input {
            text-align: right !important;
            font-size: 10px;

            &::placeholder,
            &::-webkit-input-placeholder {
              font-style: italic;
              color: #77798B;
              opacity: .5 !important;
              padding-right: 5px;
            }
          }
        }
      }
    }
    
    .skuImgBox {
      width: 150px;
      height: 150px;
      position: absolute;
      right: 40px;
      bottom: 1vh;
      background: transparent;
    }
  }

  .modalContent {
    .buttonActions {
      background: #FFF;
      padding: 9px;
    }
  }
}