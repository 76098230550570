.receivementsTable {  
  display: block;

  @media screen and (max-device-width: 480px) {
    display: none !important;
  }
  .nfInput {
    input {
      margin-bottom: 0;
      padding-top: 12px;
    }
  }

  tr {
    th {
      font-size: 11px !important;

      &.orderWithoutMargin {
        span {
          margin-right: 0px !important;
        }
      }

      span {
        margin: 4px !important;
      }
    }
  }

  td {
    .MuiInputAdornment-root {
      display: none;
    }

    input {
      padding: 7px 3px 4px 0;
      background: #FFF;
    }
  }
}

/* Costs Dialog */
.costItemsDialog {
  .MuiPaper-root {
    max-width: 1440px;
  }
}

.alertCertificate {
  width: 100%;
  height: 26px;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 10px;
}

/* Qr Code Reader Dialog */
.qrCodeReaderNotesDialog {
  // #html5-qrcode-button-camera-start,
  // #html5-qrcode-button-camera-stop {
  //   display: none !important;
  // }

  .qr-scanner {
    img {
      &:first-of-type {
        display: none;
      }
    }
  }

  #reader__scan_region {
    img {
      display: block;
      margin: auto;
    }
  }

  .qr-scanner,
  #reader {
    border: none !important;
  }

  #reader__dashboard_section {
    padding: 5px 0 !important;
  }
}