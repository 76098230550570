/* Mobile */
@media screen and (min-device-width: 320px) and (max-device-width: 1024px), screen and (max-device-width: 1366px) and (min-device-height: 1024px) {
  .MuiPopover-paper {
    top: 35%;
    left: 25%;
  }
}

/* Phones */
@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {
  .MuiPopover-paper {
    left: 5% !important;
    max-width: 90% !important;
  }
}
