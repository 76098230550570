@import './variables';

.modalHeaderNewLayout {
  display: flex;
  justify-content: space-between;
  padding: 8px 12px !important;
  width: 660px;

  &.navyBlue {
    background: #1F2445;
  }

  &.darkBlue {
    background: #00094C;
  }

  h2 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 21px;
    font-family: "Lato";

    button {
      top: -1px;
      right: -5px;
    }
  }

  p {
    font-family: "Lato";
    font-size: 14px;
    font-weight: bold;
    color: #FFF;
    text-transform: uppercase;
  }

  button {
    padding: 0;
    top: 5px;
    right: 5px;
  }

  img {
    width: 35px;
  }
}

/* Modal Header */
.modalHeader {
  display: flex;
  justify-content: space-between;
  background: #5062F0;
  padding: 12px 16px !important;

  .qr-scanner {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 20px;
  }
  
  .qr-scanner video {
    max-width: 100%;
    height: auto;
  }

  &.navyBlue {
    background: #313347;
  }

  &.transparent {
    background: transparent;
  }

  &.darkBlue {
    background: #00094C;
  }

  h2 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 21px;
    font-family: "Lato";

    button {
      top: -1px;
      right: -5px;
    }
  }

  p {
    font-family: "Lato";
    font-size: 14px;
    font-weight: bold;
    color: #FFF;
    text-transform: uppercase;
  }

  button {
    padding: 0;
    top: 5px;
    right: 5px;
  }

  img {
    width: 35px;
  }
}

/* Modal Header */
.modalHeaderChangeUserMobile {
  display: flex;
  justify-content: space-between;
  background: #5062F0;
  padding: 12px 16px !important;

  &.navyBlue {
    background: #313347;
  }

  &.transparent {
    background: transparent;
  }

  &.darkBlue {
    background: #00094C;
  }

  h2 {
    width: 100%;
    padding: 12px;
    height: 40px;
    font-family: "Lato";
    text-align: center;

    button {
      top: -1px;
      right: -5px;
    }
  }

  p {
    font-family: "Lato";
    font-size: 14px;
    font-weight: bold;
    color: #FFF;
    text-transform: uppercase;
  }

  button {
    padding: 0;
    top: 5px;
    right: 5px;
  }

  img {
    width: 35px;
  }
}

.modalHeaderSquares {
  display: flex;
  justify-content: space-between;
  background: #5062F0;
  padding: 9px 15px 7px 11px !important;

  &.navyBlue {
    background: #313347;
  }

  &.transparent {
    background: transparent;
  }

  &.darkBlue {
    background: #00094C;
  }

  h2 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 21px;
    font-family: "Lato";

    button {
      top: -1px;
      right: -5px;
    }
  }

  p {
    font-family: "Lato";
    font-size: 12px;
    font-weight: 700;
    color: #FFF;
    text-transform: uppercase;
  }

  button {
    padding: 0;
    top: 5px;
    right: 5px;
  }

  img {
    width: 35px;
  }
}


.modalHeaderProcess {
  display: flex;
  justify-content: space-between;
  background: #5062F0;
  padding: 9px 15px 7px 11px !important;

  &.navyBlue {
    background: #313347;
  }

  &.transparent {
    background: transparent;
  }

  &.darkBlue {
    background: #00094C;
  }

  h2 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 21px;
    font-family: "Lato";

    button {
      top: -1px;
      right: -5px;
    }
  }

  p {
    font-family: "Lato";
    font-size: 12px;
    font-weight: 700;
    color: #FFF;
    text-transform: uppercase;
  }

  button {
    padding: 0;
    top: 5px;
    right: 5px;
  }

  img {
    width: 35px;
  }
}

/* Default Modal */
.defaultModal {
  width: 80%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  &.defaultWidth85 {
    width: 85%;
  }

  &.fakeDialogTitle {
    .modalHeader {
      background: transparent;

      p {
        color: #0F1225;
        text-transform: initial;
      }
    }
  }

  &.fakePopover {
    .MuiBackdrop-root {
      background: transparent;
    }
  }

  &.miniModal {
    .MuiDialog-paperWidthSm {
      max-width: 311px;
    }
  }

  &.mobileModal {
    .MuiDialog-paper {
      min-width: 304px;
      width: auto;
    }
  }

  &.smallModal {
    .MuiDialog-paper {
      @media screen and (max-device-width: 458px) {
        min-width: 304px;
        width: auto;
      }

      min-width: 450px;
      width: auto;
    }
  }

  &.mediumModal {
    &.minWidth {
      .MuiDialog-paper {
        min-width: 500px;
      }
    }

    .MuiDialog-paper {
      max-width: 710px;
      width: auto;
    }
  }

  &.bigModal {
    .MuiDialog-paper {
      max-width: 850px;
      width: auto;
    }
  }

  &.bigModal-help {
    .MuiDialog-paper {
      max-width: 50%;
      width: auto;
    }
  }

  &.certificate-modal {
    .MuiDialog-paper {
      max-width: 385px;
      width: auto;
    }
  }

  &.largeModal {
    width: 95% !important;

    .MuiPaper-root {
      margin: 0;
    }
  }

  &.modal1085 {
    max-width: 1085px;
  }

  &.dialog100 {
    .MuiPaper-root {
      width: 100%;
      max-width: 100%;
    }
  }

  .MuiDialog-container {
    width: 100%;
  }

  .noBoxStyle {
    box-shadow: none !important;
    border: none !important;
  }

  .minHeight150px {
    min-height: 150px !important;
  }

  .modalContent {
    background: #F6F6F8;
    padding: 16px;
    width: 100%;
    height: auto;
    border: none;
    overflow-x: hidden;

    .lightBlue {
      td {
        color: #5062F0;
      }
    }

    &:focus {
      outline: none;
    }

    &.onlyTable {
      background: #FFF;
      padding: 0 0 8px 0;

      table {
        width: 100%;
        box-shadow: none;
        border: none;

        svg {
          color: #313347;
        }

        thead {
          th {
            background: #F6F6F8;
            color: #77798B;
            padding: 8px 16px;
            position: sticky;
            top: 0px;

            &:first-of-type {
              border-top-left-radius: 4px;
            }

            &:last-of-type {
              border-top-right-radius: 4px;
            }

            &.titleLineBorder {
              padding: 0;
            }
          }
        }

        tbody {

          td,
          tr {
            padding: 4px 16px;
          }
        }

        td,
        th {
          font-family: "Lato";

          &.right {
            text-align: right;
          }

          &.center {
            text-align: center;
          }
        }
      }
    }

    &.onlyTableProducts {
      background: #FFF;
      padding: 0 0 8px 0;

      table {
        width: 100%;
        box-shadow: rgba(4, 6, 26, 0.25) 0px 0px 8px;
        border: none;

        svg {
          color: #313347;
        }

        thead {
          th {
            background: #313347;
            color: #ffffff;
            padding: 8px 16px;
            position: sticky;
            top: 0px;

            &:first-of-type {
              border-top-left-radius: 4px;
            }

            &:last-of-type {
              border-top-right-radius: 4px;
            }

            &.titleLineBorder {
              padding: 0;
            }
          }
        }

        tbody {

          td,
          tr {
            padding: 4px 16px;
          }
        }

        td,
        th {
          font-family: "Lato";

          &.right {
            text-align: right;
          }

          &.center {
            text-align: center;
          }
        }
      }
    }

    .modalSubtitle {
      background: #F6F6F8;
      color: #77798B;
      margin-bottom: 0;
      padding: 4px 16px;
      left: -16px;
      position: relative;
      width: calc(100% + 32px);
      font-weight: bold;
    }

    .MuiContainer-root {
      padding: 0;
      margin: 0;
    }

    .title {
      margin-bottom: 20px;
    }

    table {
      border-radius: 4px;
      box-shadow: 0px 0px 8px rgba(4, 6, 26, 0.25);
      width: 100%;

      thead {
        th {
          background: #313347;
          color: #FFF;
          padding: 8px 12px;
          position: sticky;
          top: -20px;
          font-size: 12px;

          &:first-of-type {
            border-top-left-radius: 4px;
          }

          &:last-of-type {
            border-top-right-radius: 4px;
          }

          &.titleLineBorder {
            padding: 0;
          }
        }
      }

      tbody {
        td {
          padding: 4px 12px;
        }
      }

      td {
        &.alignRight {
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
}

/*Modal change user*/
.defaultModalChangeUser {
  width: 80%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  &.defaultWidth85 {
    width: 85%;
  }

  &.fakeDialogTitle {
    .modalHeader {
      background: transparent;

      p {
        color: #0F1225;
        text-transform: initial;
      }
    }
  }

  &.fakePopover {
    .MuiBackdrop-root {
      background: transparent;
    }
  }

  &.miniModal {
    .MuiDialog-paperWidthSm {
      max-width: 311px;
    }
  }

  &.mobileModal {
    .MuiDialog-paper {
      min-width: 304px;
      width: auto;
    }
  }

  &.smallModal {
    .MuiDialog-paper {
      @media screen and (max-device-width: 458px) {
        min-width: 304px;
        width: auto;
      }

      min-width: 450px;
      width: auto;
    }
  }

  &.mediumModal {
    &.minWidth {
      .MuiDialog-paper {
        min-width: 500px;
      }
    }

    .MuiDialog-paper {
      max-width: 710px;
      width: auto;
    }
  }

  &.bigModal {
    .MuiDialog-paper {
      max-width: 850px;
      width: auto;
    }
  }

  &.bigModal-help {
    .MuiDialog-paper {
      max-width: 50%;
      width: auto;
    }
  }

  &.certificate-modal {
    .MuiDialog-paper {
      max-width: 385px;
      width: auto;
    }
  }

  &.largeModal {
    width: 95% !important;

    .MuiPaper-root {
      margin: 0;
    }
  }

  &.modal1085 {
    max-width: 1085px;
  }

  &.dialog100 {
    .MuiPaper-root {
      width: 100%;
      max-width: 100%;
    }
  }

  .MuiDialog-container {
    width: 100%;
    height: 90%;
    display: flex;
    align-items: flex-start;
  }

  .noBoxStyle {
    box-shadow: none !important;
    border: none !important;
  }

  .minHeight150px {
    min-height: 150px !important;
  }

  .modalContent {
    background: #F6F6F8;
    padding: 16px;
    width: 100%;
    height: auto;
    border: none;
    overflow-x: hidden;

    .lightBlue {
      td {
        color: #5062F0;
      }
    }

    &:focus {
      outline: none;
    }

    &.onlyTable {
      background: #FFF;
      padding: 0 0 8px 0;

      table {
        width: 100%;
        box-shadow: none;
        border: none;

        svg {
          color: #313347;
        }

        thead {
          th {
            background: #F6F6F8;
            color: #77798B;
            padding: 8px 16px;
            position: sticky;
            top: 0px;

            &:first-of-type {
              border-top-left-radius: 4px;
            }

            &:last-of-type {
              border-top-right-radius: 4px;
            }

            &.titleLineBorder {
              padding: 0;
            }
          }
        }

        tbody {

          td,
          tr {
            padding: 4px 16px;
          }
        }

        td,
        th {
          font-family: "Lato";

          &.right {
            text-align: right;
          }

          &.center {
            text-align: center;
          }
        }
      }
    }

    &.onlyTableProducts {
      background: #FFF;
      padding: 0 0 8px 0;

      table {
        width: 100%;
        box-shadow: rgba(4, 6, 26, 0.25) 0px 0px 8px;
        border: none;

        svg {
          color: #313347;
        }

        thead {
          th {
            background: #313347;
            color: #ffffff;
            padding: 8px 16px;
            position: sticky;
            top: 0px;

            &:first-of-type {
              border-top-left-radius: 4px;
            }

            &:last-of-type {
              border-top-right-radius: 4px;
            }

            &.titleLineBorder {
              padding: 0;
            }
          }
        }

        tbody {

          td,
          tr {
            padding: 4px 16px;
          }
        }

        td,
        th {
          font-family: "Lato";

          &.right {
            text-align: right;
          }

          &.center {
            text-align: center;
          }
        }
      }
    }

    .modalSubtitle {
      background: #F6F6F8;
      color: #77798B;
      margin-bottom: 0;
      padding: 4px 16px;
      left: -16px;
      position: relative;
      width: calc(100% + 32px);
      font-weight: bold;
    }

    .MuiContainer-root {
      padding: 0;
      margin: 0;
    }

    .title {
      margin-bottom: 20px;
    }

    table {
      border-radius: 4px;
      box-shadow: 0px 0px 8px rgba(4, 6, 26, 0.25);
      width: 100%;

      thead {
        th {
          background: #313347;
          color: #FFF;
          padding: 8px 12px;
          position: sticky;
          top: -20px;
          font-size: 12px;

          &:first-of-type {
            border-top-left-radius: 4px;
          }

          &:last-of-type {
            border-top-right-radius: 4px;
          }

          &.titleLineBorder {
            padding: 0;
          }
        }
      }

      tbody {
        td {
          padding: 4px 12px;
        }
      }

      td {
        &.alignRight {
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
}

/* Periodicity Register Dialog */
.periodicityRegisterDialog {
  .MuiPaper-root {
    max-width: 730px;
  }
}

// .customZIndex {
//   z-index: 1301 !important;
// }

/* Registers Modal */
.modalRegisterContainer {
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  .MuiPaper-root {
    width: 70%;
    max-width: 100%;

    .MuiPaper-root {
      width: 100%;
    }
  }

  .modalContent {
    .modalTitle {
      font-weight: bold;
      color: $white;
      background: #3f51b5;
      min-height: 44px;
      padding: 0 24px !important;
      margin-bottom: 15px;
      display: flex;
      align-items: center;
      font-size: 16px;
    }
  }

  &.small {
    width: 50%;

    .modalContent {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      flex-direction: column;

      h4 {
        margin-bottom: 20px;
        font-weight: bold;
      }
    }

    .modalActions {
      display: flex;
      justify-content: center;

      button {
        &:first-of-type {
          margin-right: 20px;
        }
      }
    }
  }
}

/* Confirmations Modal */
.modalConfirmationContainer {
  &.small {
    width: 50%;

    .modalContent {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      flex-direction: column;

      h4 {
        margin-bottom: 20px;
        font-weight: bold;
      }
    }

    .modalActions {
      display: flex;
      justify-content: center;

      button {
        &:first-of-type {
          margin-right: 20px;
        }
      }
    }
  }
}

/* Product Table Dialog */
.productTableDialog {
  width: 100%;

  .input-search-sku-site {
    display: none;
  }

  #categoryContainer {
    width: 12vw;

    label {
      display: block;
      margin-bottom: 9px;
    }
  }

  #categoryBox {
    width: 100%;

    label {
      width: 100%;
      margin-bottom: 0;

      input {
        width: 100%;
        border-bottom: 1px solid #000;
      }
    }
  }

  .MuiPaper-root {
    width: 90%;
    max-width: 100%;
  }

  .MuiAlert-root {
    width: 100%;
  }

  table {
    margin-bottom: 60px;
  }

  tbody {
    tr {
      &.dividerBg {
        background: rgba(146, 150, 172, .2)
      }
    }
  }

  .pagination {
    position: fixed;
    width: 99%;
    bottom: 0;
    background: #F6F6F8;
    padding: 10px 8px 10px 15px;
    margin: 0 0 0 -16px;
    z-index: 999
  }
}

/* Sku Modal */
.skuModalContainer {
  width: 95%;
  height: 90%;
  margin: auto;
  background: #F6F6F8;
  border-radius: 4px;
  overflow: hidden;

  #draggable-dialog-title {
    padding: 0;
  }

  .MuiDialog-paperScrollPaper {
    overflow-x: hidden;
  }

  .modalContent {
    padding: 16px;
  }

  .modalContent,
  .MuiDialogActions-root {
    background: #F6F6F8;
  }

  .modalBox {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;

    form {
      width: 100%;

      .MuiButtonBase-root {
        padding: 5px 0 5px 12px;
      }
    }

    &.boxShadowCustom {
      justify-content: flex-start;
      box-shadow: 0px 5px 5px 2px #ccc;
      border-radius: 0;
    }
  }

  .modalTitle {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 !important;

    p {
      background: #5062F0;
      border-radius: 4px 4px 0px 0px;
      padding: 7px 16px !important;
      font-size: 12px !important;
      color: $white;
      text-transform: uppercase;
      min-width: 190px;
    }

    .actionButton {
      span {
        font-size: 12px;
        font-family: "Lato";
        padding: 0 12px;
      }

      svg {
        color: $white;
      }
    }

    &.smallTab {
      p {
        min-width: 50px;
        border-radius: 4px 0px 0px 0px;
      }

      .cardTab {
        display: flex;
        background: #0E1337;
        border-radius: 0px 4px 0px 0px;
        padding: 4.8px 16px;

        p,
        span {
          margin-bottom: 0;
          color: #D0D3E2;
          font-size: 12px;
          font-family: 'Lato';
        }

        p {
          padding: 0 !important;
          background: transparent;
          border-radius: 0;
          text-transform: inherit;
        }

        span {
          color: #FFF;
          font-size: 16px;
          font-weight: bold;
          margin-left: 5px;
        }
      }
    }

    &.smallTabSecondary {
      p {
        border-radius: 4px 0px 0px 0px;
      }

      .cardTab {
        display: flex;
        background: #0E1337;
        border-radius: 0px 4px 0px 0px;
        padding: 4.8px 16px;

        p,
        span {
          margin-bottom: 0;
          color: #D0D3E2;
          font-size: 12px;
          font-family: 'Lato';
        }

        p {
          padding: 0 !important;
          background: transparent;
          border-radius: 0;
          text-transform: inherit;
        }

        span {
          color: #FFF;
          font-size: 16px;
          font-weight: bold;
          margin-left: 5px;
        }
      }
    }
  }

  .associatedSkusBox {
    table {
      tr {
        display: table;
        table-layout: fixed;
        width: 100%;
      }

      tbody {
        height: auto;
        max-height: 130px;
        overflow: auto;
        display: block;
      }
    }
  }

  .inputBox {
    margin-bottom: 10px;
    padding: 0 8px 0 0 !important;

    &.p-0 {
      padding: 0 !important;
    }
  }

  .textAreaBox {
    padding: 0 !important;
  }

  .itemWithModal {
    .MuiAutocomplete-root {
      width: 90%;

      .MuiFormControl-root {
        width: 100% !important;
      }
    }

    .modalButton {
      padding: 5px 0 5px 10px;
    }

    .defaultButton {
      &:disabled {
        background: transparent !important;
        color: #313347 !important;
        border: solid 1px #313347 !important;

        span {
          color: #313347 !important;
        }
      }
    }
  }

  .addSkuButton {
    position: absolute !important;
    right: 20px;
    background-color: rgba(63, 81, 181, .6) !important;

    &:hover {
      background-color: rgba(63, 81, 181, 1) !important;
    }

    svg {
      color: $white;
    }
  }

  .customSkuModalGridItem {
    flex-grow: 1 !important;
  }

  .productUseOnCardsCustomGrid {
    padding-right: 10px !important;
    max-width: 152px !important;
  }

  .productTakeInventoryCustomGrid {
    max-width: 161px !important;
  }

  .unifySkusCustomGrid {
    padding-right: 0 !important;
    max-width: 212px !important;
  }

  .skuUnitMeasurementsBox {
    .MuiInputBase-root {
      padding-right: 0 !important;

      .MuiAutocomplete-endAdornment {
        width: 100%;
        right: 0;
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  .tagsManagerBox {
    border-left: solid 1.5px #9296AC;
    border-bottom: solid 5.5px #9296AC;
    border-radius: 0px 4px 4px 0px;
  }

  .cropImageBox {
    .defaultButton {
      background: #5062F0;
      padding: 5px 10px !important;

      &:hover {
        background: #5062F0;
      }

      span {
        color: #FFF;
        font-size: 12px;
      }

      &.backButton {
        span {
          color: #313347;
        }
      }
    }
  }
}

/* Google Search Dialog */
.googleSearchImageDialog {
  .MuiPaper-root {
    width: 63.5%;
    max-width: 100%;
  }

  .MuiSnackbar-root {
    width: 440px;
  }

  .MuiAlert-root {
    width: 100%;
  }

  .modalHeader {
    a {
      font-weight: bold;
      font-size: 12px;
      color: #FFF;
      margin-right: 30px;
      text-decoration: underline;
    }
  }

  .searchBox {
    margin-bottom: 25px;

    img {
      width: 44px;
      height: 44px;
      cursor: pointer;
      z-index: 99999;
    }

    .textFieldSmall {
      margin: 1px 0 0 -20px;
      height: 42px;
      width: 400px;

      .MuiInputBase-root {
        height: 100%;
      }

      input {
        height: 100%;
        width: 100%;
        padding: 0 0 0 30px;
        margin: 0;
        border: 1px solid #5062F0;
        border-left: none;
        border-radius: 8px;
      }

      fieldset {
        border-bottom: none;
      }
    }
  }

  .imageOptionsContainer {
    margin-bottom: -15px;

    .imageOptionBox {
      width: 176px;
      float: left;
      margin: 0 12px 12px 0;

      &:last-of-type,
      &:nth-of-type(4n) {
        margin-right: 0;
      }
    }

    .imageBox {
      width: 100%;
      height: 176px;
      border: 1px solid #F2F3F8;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }

    .refLinkUrl {
      align-items: center;
      margin-bottom: 5px;

      span,
      a {
        font-size: 12px;
        width: 100%;
      }

      a {
        text-decoration: underline;
      }
    }
  }

  .MuiLinearProgress-root {
    height: 10px;
  }
}

/* Image Cropper Dialog */
.imageCropperDialog {
  .MuiPaper-root {
    width: 80%;
    max-width: 100%;
  }

  .imgCropBox {
    width: 580px;
  }

  .imgPreviewBox {
    p {
      display: flex;
      justify-content: center;
      margin-bottom: 10px;

      td {
        font-size: 14px;
        text-align: center;
      }
    }

    .img-preview {
      width: 340px !important;
    }
  }

  .actionButtons {
    margin-top: 10px;

    .defaultButton {
      font-size: 12px !important;
      padding: 8px 10px !important;
    }
  }
}

/* Sku Comparator Modal */
.modalSkuComparator {
  width: 80%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  &.large {
    width: 95%;

    .modalContent {
      height: 99%;
      overflow: auto;
    }
  }

  .modalContent {
    background: #FFF;
    padding: 20px;
    width: 100%;
    height: auto;
    border: none;

    &:focus {
      outline: none;
    }

    .MuiContainer-root {
      padding: 0;
      margin: 0;
    }
  }
}

.skuComparatorContainer {
  max-width: 100% !important;

  .skuContent {
    overflow: hidden;
    overflow-y: scroll;
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 82vh;
  }

  form {
    display: flex;
    justify-content: space-between;
    max-width: 100%;
    flex-wrap: wrap;
  }

  .skuBox {
    width: 49%;
    flex-direction: column;

    .skuSearchToolBar {
      .Mui-focused {
        label {
          display: none;
        }
      }

      label {
        padding-left: 40px;
        color: $white;

        &.MuiInputLabel-shrink {
          transform: translate(14px, 12px) scale(1)
        }
      }

      legend {
        height: 8px;
      }

      input {
        padding-left: 45px !important;
        color: $white;
      }

      button {
        svg {
          color: $white;
        }
      }
    }

    .MuiTypography-h6 {
      font-size: 16px;
    }

    .MuiAutocomplete-popper {
      width: 400px !important;
    }

    .skuInfos {
      border: 1px solid rgba(0, 0, 0, .1);
      padding: 24px;
      min-height: 15%;

      li {
        margin-bottom: 24px;

        &:last-of-type {
          margin-bottom: 0;
        }

        &.withTwoItems {
          display: flex;
          justify-content: space-between;

          .MuiFormControl-root {
            width: 49%;
          }
        }
      }

      .listContainer {
        display: flex;
        flex-direction: row;
        align-items: center;

        legend {
          width: auto;
          margin-right: 20px;

          &.Mui-focused {
            color: rgba(0, 0, 0, 0.54);
          }
        }

        .listBox {
          width: 100%;
          display: flex;
          flex-wrap: unset;
          flex-direction: column;

          label {
            margin-left: 0;
            margin-bottom: 0;

            svg {
              color: $primary;
            }
          }

          .checkListItem {
            .MuiListItemIcon-root {
              min-width: auto;
            }

            svg {
              color: $primary;
            }
          }
        }
      }
    }
  }

  .footerActions {
    width: 100%;
    padding: 24px 0 0;
    display: flex;
    justify-content: center;

    button {
      margin-left: 20px;

      &:focus {
        outline: none;
      }

      &:first-of-type {
        margin-left: 0;
      }

      &.registerButton {
        background: rgba(21, 87, 36, .6);
        border: solid 1px rgba(21, 87, 36, .6);

        &:hover {
          background: rgba(21, 87, 36, .8);
          border: solid 1px rgba(21, 87, 36, .8);
        }
      }

      &.rejectButton {
        background: rgba(114, 28, 36, .6);
        border: solid 1px rgba(114, 28, 36, .6);

        &:hover {
          background: rgba(114, 28, 36, .8);
          border: solid 1px rgba(114, 28, 36, .8);
        }
      }
    }
  }
}

/* Setup Modals */
.setupProductModal {
  .MuiDialog-paper {
    max-width: 80%;
    width: 80%;
  }

  &.w-auto {
    .MuiDialog-paper {
      width: auto;
    }
  }
}

/* Related Cards Modal */
.relatedCardsModal {
  z-index: 1299 !important;

  .MuiPaper-root {
    width: 80%;
    max-width: 80%;
  }
}

/* DrillDown Modal */
.drillDownModal {
  z-index: 1399 !important;

  .MuiPaper-root {
    width: 60%;
    max-width: 60%;
  }
}

/* Related Skus Modal */
.relatedSkusDialog {
  z-index: 1299 !important;
}

.productFormDialog {
  .MuiPaper-root {
    width: 80%;
    max-width: 80%;
  }
}

/* Select Restaurant Dialog */
.selectRestaurantDialog {
  .MuiDialog-paper {
    max-width: 800px;
    width: 800px;
  }

  h2 {
    p {
      font-size: 16px;
    }
  }

  .MuiDialogContent-root {
    padding: 0;

    li {
      padding: 8px 16px;
      font-size: 16px !important;
      font-family: "Lato";
      line-height: normal;
      width: 100% !important;

      &.storeHeader {
        padding: 8px 30px;
      }
    }
  }

  .subGroupHeader {
    background: #5062F0;
    color: #FFF;
  }
}

/* Select User Dialog */
.selectUserDialog {
  .MuiDialog-paper {
    max-width: 320px;
    width: 320px;
  }

  h2 {
    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
    }
  }

  .MuiDialogContent-root {
    padding: 0;

    li {
      padding: 8px 16px;
      font-size: 16px !important;
      font-family: "Lato";
      line-height: normal;
      width: 100% !important;

      &.storeHeader {
        padding: 8px 30px;
      }
    }
  }

  .subGroupHeader {
    background: #F2F3F8;
    color: #5062F0;
    cursor: pointer;
    border-bottom: solid 1px;
  }

  .subGroupHeaderMobile {
    background: #F2F3F8;
    color: #5062F0;
    cursor: pointer;
    border-bottom: solid 1px;
    height: 50px;
    display: flex;
    align-items: center;
  }

  .subSelectHeader {
    background: #5062F0;
    color: #F2F3F8;
    padding-left: 8px;
  }

  .menuItem {
    font-family: "Lato";
    width: 250px;
    color: #5062F0
  }
  
  .menuItemMobile {
    font-family: "Lato";
    width: 250px;
    color: #5062F0;
    height: 50px
  }
}

/* Action Plan Daily Schedule Dialog */
.actionPlanDialog {
  .MuiDialog-paper {
    max-width: 430px;
    width: 430px;
  }

  h2 {
    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
    }
  }

  .MuiDialogContent-root {
    padding: 0;

    li {
      padding: 8px 16px;
      font-size: 16px !important;
      font-family: "Lato";
      line-height: normal;
      width: 100% !important;
    }
  }

  .defaultButton {
    display: flex !important;
    border-radius: 8px !important;
    padding: 12px 16px !important;
    line-height: normal !important;
    font-size: 12px !important;
    font-family: 'Lato' !important;
    text-transform: initial !important;
    letter-spacing: normal !important;
  }

  .inputStyle {
    div {
      input {
        padding: 2px 0;
        font-size: 12px;
        height: 100px;
      }

      input::placeholder {
        font-size: 12px;
        font-family: 'Lato', sans-serif;
        font-style: italic;
        color: #B8BFF5;
        position: absolute;
        left: 0px;
        top: 0px;
      }
    }

    div::before {
      border-bottom: 1px solid #333541 !important;
    }

    div::after {
      border-bottom: 1px solid #333541 !important;
    }
  }
}

/* Select User Password Dialog */
.selectUserPasswordDialog {
  .MuiDialog-paper {
    max-width: 180px;
    max-height: 117px;
    width: 180px;
    height: 117px;
  }

  h2 {
    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
    }
  }

  .MuiDialogContent-root {
    padding: 0;

    li {
      padding: 8px 16px;
      font-size: 16px !important;
      font-family: "Lato";
      line-height: normal;
      width: 100% !important;

      &.storeHeader {
        padding: 8px 30px;
      }
    }
  }
}

/* Sku Restaurants Dialog */
.skuRestaurantsDialog {
  .MuiDialog-paper {
    max-width: 650px !important;

    .modalContent {
      tr {

        th,
        td {
          padding-right: 0 !important;

          &:last-of-type {
            padding-right: 16px !important;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .modalProducao {
    .MuiDialog-paperFullWidth {
      width: 100% !important;
      max-width: 100% !important;
      margin: 0px !important;
    }

    .MuiDialog-paper {
      width: 100% !important;
      max-width: 100% !important;
      margin: 0px !important;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .modalProducao {
    .MuiPaper-root {
      overflow: visible;
    }

    .MuiDialog-paperScrollPaper {
      margin: 12px 0px !important;
      max-height: calc(100% - 135px) !important;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.8));
    }

    .iconButtonFullContainer {
      height: 70px;
      border-radius: 12px;
      box-shadow: 3px 3px 4px 0px #000000BF;

      .iconButtonFull {
        img {
          box-shadow: 3px 3px 6px 0px #000000BF;
          border-radius: 30px;
          width: 50px;
          height: 50px;
        }
      }

      .finishedTimeCircle {
        width: 70px;
        height: 70px;
        box-shadow: 0px 4px 4px 0px #00000080;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        right: 0;
        position: absolute;
  
        span {
          font-size: 16px;
        }
  
        &.positiveTime {
          background: rgba(0, 102, 8, 1);
  
          span {
            color: #FFF;
          }
        }
  
        &.noTime {
          background: rgba(247, 213, 110, 1);
  
          span {
            color: rgba(14, 19, 55, 1)
          }
        }
      }
    }

    .MuiDialog-paperWidthSm {
      max-width: 735px;
      border: solid 2px #1F2445;
      border-bottom: none;
    }

    .MuiDialog-paperFullWidth {
      width: calc(100% - 0px) !important;
      margin: 0 !important;
      border-radius: 0 0 38px 38px;
    }

    .leftFakeBorder {
      position: absolute;
      left: -22px;
      top: -20px;
      height: calc(100% + 44px);
      width: calc(100% + 44px);
      box-shadow: inset 8px 4px 6px rgba(0, 0, 0, .5);
      border-radius: 32px;
    }

    .rightFakeBorder {
      position: absolute;
      right: 1px;
      top: 0px;
      height: 100%;
      width: 100%;
      border-radius: 14px;
      box-shadow: 5px 4px 7px 5px rgba(0, 0, 0, .5);
    }
  }

  .delete-modal-mobile {
    .MuiDialog-paper {
      margin: 12px;
    }
  }
}

.delete-modal-mobile {
  .MuiPaper-root {
    border: solid 2px #1F2445;
  }
}

.sumeValuesProductionDialog {
  input {
    font-size: 20px;
  }
}

.discardHeader{
  height: 36px;
  padding: 0px 16px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.discart{
  max-width: 300px;
  background-color: #F2F2F4 !important;
  padding: 12px 11px !important;
  >div:first-child{
    padding: 9px;
    box-shadow: 0px 0px 8px 0px rgba(4, 6, 26, 0.25);
    border-radius: 4px;
    margin-bottom: 10px !important;
    padding-bottom: 16px;
    background-color: #fff;
    >div:first-child{
      padding-top: 13px;
      padding-bottom: 7px;
    }
  }
  .fixedInfo{
    position: relative;
    .labelName{
      font-family: Lato;
      font-size: 12px;
      font-style: normal;
      font-weight: 700 !important;
      line-height: 16px;
      position: absolute;
      top: -16px;
      &.big{
        top: -10px;
      }
    }
    
    .productDescription{
      padding-top: 10px !important;
      max-height: 40px;
      font-family: Lato;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      display: flex;
      align-items: self-end;
    }
  }
  
  .datePicker{
    div{
      margin-top: 0px !important;
      height: 26px;
    }
    label{
      position: absolute !important;
      top: -15px;
      font-family: Lato;
      font-size: 12px;
      font-style: normal;
      font-weight: 700 !important;
      line-height: 16px;
      transform: scale(1) !important;
    }
    input{
      padding-bottom: 0px !important;
    }
    button{
      padding: 0px;
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='Icons'%3E%3Cpath id='Vector (Stroke)' fill-rule='evenodd' clip-rule='evenodd' d='M5.55556 1C5.8317 1 6.05556 1.22386 6.05556 1.5V2.3H9.94444V1.5C9.94444 1.22386 10.1683 1 10.4444 1C10.7206 1 10.9444 1.22386 10.9444 1.5V2.3H12.2778C13.2575 2.3 14 3.1354 14 4.1V13.2C14 14.1646 13.2575 15 12.2778 15H3.72222C2.74245 15 2 14.1646 2 13.2V4.1C2 3.1354 2.74245 2.3 3.72222 2.3H5.05556V1.5C5.05556 1.22386 5.27941 1 5.55556 1ZM5.05556 3.3H3.72222C3.35196 3.3 3 3.62866 3 4.1V6.2H13V4.1C13 3.62866 12.648 3.3 12.2778 3.3H10.9444V4.1C10.9444 4.37614 10.7206 4.6 10.4444 4.6C10.1683 4.6 9.94444 4.37614 9.94444 4.1V3.3H6.05556V4.1C6.05556 4.37614 5.8317 4.6 5.55556 4.6C5.27941 4.6 5.05556 4.37614 5.05556 4.1V3.3ZM13 7.2H3V13.2C3 13.6713 3.35196 14 3.72222 14H12.2778C12.648 14 13 13.6713 13 13.2V7.2Z' fill='%23333541'/%3E%3C/g%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: center;
      >span{
        visibility: hidden;
      }
    }
  }
  
  .labelTextAlignRight{
    label{
      text-align: right;
      width: 100%;
    }
    >div{
      height: 26px;
    }
  }

  .autocompleteSmall{
    &.discard{
      >div >div{
        padding-top: 0px !important;
        padding-bottom: 3px !important;
      }
    }
  }
}

/* Manager Confirmation Dialog */
.confirmManagerValidationDialog {
  .MuiDialog-container {
    width: 41%;
  }
  
  .modalHeader {
    background: #5062F0;
  }
  
  h2 {
    p {
      margin-bottom: 0;
    }
  }

  p {
    font-size: 14px;
    margin-bottom: 20px;
  }

  .MuiFormControl-root {
    margin-bottom: 20px;

    .MuiInputBase-root {
      margin-top: 5px;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
  
  .MuiDialogActions-root {
    justify-content: flex-end !important;
    padding: 0 16px 16px;

    .Mui-disabled {
      background: #D0D3E2 !important;
      
      span {
        color: #A0A1A7;
      }
    }
  }
}
