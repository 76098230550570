.copilotTable {
  input {
    background: #FFF
  }
  
  tbody {
    .headerTitle {
      th {
        top: 77px !important;
      }
    }

    .defaultLine {
      td {
        height: 46px;
        vertical-align: middle;

        td {
          vertical-align: middle;
          display: contents;
        }
      }
    }

    .itemTransformationLine {
      .copilotTableCollapse {
        input {
          height: 29px;
        }
      }
    }

    .itemProductionTableDescription {
      .MuiInputBase-root {
        width: 100%;

        input {
          height: 18px;
          padding: 5px 5px 5px;
        }
      }

      .MuiInputAdornment-root {
        display: none;
      }
    }
  }

  .copilotWeekTagsBox {
    display: flex;
    align-items: center;
    justify-content: center;

    .copilotWeekTag {
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 4px;
      width: 20px;
      height: 20px;
      margin-right: 2px;

      span {
        color: #454A67;
      }

      &.active {
        background: #7583F0;

        span {
          color: #FFF;
        }
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}

.copilotTagsFilter {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px 0;
  min-height: 40px;
  position: sticky;
  top: -8px;
  background: #FAFAFA;
  z-index: 9;
  border-radius: 10px 0 0 0;

  .defaultTagFilter {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px 8px;
    width: 160px;
    box-shadow: 0px 4px 4px 0px #00000026;
    border-radius: 10px;
    margin-right: 8px;
    cursor: pointer;

    span {
      font-size: 12px;
      color: #0E1337;
      user-select: none;
    }

    &.active {
      border: 1px solid #000000;
      box-shadow: 0px 6px 4px 0px #00000080;
    }

    &.includedNoSchedule {
      background: rgba(80, 98, 240, .45);

      span {
        font-weight: bold;
        color: #F53D4C;
      }
    }

    &.includedSchedule {
      background: rgba(80, 98, 240, .2);
    }

    &.noIncludedSchedule {
      background: rgba(247, 213, 110, .5);

      span {
        font-weight: bold;
      }
    }

    &.noIncludedNoSchedule {
      background: rgba(247, 213, 110, .2);
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
}

.copilotHeader {
  position: sticky;
  top: 26px;
  z-index: 9;
}

.copilotTableCollapse {
  display: flex;
  justify-content: flex-end;
  
  input {
    background: #FFF;
    padding-bottom: 2px;
    height: 23px;
    padding-right: 2px;
  }

  .MuiInputAdornment-root {
    display: none;
  }
}

.copilotHeader {
  background: #FFFFFF;
}

.copilotDialog,
.copilotHeader {
  width: 100%;
  box-shadow: 0px 0px 8px 0px #04061A40;
  border-radius: 4px 4px 0 0;
  padding: 4px 4px 0 0;

  .closeProgrammingButton {
    background: rgba(80, 98, 240, 1);
    color: rgba(255, 255, 255, 1);
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
    font-size: 12px;
    cursor: pointer;

    &.disabled {
      background: rgba(210, 211, 215, 1);
      color: rgba(102, 103, 112, 1)
    }
  }

  .modalContent {
    table {
      tbody {
        td {
          padding: 0
        }
      }

      &.dropDownTable {
        box-shadow: unset;
      }
    }
  }
}

.copilotDiffOnSales {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: rgba(245, 61, 76, .85);
  border-radius: 4px;
  padding: 8px 8px 10px 8px;
  margin-top: -10px;
  margin-bottom: 12px;

  p,
  span {
    color: #FFF;
    font-size: 12px;
  }

  p {
    margin-bottom: 8px;
  }

  .diffOnSalesInfos {
    display: flex;
    justify-content: center;

    img,
    svg {
      margin-left: 5px;
    }

    img {
      width: 15px;
    }

    svg {
      width: 11px
    }
  }
}

/* Popover Quantity or Yield */
.copilotQuantityOrYieldPopover {
  box-shadow: 0px 8px 16px 0px #00000080;

  .MuiPaper-root {
    padding: 0 !important;
    margin-left: 7% !important;
  }

  .popoverCardInfo,
  .popoverCardActions {
    width: 100%;
  }

  .popoverCardInfo {
    padding: 8px 10px;
    background: rgba(80, 98, 240, .2);
    border-bottom: 1px solid #454A67;

    span {
      margin-bottom: 5px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .popoverCardActions {
    padding: 1px 5px 8px 10px;

    .popoverCardValues {
      span {
        display: block;
        margin-top: 3px;
      }
    }

    .MuiFormControlLabel-root {
      margin-bottom: 0;
    }

    .MuiButtonBase-root {
      padding: 5px 3px 3px;
      margin-left: 5px;

      svg {
        color: #5062F0;
        width: 16px;
      }

      &:first-of-type {
        margin-left: 0;
      }
    }
  }
}

/* Popovers */
.itemsDiffQuantitiesPopover {
  .MuiPaper-root {
    width: 550px;
    max-width: 550px !important;
    border-radius: 8px !important;
  }

  .itemsDiffQuantitiesPopoverHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: #F53D4C;
    padding: 15px;
    border-bottom: solid 1px #454A67;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);

    img {
      margin-left: 10px;
      width: 20px;
    }

    p,
    span {
      color: #FFF;
      text-align: center;
    }
  }

  .cardDescriptionBox {
    padding: 8px 10px;
    background: rgba(146, 150, 172, .15);
    border-bottom: solid 1px #454A67;
    border-bottom: solid 1px #454A67;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);

    p {
      color: #000;
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  .tableInfosBox {
    padding: 10px;

    p {
      color: #010311;
      margin-right: 10px;
    }

    table {
      margin-top: 12px;

      td {
        line-height: 2 !important;
      }
    }
  }
}

.dialogSalesProjection {
  .MuiPaper-root {
    width: 80%;
    max-width: 1366px !important;
    border-radius: 8px !important;
  }

  .itemsDiffQuantitiesPopoverHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: #F53D4C;
    padding: 15px;
    border-bottom: solid 1px #454A67;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);

    img {
      margin-left: 10px;
      width: 20px;
    }

    p,
    span {
      color: #FFF;
      text-align: center;
    }
  }

  .cardDescriptionBox {
    padding: 8px 10px;
    background: rgba(146, 150, 172, .15);
    border-bottom: solid 1px #454A67;
    border-bottom: solid 1px #454A67;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);

    p {
      color: #000;
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  .tableInfosBox {
    padding: 10px;

    p {
      color: #010311;
      margin-right: 10px;
    }

    table {
      margin-top: 12px;

      td {
        line-height: 2 !important;
      }
    }
  }
}

.processing{
  width: 100%;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #AA8208;
  margin-bottom: 14px;
  box-shadow: 0px 0px 8px 0px rgba(4, 6, 26, 0.25);
  p {
    margin: 0px;
    text-align: center;
    font-variant-numeric: lining-nums tabular-nums;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #fff;
  }
}