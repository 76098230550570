/* Mobile - Production Screen */
@media screen and (min-device-width: 320px) and (max-device-width: 1024px), screen and (max-device-width: 1366px) and (min-device-height: 1024px) {
  .productionTable {
    display: none !important;
  }
}

.productionPopupButton {
  &:disabled {
    background: #D0D3E2 !important;
    border: none !important;

    span {
      color: #666770;
    }
  }
}

.addNewProductionDialog {
  margin-top: 150px;

  .MuiBackdrop-root {
    top: 150px !important;
    background-color: rgba(0, 0, 0, 0.8);
  }

  .MuiDialog-scrollPaper {
    align-items: flex-start;
  }

  .MuiDialog-paper {
    overflow: unset;
    border-radius: 10px;
    margin: 0;
    max-width: 880px;
    width: 97%;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }

  #cardProductionDateMobile {
    margin-bottom: 0px;
    margin-top: -5px;
  }

  .addNewProductionContainer {
    display: flex;
    flex-direction: column;
    border-radius: 0 0 10px 10px;
    overflow: hidden;

    .previewCardBox {
      border-radius: 0 0 38px 38px;
    }

    .addNewProductionActions {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 12px;
      background: #FFF;
    }
  }
  
  @media only screen and (min-width: 600px) {
    margin-top: 190px !important;

    .MuiBackdrop-root {
      top: 190px !important;
    }
  }
}

#finishProductionDate {
  text-align: right;
}

.mobileProductionCurrentInputPopover {
  .MuiPaper-root {
    width: 348px !important;
    max-width: 100% !important;
    padding: 0 !important;
    box-shadow: 0px 8px 16px 0px #00000080;
    margin-left: 90px;
  }
  
  .cardProductionMainInfos {
    display: flex;
    flex-direction: column;
    background: rgba(80, 98, 240, .2);
    border-bottom: solid 1px rgba(69, 74, 103, 1);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 10px 8px;

    span {
      &:first-of-type {
        margin-bottom: 10px;
      }
    }
  }

  .cardProductionActionButtons {
    display: flex;
    flex-direction: column;
    padding: 10px 8px;

    .backButton {
      background: #E6E7F0 !important;
      
      span {
        color: #454A67 !important;
      }
    }

    .defaultButton {
      margin-left: 10px;

      span {
        font-size: 12px !important;
        font-weight: bold;
      }
    }
  }

  span {
    font-size: 16px;
  }
}

.productionPopover {
  button {
    span {
      font-size: 16px;
    }
  }

  .MuiPopover-paper {
    max-width: 420px !important;
    border: solid 2px #1F2445;

    @media screen and (max-device-width: 1024px) {
      position: relative;
      margin: 0 auto;
      left: auto !important;
      max-width: 733px !important;
      top: 30% !important;

      .popupProductionProductsTable {
        padding-right: 0 !important;
      }
    }

    @media screen and (max-device-width: 768px) {
      input,
      span,
      p,
      td {
        font-size: 16px !important;
      }
    }
  }

  @media screen and (min-device-width: 320px) and (max-device-width: 1024px) {
    #finishProductionDate {
      font-weight: bold;
    }
  }

  input {
    @media screen and (min-device-width: 320px) and (max-device-width: 1024px) {
      font-size: 20px !important;
      width: 100px;
    }

    @media screen and (max-device-width: 768px) {
      width: 60px;
    }
  }

  .popupProductionProductsTable {
    @media screen and (min-device-width: 320px) and (max-device-width: 1024px) {
      span {
        font-size: 20px !important;
      }

      input {
        height: 35px;
      }
    }
  }

  .quantityPopupBox {
    @media screen and (min-device-width: 320px) and (max-device-width: 1024px) {
      span {
        font-size: 20px !important;
      }

      input {
        font-weight: bold;
      }
    }
  }
}