@import "../common/variables.scss";

.extractModal {
  max-width: 1500px;

  .cardTable {
    background: #FFF;
    box-shadow: 0px 0px 8px rgba(4, 6, 26, 0.25);
    border-radius: 4px;
    padding-bottom: 10px;
    padding-top: 16px;
  }

  .productFilterAutocomplete {
    .MuiAutocomplete-endAdornment {
      .MuiAutocomplete-clearIndicator {
        display: none;
      }
    }
  }

  .modalTitleFake {
    background: #313347;
    padding: 4px 16px;

    p {
      font-family: "Lato";
      font-size: 12px;
      color: #FFF;
      line-height: 30px;
      font-weight: bold;
      margin-bottom: 0;
    }
  }

  table.realStock {
    width: 100%;
    color: #0E1337;
    border: none;

    .subtitle {
      font-family: "Lato";
      font-size: 14px;
      color: #0E1337;
      line-height: 30px;
      font-weight: bold;
    }

    .subtitleBold {
      font-family: "Lato";
      font-size: 14px;
      color: #0E1337;
      line-height: 30px;
    }

    .description {
      font-family: "Lato";
      font-size: 14px;
      color: #0E1337;
      line-height: 30px;

      &.error {
        color: $error;
      }
    }

    .bgWhite {
      background-color: #FFFFFF;
      div {
        span {
          font-weight: 700;
        }
      }
    }

    .right {
      text-align: right;
    }

    .withBorder {
      td {
        &.disableBorder {
          border-bottom: none !important;
        }
      }
    }
  }

  .filterDays {
    color: #5062F0;
  }
}

/* Config Columns Popover */
.extractDialogColumnsConfigPopover {
  .MuiPopover-paper {
    left: 272px !important;
  }
}
