/* List */
.extractCountsListTable {
  padding: 0;
  background-color: #fafafa;
  border-radius: 4px;
  padding-top: 20px;
  overflow-x: scroll;
  max-height: 62vh;

  table {
    width: 100%;
    border-spacing: 0;
    font-family: "Lato";
    font-size: 11px;
    background: #fff;
    box-shadow: 0px 0px 8px rgba(4, 6, 26, 0.25);

    .finishTab,
    .initialTab,
    .entryTab,
    .outputTab {
      border-radius: 4px 4px 0px 0px;
      padding: 4px 8px 2px;
      color: #FFF;
      position: absolute;
      top: -19px;
      left: 0;
      font-weight: normal;
      font-family: "Lato";
    }

    .entryTab,
    .outputTab {
      padding: 1px 5px 0px;
      top: -14px;
      font-weight: bold;
      font-size: 10px;
    }

    .finishTab {
      background: #001373;
    }

    .initialTab {
      background: #333541;
    }

    .entryTab {
      background: #006C5F;
    }

    .outputTab {
      background: #4D0073;
    }

    .defaultHeader {
      background: #313347;
      color: #FFF;
      position: sticky;
      top: -20px;
      z-index: 999;  
      padding: 8px 12px;
      border-right: 1px solid #77798B;

      &.category {
        border-right: none;
      }
    }

    .mainCount {
      background: #001373;
    }

    .theoreticalStock {
      background: #804800;
    }

    .count {
      background: #333541;
    }

    .shopping,
    .produced {
      background: #006C5F;
    }
    
    .sales,
    .productionConsumption,
    .transfers,
    .losses {
      background: #4D0073;
    }

    tr {
      :last-child {
        td {
          background: #fff;
          border-bottom: 0;
        }
      }

      &:hover {
        td {
          background-color:rgba(80, 98, 240, 0.15);

          td {
            background: transparent;
          }
        }
      }
    }

    thead {
      tr {
        th {
          &.defaultHeader {
            min-width: 160px;

            &.category {
              min-width: 180px;
            }
        
            &.itemCounts {
              min-width: 320px;
            }
        
            &.difference {
              min-width: 240px;
            }
          }

          &:first-of-type {
            border-top-left-radius: 4px;
          }

          &:last-of-type {
            border-top-right-radius: 4px;
          }
        }

        th,
        td {
          &.defaultSubtitle {
            border-bottom: 1px solid #eaeaea;
            font-size: 10px;
            position: sticky;
            top: 8px;
            z-index: 999;
            background: #F6F6F8;
            color: #77798B;
            text-align: left;   
            vertical-align: bottom;
            text-align: right;

            p {
              text-align: right;
            }
          }

          &.price {
            width: 70px; 
          }

          &.quantity,
          &.salesVar {
            width: 75px;
          }

          &.total {
            width: 85px;
          }
        }
      }
    }

    th,
    td {
      background: #fff;
      margin: 0;
      padding: 0.4rem;
      border-bottom: 1px solid #ECEDF1;

      :last-child {
        border-right: 0;
      }

      &.withoutBorder {
        border-left: none !important;
      }

      &.withBorder {
        border-left: solid 1px #77798B !important;
      }

      &.withoutRightBorder {
        border-right: none !important;
      }

      &.withRightBorder {
        border-right: solid 1px #77798B !important
      }

      &.withLeftDefaultBorder {
        border-left: solid 1px #BFC0CB !important;
      }

      &.withRightDefaultBorder {
        border-right: solid 1px #BFC0CB !important;
      }
    }

    td {
      span {
        display: flex;
        align-items: center;
      }

      td {
        padding: 0;
      }

      .diffCounts {
        background: #5062F0;
        color: #FFF;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        padding: 0 1px 1px 0;
        height: 20px;
        border-radius: 50px;
        font-size: 10px;
        margin-right: 10px;
      }

      &.salesVar {
        position: relative;

        .cellWithStyle {
          height: 100%;
          position: absolute;
          top: 0;
          bottom: 0px;
          right: 0;
          left: 0;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          padding-bottom: 2px;
          padding-right: 7px;
        }
      }
    }
  }
}