.so-tree-with-line.so-tree>.so-tree-node:first-child:before {
  top: 9px !important;
}

.so-tree-with-line .so-tree-node:before {
  left: 5px !important;
}

.categoriesTree {
  .so-tree-node,
  .addItemContainer {
    position: relative;

    &:before,
    &:after {
      background: #BFC0CB !important;
    }

    &:after {
      top: 14.5px !important;
      position: absolute;
      left: 6px;
      width: 11px;
      height: 1px;
      background-size: 2px 100%;
      content: " ";
    }

    &:before {
      position: absolute;
      bottom: 0;
      left: 5px;
      width: 1px;
      background-size: 100% 2px;
      content: " ";
      top: -15px;
      height: 100% !important;
    }

    &:first-of-type {
      &:before {
        top: -5px !important;
        height: calc(100% + 8px) !important;
      }
    }

    a {
      top: 3px;
    }

    &:last-of-type {
      &:before {
        height: 30px !important;
        top: -15px;
      }
    }
  }

  .addItemContainer {
    &:before {
      top: -3px !important;
    }

    .textFieldSmall {
      input {
        padding-left: 10px;
      }
    }
  }

  .so-tree-default-icon {
    background: #5062F0 !important;
    border: none !important;

    &:before,
    &:after {
      color: #FFF;
    }

    &:before {
      top: 5px !important;
      left: 2px !important;
    }

    &:after {
      top: 2px !important;
      left: 5px !important;
    }
  }

  .MuiFormControl-root {
    // margin: 0 0 0 4px;

    &:last-of-type {
      // margin: 0 0 16px 4px;
    }
  }

  .MuiInputBase-root {
    padding-left: 0;
    border-radius: 0;
    border-bottom: solid 1px #5062F0;

    fieldset {
      border-radius: 0;
      padding-left: 0;
      border: none;
    }

    input {
      font-size: 12px;
      font-family: "Lato";
      padding: 0px 10px 6px 0px;
      font-weight: 400;
    }
  }

  button {
    padding: 0 6px;

    svg {
      color: #313347;
    }
  }

  .smallIcons {
    padding: 0 7px;
    margin-top: 5px;

    svg {
      width: 18px;
    }
  }

  .textFieldSmall {
    input {
      padding: 6px 4px 6px 0;
      margin-bottom: 0;
    }
  }
}

.fixedRootCategories {
  padding-left: 25px;

  &:before,
  &:after {
    position: absolute;
    background: #BFC0CB !important;
    content: " ";
  }

  &:before {
    top: auto;
    bottom: 30px;
    left: 21px;
    width: 1px;
    height: calc(100% - 65px);
  }

  &:after {
    top: auto;
    left: 21px;
    width: 11px;
    height: 1px;
    bottom: 30px;
  }

  input {
    padding-right: 10px;
  }

  .smallIcons {
    padding: 0 7px;
    margin-top: 10px;

    svg {
      width: 18px;
    }
  }

  .smallCheckbox {
    margin-left: .6rem;
    transform: scale(0.75);
  }

  &.noLines {
    padding-left: 0;

    &:before,
    &:after {
      display: none;
    }
  }
}

.fixedRootCategoriesOnDialog {
  padding-left: 20px;
  input {
    padding-right: 10px;
  }
  button {
    span {
      svg {
        width: 18px;

      }
    }
  }
}

.checkboxCategorieDialog {
  svg {
    width: 18px;
    height: 18px;
  }
}

.categorieOutline {
  background-color: #F2B90D!important;
  padding: 1px!important;
  transition: all 350ms ease-in-out!important;
  color: #010311!important;
}

.categorieOutlineNoColor {
  background-color: #ffffff!important;
  padding: 1px!important;
  transition: all 350ms ease-in-out!important;
}
