/* Mobile */
@media screen and (min-device-width: 320px) and (max-device-width: 1024px), screen and (max-device-width: 1366px) and (min-device-height: 1024px) {
  .pdfViewerDialog {
    width: 100%;
    margin-top: 50px;

    .MuiDialog-paper {
      width: 90%;
    }

    .MuiDialog-paperWidthSm {
      max-width: 100%;
    }

    .textLayer,
    .annotationLayer {
      display: none;
    }

    .react-pdf__Page {
      display: flex;
      justify-content: center;
    }
  }
}
