/* Phones */
@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {
  main {
    margin: 119px 0 0 0 !important;
  }
}

/* Mobile */
@media screen and (min-device-width: 320px) and (max-device-width: 1024px), screen and (max-device-width: 1366px) and (min-device-height: 1024px) {
  main {
    // margin: 64px 0 0 0 !important;
  }
}

.smallTabletMainContainer {
  padding: 75px 10px 0px 10px !important;

  .filterBtn {
    display: flex;
    justify-content: space-between;
  }
}