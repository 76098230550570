.productCreateOrEditPage {
  form {
    .groupBox {
      border-radius: 4px;
      border: 1px solid rgba(117, 131, 240, .9);
      padding: 8px 8px 0 8px;
      margin: 0 -8px 0;
      width: calc(100% + 16px);
      max-width: calc(100% + 16px);
    }
  }
}