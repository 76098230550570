
.diffInCountsDialog {
  width: 90%;

  .diffInCountsFilterBox {
    position: relative;
  }

  .salesAndDiffFilterBox {
    overflow: hidden;
    width: auto;
    max-width: 300px;
    min-width: 260px;
    height: 48px;
    border-radius: 4px;
    padding-top: 0 !important;
    background: #FFF;
    border: solid 1px #5062F0;
    box-shadow: 0px 4px 4px 0px #000000BF;
    position: absolute;
    right: 10px;
    top: 24px;

    @media (max-width: 870px) and (orientation: portrait) {
      width: 38%;
      margin: 0;
      height: 52px;

      p,
      span {
        font-size: 14px !important;
      }

      .salesBox {
        .salesInfos {
          justify-content: space-between;
          width: 50% !important;
        }
      }
    }

    .salesBox {
      background: #5062F0;
      padding: 3px 6px 4px 6px;
      margin-bottom: 1px;

      p {
        font-size: 12px;
        color: #FFF;
      }

      img {
        box-shadow: 0px 2px 2px 0px #00000040;
      }

      .salesTitle {
        margin-right: 15px;

        &:last-of-type {
          margin-right: 0px;
        }
      }

      .salesInfos {
        width: 38%;

        span {
          font-size: 8px;
        }
      }
    }

    .totalDiscardInfos {
      display: flex;
      align-items: center;
      height: 22px;
      padding: 0px 6px;
      z-index: 100;
      background-color: #FFF;
      width: 100%;
      justify-content: flex-end;
      
      .iconTotal {
        display: flex;
        align-items: center;

        img {
          margin-right: 2px;
        }

        p,
        span {
          font-size: 12px;
          margin-top: 0;
          margin-bottom: 0;
          font-weight: bold;
          color: #454A67;
        }

        p {
          display: flex;
          align-items: center;
        }

        span {
          margin-left: 5px;
        }
      }

      .valuesDiscard {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 15px;
        padding: 0;
        width: 38%;

        p {
          font-weight: 700;
          color: #454A67;
          font-size: 12px;
          margin: 0 3px 0 0;
        }

        @media (max-width: 870px) and (orientation: portrait) {
          width: 50%;
          justify-content: space-between;

          span {
            margin-right: 2px;
          }
        }
      }
    }
  }

  .totalDiffBox {
    margin-right: 45px;

    .totalDiffBoxLabel,
    .totalDiffBoxValue {
      padding: 5px 6px;
    }

    .totalDiffBoxValue {
      border-radius: 50px;
      box-shadow: 0px 4px 4px 0px #00000040;
    }

    @media (max-width: 870px) and (orientation: portrait) {
      span {
        text-transform: uppercase;
      }

      .totalDiffBoxValue {
        padding: 2px 10px;
      }
      
      .totalDiffBoxLabel {
        padding-left: 0px;
      }
    }
  }
}