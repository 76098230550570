@import './variables';

body,
.MuiTypography-body1 {
  font-family: 'Lato' !important;
  overflow: hidden;
}

html {
  position: fixed !important;
}

#root {
  height: 100vh;

  .content-wrapper {
    height: 100vh;

    .main-content {
      height: 100vh;
    }
  }
}

.layoutContainer {
  margin-top: 55px;
}

.overflowHidden {
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
i,
figcaption,
a,
span,
li,
label,
strong,
select {
  &.requiredWarning {
    color: red;
    margin-top: 5px;
  }
}

.labelError {
  label {
    color: red !important;
  }

  .ant-select-selector,
  fieldset {
    border-color: red !important;
  }

  &.selectSmallBox {
    .selectSmall {
      border-bottom: solid 1px red !important;
    }
  }

  .MuiInput-underline:after,
  .MuiInput-underline:before {
    border-bottom: solid 2px red !important;
  }
}

*::-webkit-scrollbar-track {
  background-color: transparent;
  background: transparent !important;
}

*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
  background: transparent !important;
}

*::-webkit-scrollbar-thumb {
  background-color: #aaa;
  background: #aaa !important;
}

/* Spacements */
.mAuto {
  margin: auto !important;
}

.mlAuto {
  margin-left: auto !important;
}

.mrAuto {
  margin-right: auto !important;
}

.m0 {
  margin: 0 !important;
}

.m5 {
  margin: 5px !important;
}

.m10 {
  margin: 10px !important;
}

.mb {
  &0 {
    margin-bottom: 0 !important;
  }

  &2 {
    margin-bottom: 2px !important;
  }

  &3 {
    margin-bottom: 3px !important;
  }

  &5 {
    margin-bottom: 5px !important;
  }

  &8 {
    margin-bottom: 8px !important;
  }

  &10 {
    margin-bottom: 10px !important;
  }

  &12 {
    margin-bottom: 12px !important;
  }

  &16 {
    margin-bottom: 16px !important;
  }

  &20 {
    margin-bottom: 20px !important;
  }

  &30 {
    margin-bottom: 30px !important;
  }
}

.mt {
  &0 {
    margin-top: 0 !important;
  }

  &2 {
    margin-top: 2px !important;
  }

  &3 {
    margin-top: 3px !important;
  }

  &5 {
    margin-top: 5px !important;
  }

  &8 {
    margin-top: 8px !important;
  }

  &10 {
    margin-top: 10px !important;
  }

  &12 {
    margin-top: 12px !important;
  }

  &15 {
    margin-top: 15px !important;
  }

  &16 {
    margin-top: 16px !important;
  }

  &20 {
    margin-top: 20px !important;
  }

  &30 {
    margin-top: 30px !important;
  }
}

.mr {
  &0 {
    margin-right: 0px !important;
  }
  
  &3 {
    margin-right: 3px !important;
  }

  &5 {
    margin-right: 5px !important;
  }

  &8 {
    margin-right: 8px !important;
  }

  &10 {
    margin-right: 10px !important;
  }

  &12 {
    margin-right: 12px !important;
  }

  &15 {
    margin-right: 15px !important;
  }

  &16 {
    margin-right: 16px !important;
  }

  &20 {
    margin-right: 20px !important;
  }

  &25 {
    margin-right: 25px !important;
  }

  &30 {
    margin-right: 30px !important;
  }
}

.ml {
  &0 {
    margin-left: 0 !important;
  }

  &2 {
    margin-left: 2px !important;
  }

  &3 {
    margin-left: 3px !important;
  }

  &5 {
    margin-left: 5px !important;
  }

  &8 {
    margin-left: 8px !important;
  }

  &12 {
    margin-left: 12px !important;
  }

  &10 {
    margin-left: 10px !important;
  }

  &16 {
    margin-left: 16px !important;
  }

  &20 {
    margin-left: 20px !important;
  }
}

.p {
  &0 {
    padding: 0 !important;
  }

  &2 {
    padding: 2px !important;
  }

  &4 {
    padding: 0 4px !important;
  }

  &7 {
    padding: 0 7px !important;
  }
  
  &8 {
    padding: 0 8px !important;
  }
  
  &4all {
    padding: 4px !important;
  }

  &10 {
    padding: 0 10px !important;
  }

  &12 {
    padding: 0 12px !important;
  }

  &16 {
    padding: 16px !important;
  }

  &16h {
    padding: 0 16px !important;
  }

  &20h {
    padding: 0 20px !important;
  }

  &20 {
    padding: 20px !important;
  }

  &32h {
    padding: 0 32px !important;
  }

  /* Table td/th */
  &12 {
    padding: 0 12px !important;
  }
}

.ph {
  &16 {
    padding: 0 16px !important;
  }
}

.pl {
  &0 {
    padding-left: 0 !important;
  }

  &3 {
    padding-left: 3px !important;
  }

  &5 {
    padding-left: 5px !important;
  }

  &6 {
    padding-left: 6px !important;
  }

  &8 {
    padding-left: 8px !important;
  }

  &10 {
    padding-left: 10px !important;
  }

  &16 {
    padding-left: 16px !important;
  }

  &20 {
    padding-left: 20px !important;
  }

  &30 {
    padding-left: 30px !important;
  }

  /* Table td/th */
  &12 {
    padding-left: 12px !important;
  }
}

.childPr {
  &0 {
    input {
      padding-right: 0 !important;
    }
  }
}

.pr {
  &0 {
    padding-right: 0 !important;
  }

  &3 {
    padding-right: 3px !important;
  }

  &5 {
    padding-right: 5px !important;
  }

  &6 {
    padding-right: 6px !important;
  }

  &8 {
    padding-right: 8px !important;
  }

  &10 {
    padding-right: 10px !important;
  }

  &12 {
    padding-right: 12px !important;
  }

  &16 {
    padding-right: 16px !important;
  }

  &20 {
    padding-right: 20px !important;
  }

  /* Table td/th */
  &12 {
    padding-right: 12px !important;
  }
}

.pb {
  &0 {
    padding-bottom: 0 !important;
  }

  &3 {
    padding-bottom: 3px !important;
  }

  &4 {
    padding-bottom: 4px !important;
  }

  &5 {
    padding-bottom: 5px !important;
  }

  &6 {
    padding-bottom: 6px !important;
  }

  &8 {
    padding-bottom: 8px !important;
  }

  &10 {
    padding-bottom: 10px !important;
  }

  &12 {
    padding-bottom: 12px !important;
  }

  &16 {
    padding-bottom: 16px !important;
  }

  &20 {
    padding-bottom: 20px !important;
  }

  &40 {
    padding-bottom: 40px !important;
  }
}

.pt {
  &0 {
    padding-top: 0 !important;
  }

  &3 {
    padding-top: 3px !important;
  }

  &5 {
    padding-top: 5px !important;
  }

  &6 {
    padding-top: 6px !important;
  }

  &8 {
    padding-top: 8px !important;
  }

  &10 {
    padding-top: 10px !important;
  }

  &12 {
    padding-top: 12px !important;
  }

  &16 {
    padding-top: 16px !important;
  }

  &20 {
    padding-top: 20px !important;
  }

  &30 {
    padding-top: 30px !important;
  }
}

.MuiGrid-spacing-xs-2>.MuiGrid-item {
  &.customSpacing {
    padding: 16px 0px 16px 16px !important;
  }

  &.customSpacingSecondary {
    padding: 16px 0px 16px 32px !important;
  }

  &.customSpacingAutocomplete {
    padding: 16px 16px 16px 16px !important;
  }

  &.pr16 {
    padding-right: 16px !important;
  }

  &.pb0 {
    padding-bottom: 0 !important;
  }

  &.pt0 {
    padding-top: 0 !important;
  }
}

/* Displays */
.dFlex {
  display: flex !important;
}

.dBlock {
  display: block !important;
}

.dNone {
  display: none !important;
}

.justifyStart {
  justify-content: flex-start !important;
}

.justifyCenter {
  justify-content: center !important;
}

.justifyBetween {
  justify-content: space-between !important;
}

.justifyAround {
  justify-content: space-around !important;
}

.justifyEnd {
  justify-content: flex-end !important;
}

.verticalAlignBaseline {
  vertical-align: baseline !important;
}

.alignCenter {
  align-items: center !important;
}

.alignStart {
  align-items: flex-start !important;
}

.alignEnd {
  align-items: flex-end !important;
}

.flexRow {
  flex-direction: row !important;
}

.flexColumn {
  flex-direction: column !important;
}

.flexWrap {
  flex-wrap: wrap !important;
}

/* Visibilities */
.visibilityHidden {
  visibility: hidden;
}

.visibilityVisible {
  visibility: visible;
}

/* Positions */
.pRelative {
  position: relative !important;
}

.pAbsolute {
  position: absolute !important;
}

.pSticky {
  position: sticky !important;
}

.pFixed {
  position: fixed !important;
}

/* Sizes */
.w100 {
  width: 100% !important;
}

.h {
  &0 {
    height: 0 !important;
  }

  &100 {
    height: 100% !important;
  }
}

.hAuto {
  height: auto !important;
}

.wAuto {
  width: auto !important;
}

.mW100 {
  max-width: 100% !important;
}

.mH80 {
  max-height: 80% !important;
}
.minHeight {
  &Auto {
    min-height: auto !important;
  }

  &0 {
    min-height: 0 !important;
  }
}

/* Text Alignments */
.textAlignRight {
  text-align: right !important;
}

.childTextAlignRight {
  input {
    text-align: right !important;
  }

  label {
    margin-left: auto;
  }
}

.textAlignCenter {
  text-align: center;
}

.textAlignLeft {
  text-align: left !important;
}

.labelAlignLeft {
  text-align: left !important;
  
  label {
    text-align: left !important;
  }
}

.uppercase {
  text-transform: uppercase !important;
}

.spanChildUppercase {
  span {
    text-transform: uppercase !important;
  }
}

.lowercase {
  text-transform: lowercase;
}

.lowercaseInput {
  input {
    text-transform: lowercase;

    &::placeholder {
      text-transform: initial;
    }
  }
}

.capitalize {
  text-transform: capitalize;
}

.initialTransform {
  text-transform: initial !important;
}

/* Borders */
.borderNone {
  border: none !important;
}

.noBorderRadius {
  &Left {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }

  &Right {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  &Top {
    border-top-right-radius: 0px !important;
    border-top-left-radius: 0px !important;
  }

  &Bottom {
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }
}

/* Autocomplete Multiple Tags */
.MuiAutocomplete-tagSizeSmall {
  margin: 0 !important;
}

.MuiAutocomplete-endAdornment {
  right: 0 !important;
}

/* Floats */
.floatRight {
  float: right;
}

.floatLeft {
  float: left;
}

/* Tooltips */
.MuiTooltip-popper {
  .MuiTooltip-tooltip {
    background: #313347 !important;
    font-family: 'Lato' !important;
    color: #fff !important;
    padding: 2px 6px;
    margin: 0;
    max-width: 500px !important;
  }

  p,
  span {
    color: #fff;
  }

  .MuiTooltip-arrow {
    &:before {
      border-color: transparent transparent #313347 transparent !important;
    }
  }

  .MuiTooltip-tooltipPlacementRight {
    padding: 0;

    .MuiTooltip-arrow {
      &:before {
        border-color: transparent #9296ac !important;
      }
    }
  }

  .MuiTooltip-tooltipPlacementLeft {
    padding: 0;
    max-width: 400px !important;

    .MuiTooltip-arrow {
      &:before {
        border-color: transparent transparent transparent #9296ac !important;
      }
    }
  }
}

/* Cursor */
.cursorPointer {
  cursor: pointer !important;
}

.cursorAuto {
  cursor: auto !important;
}

.cursorMove {
  cursor: move !important;
}

.disableSelection {
  user-select: none !important;
}

#spreadsheetImport {
  &::-webkit-file-upload-button {
    margin-left: 20px;
  }

  input {
    margin-left: 20px;
  }
}

/* Ant Design Tree Selector */
.ant-select-dropdown {
  position: relative !important;
  z-index: 9999999 !important;
  width: 430px !important;
}

.so-input-focus {
  box-shadow: none !important;
}

.so-treeSelect-result {
  .so-treeSelect-input {
    text-align: left;
  }
}

.ant-select-tree-list-scrollbar {
  display: block;
}

.ant-select-selector {
  border: none !important;
  border-bottom: solid 1px #000 !important;
  padding-left: 0 !important;
  background: transparent !important;
  border-radius: 0 !important;

  input {
    cursor: text !important;
  }

  .ant-select-selection-item,
  .ant-select-selection-placeholder {
    font-size: 12px !important;
    color: #77798b !important;
    font-family: 'Lato' !important;
  }

  .ant-select-selection-placeholder {
    font-style: italic !important;
    opacity: 0.5 !important;
  }
}

.ant-select-arrow {
  right: 0 !important;
  position: absolute !important;
  display: flex !important;
}

.ant-select-clear {
  right: 22px !important;
  position: absolute !important;
  display: flex !important;

  svg {
    bottom: -2px;
    position: absolute;
  }
}

.ant-select-selection-search {
  left: 0 !important;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 30px !important;
}

.ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none !important;
}

.ant-select-disabled {
  color: rgba(0, 0, 0, 0.38);

  .ant-select-selector {
    border-color: rgba(0, 0, 0, 0.26) !important;
  }

  input {
    cursor: auto !important;
  }
}

/* Material Clearable Icon */
.searchByTermMode {
  .customClearIcon {
    visibility: hidden;
    cursor: pointer;
  }

  .MuiAutocomplete-clearIndicator {
    display: none !important;
  }

  .MuiInputBase-root {
    &:hover {
      .customClearIcon {
        visibility: visible;
      }
    }
  }
}

/* Pull To Refresh Bubbles */
.customPullToRefresh {
  overflow: visible !important;

  .lds-ellipsis {
    div {
      background: #080b30;
    }
  }

  .ptr__pull-down--pull-more {
    display: none !important;
  }

  .ptr__children {
    overflow: visible;
  }
}
 .heightTableMobile {
   height: auto;

  @media (max-width: 1200px) {
      height: 60px;
    }
  }
/* Disable Link */
.disabledLink {
  pointer-events: none;
}
.estimatedEconomy {
  border: 1px solid black;
  border-radius: 8px;
  padding: 4px;
  gap: 4px;
  background-color: #D2F9D6;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.iconBloom {
  background-color: #5062F0;
  padding: 5px;
  border-radius: 10px;
}

.iconBloomDisabled {
  background-color: #9296AC;
  padding: 5px;
  border-radius: 10px;
}
.valueEstimated {
  margin: 0;
  font-weight: 700;
  color: #5062F0;
}

.valueEstimatedDisabled {
  margin: 0;
  font-weight: 700;
  color: #9296AC;
}

.disabledField {
  color: #9296AC
}

.percentEstimated {
  height: 17px;
  font-size: 12px;
  background-color: #fff;
  border: 1px solid #5062F0;
  border-radius: 8px;
  border-style: dashed;
  padding: 0 5px;
  p {
      margin: 0;
      font-weight: 700;
      color: #5062F0;
    }
  }
  
  .percentEstimatedDisabled {
    height: 17px;
    font-size: 12px;
    background-color: #fff;
    border: 1px solid #9296AC;
    border-radius: 8px;
    border-style: dashed;
    padding: 0 5px;
  
    p {
      margin: 0;
      font-weight: 700;
      color: #9296AC;
    }
}