.userFormDialog {
  .MuiPaper-root {
    max-width: 1080px;
  }

  .storeCloneStepViewBox {
    padding-right: 0;
  }

  .autocompleteSmall {
    .MuiInputBase-root {
      padding-left: 0;
    }
  }
}
