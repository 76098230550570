// Colors
$primary:        #8d9092;
$secondary:      #383d41;
$success:        #155724;
$danger:         #721c24;
$error:          #E22121;
$warning:        #856404;
$info:           #0c5460;
$dark:           #1b1e21;
$black:          #010311;
$default:        #818182;
$white:          #FFFFFF;
$lightGray:      #EAEAEA;
$disabledGray:   #9296AC;
$materialBlue:   #3f51b5;
$navyBlue:       #080B30;
$otherBlue:      #454A67;
$lightBlue:      #5062F0;

// Background Colors
$bgPrimary:      #cce5ff;
$bgSecondary:    #e2e3e5;
$bgSuccess:      #d4edda;
$bgDanger:       #f8d7da;
$bgError:        #E22121;
$bgWarning:      #fff3cd;
$bgInfo:         #d1ecf1;
$bgDark:         #d6d8d9;
$bgDefault:      #fefefe;
$bgWhite:        #fefefe;
$bgNavyBlue:     #080B30;
$bgMainBlue:     #030303;
$bgOtherBlue:    #454A67;
$bgLightBlue:    #5062F0;

h1,
h2,
h3,
h4,
h5,
h6,
p,
i,
figcaption,
a,
span,
li,
label,
strong,
select,
td,
th,
div,
svg {
  &.blue {
    color: $primary;
  }

  &.gray {
    color: $secondary;
  }

  &.success {
    color: $success;
  }

  &.danger {
    color: $danger;
  }

  &.error {
    color: $error;
  }

  &.warning {
    color: $warning;
  }

  &.info {
    color: $info;
  }

  &.dark {
    color: $dark;
  }

  &.black {
    color: $black;
  }

  &.default {
    color: $default;
  }

  &.white {
    color: $white !important;
  }

  &.lightGray {
    color: $lightGray;
  }

  &.disabledGray {
    color: $disabledGray;
  }

  &.materialBlue {
    color: $materialBlue !important;
  }

  &.navyBlue {
    color: $navyBlue;
  }

  &.otherBlue {
    color: $otherBlue;
  }

  &.lightBlue {
    color: $lightBlue !important;

    td {
      color: $lightBlue !important;
    }
  }
}

.bgPrimary {
  background: $bgPrimary;
}

.bgSecondary {
  background: $bgSecondary;
}

.bgSuccess {
  background: $bgSuccess;
}

.bgDanger {
  background: $bgDanger;
}

.bgError {
  background: $bgError;
}

.bgWarning {
  background: $bgWarning;
}

.bgInfo {
  background: $bgInfo;
}

.bgDark {
  background: $bgDark;
}

.bgDefault {
  background: $bgDefault;
}

.bgNavyBlue {
  background: $bgNavyBlue !important;
}

.bgMainBlue {
  background: $bgMainBlue;
}

.bgWhite {
  background: $bgWhite !important;
}

.bgOtherBlue {
  background: $bgOtherBlue !important;
}

.bgLightBlue {
  background: $bgLightBlue !important;
}

.noBg {
  background: transparent !important;
}
