.totalBar{
  height: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px 14px;
  background-color: #5062F0;
  margin: 10px 0px;
  border-radius: 4px;
  font-weight: 700;
  font-size: 12px;
  color: #fff;
  span.notes{
    margin-right: 90px;
  }
}
.invoiceTable{
  min-width: 403px !important;
  &.singleInvoiceTable{
    width: 770px !important;
    margin: 10px;
  }
  margin: auto;
  margin-bottom: 16px;
  background-color: #fff;
  filter: drop-shadow(0px 0px 8px rgba(4, 6, 26, 0.25));
  thead th{
    background-color: #333541 !important;
    font-family: Lato;
    color: #fff !important;
    font-size: 9px !important;
    font-weight: 700;
    font-style: normal;
    text-transform: uppercase;
    border-right: 1px solid #454A67;
    text-align: right;
    &.alignLeft{
      text-align: left;
    }
    &:last-child{
      border-right: none;
    }
  }
  tbody{
    tr{
      border-bottom: 1px solid #454A67;
      &:last-child{
        border-bottom: none;
      }
      td{
        height: 33px;
        border-right: 1px solid #454A67;
        font-size: 12px;
        text-align: right;
        &.alignLeft{
          text-align: left;
        }
        &:last-child{
          border-right: none;
        }
        .invoiceLink{
          color: #5062F0;
          cursor: pointer;
        }
      }
    }
  }
}

.filterWrapper{
  width: 100%;
  height: 72px;
  margin: 13px auto;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 12px;
  box-shadow: 0px 0px 8px 0px rgba(4, 6, 26, 0.25);
  div{
    margin-right: 0px !important;
  }
}

.invoiceDialog {
  .MuiPaper-root {
    width: 50%;

    .textFieldSmall{
      width: 27%;
      margin-top: 16px;

      input {
        padding-top: 7px;
      }
    }
  }

  &.invoiceDialogPerItem {
    .MuiPaper-root {
      width: 100%;

      .totalBar {
        padding: 2px 16px;
        
        .leftInfos,
        .rightInfos {
          display: flex;
          align-items: center;

          span {
            display: block;
          }
        }

        .leftInfos {
          width: 26%;
          justify-content: space-between;

          span {
            &:last-of-type {
              margin-right: 11%;
            }
          }
        }

        .rightInfos {
          width: 74%;
          justify-content: flex-end;

          span {
            text-align: right;
            width: 17%;
            padding-right: 1.7%;

            &:first-of-type {
              width: 14%;
              padding-right: 29.5%
            }

            &:last-of-type {
              width: 14%;
              padding-right: 0;
            }
          }
        }
      }

      table {
        thead {
          th {
            padding: 8px 12px;
  
            &:first-of-type {
              padding-left: 16px;
            }
  
            &:last-of-type {
              padding-right: 16px;
            }
          }
        }

        tbody {
          td {
            padding: 0 12px;

            &:first-of-type {
              padding-left: 16px;
            }

            &:last-of-type {
              padding-right: 16px;
            }
          }
        }
      }
    }
  }
}