.parametersPage {
  .userInfos {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    p {
      font-size: 11px;
      color: #313347;
      font-family: "Lato";
      margin-bottom: 0;

      strong {
        color: #5062F0;
        margin-left: 5px;
      }
    }
  }

  /* Boxes */
  .boxDefault {
    background: #fff;
    padding-bottom: 20px;

    .boxTitle {
      background: #313347;
      padding: 8px 12px;

      h5 {
        color: #FFF;
        font-size: 12px;
        font-family: "Lato";
        font-weight: bold;
        text-transform: uppercase;
        margin: 0;
      }
    }

    .item {
      padding: 16px 12px 0;
      display: flex;
      align-items: center;

      svg {
        color: #5062F0
      }

      h7,
      label,
      span {
        color: #313347;
        font-size: 12px;
        font-family: "Lato";
      }

      h7 {
        margin: 0 20px 0 0;
      }

      label {
        margin-bottom: 0;

        .MuiButtonBase-root {
          padding: 0 5px;
        }
      }

      .textFieldSmall {
        fieldset {
          border-bottom: solid 1px #5062F0;
        }
      }
    }
  }
}