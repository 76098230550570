.backgroundLogin {
    background-image: url('../../../images/backgrounds/login.svg');
    background-repeat: no-repeat;
    background-position: 0px;
    background-size: cover;
}

.radius {
    background-color: #FFFFFF;
    border-radius: 10px;
}

.backgroundTitle {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    background-color: #080B30;
    height: 92px;
}

.fontColor {
    color: #080B30;
}

.logoTitle {
    padding: 24px 78px;

    img {
        width: 154px;
        height: 44px;
    }
}

.inputStyle {
    div {

        input {
            padding: 2px 0;
            font-size: 12px;
        }

        input::placeholder {
            font-size: 12px;
            font-family: 'Lato', sans-serif;
            font-style: italic;
            color: #B8BFF5;

        }


    }

    div::before {
        border-bottom: 1px solid #8B97F2 !important;
    }
}

.gapItens {
    gap: 20px;
}

.buttonEnter {
    width: 100%;
    padding: 0 11px 10px;
    
    button {
        padding: 4px 65px;
        border-radius: 10px;

        span {
            font-size: 12px;
            font-weight: 700;
            line-height: 14.4px;
            letter-spacing: 0.2px;
        }
    }
}