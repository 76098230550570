.groupButton {
    width: 480px;
    height: 32px;
    margin-bottom: 15px;
}

.buttonAllClients {
    width: 170px;
    height: 32px;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 12px !important;
    line-height: 16px !important;
    background-color: #fff !important;
    border: 1px solid #5062f0 !important;
    box-sizing: border-box !important;
    border-radius: 8px !important;
    color: #5062f0 !important;
}

.buttonEnableAssociated {
    width: 135px;
    height: 32px;
    margin-right: 8px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 12px !important;
    line-height: 16px !important;
    background-color: #fff !important;
    border: 1px solid #5062f0 !important;
    box-sizing: border-box !important;
    border-radius: 8px !important;
    color: #5062f0 !important;
}

.buttonAllClients:hover,
.buttonEnableAssociated:hover {
    color: #fff !important;
    background-color: #5062f0 !important;
}

.buttonAllClients:focus,
.buttonEnableAssociated:focus {
    color: #fff !important;
    background-color: #5062f0 !important;
}

.space {
    margin-right: 11px;
    margin-left: 11px;
}

.btn-active {
    color: #fff !important;
    background-color: #5062f0 !important;
}
