/* Float Button */
.floatButtonFirst,
.floatButtonSecond {
  position: fixed !important;
  right: 15px;
  background-color: rgba(63, 81, 181, 0.6) !important;

  &:hover {
    background-color: rgba(63, 81, 181, 1) !important;
  }

  &:disabled {
    background-color: rgba(143, 143, 145, 0.6) !important;
  }
}

.floatButtonFirst {
  bottom: 86px !important;
}

.floatButtonSecond {
  bottom: 156px !important;
}

.uploadMultipleFilesButton {
  cursor: pointer;

  input,
  span,
  img {
    cursor: pointer !important;

    &::-webkit-file-upload-button {
      cursor: pointer !important;
    }
  }
}

.defaultButton {
  display: flex !important;
  border-radius: 8px !important;
  padding: 12px 16px !important;
  line-height: normal !important;
  font-size: 16px !important;
  font-family: 'Lato' !important;
  text-transform: initial !important;
  letter-spacing: normal !important;
}

.defaultButtonFs12 {
  display: flex !important;
  border-radius: 8px !important;
  padding: 12px 16px !important;
  line-height: normal !important;
  font-size: 12px !important;
  font-family: 'Lato' !important;
  text-transform: initial !important;
  letter-spacing: normal !important;
}

.errorButton {
  background: #F53D4C !important;
  color: #FFF !important;

  &:disabled {
    color: #b8bbcc !important;
    border: solid 1px #b8bbcc !important;
  }
}

.submitButton {
  background: #5062f0 !important;
  color: #fff !important;

  &:disabled {
    color: #b8bbcc !important;
    border: solid 1px #b8bbcc !important;
  }
}

.secondActionButton {
  border: 1px solid #5062f0;
  background: #ffffff !important;
  color: #5062f0 !important;

  &:disabled {
    color: #b8bbcc !important;
    border: solid 1px #b8bbcc !important;
  }
}

.disabledButton {
  transition: all 0.5s ease;
  background: rgba(210, 211, 215, 1) !important;
  color: rgba(102, 103, 112, 1) !important;
  border: solid 1px #b8bbcc !important;
}

.backButton {
  background: transparent !important;
  color: #313347 !important;
  border: solid 1px #313347 !important;

  &:disabled {
    color: #b8bbcc !important;
    border: solid 1px #b8bbcc !important;
  }
}

.sizeButtonMenu {
  max-width: 160px;
  height: 32px;

  span {
    font-size: 12px;
  }
}
.toggleDefault {
  &.small {
    .MuiSwitch-root {
      width: 35px;
      height: 18px;
    }

    .MuiSwitch-switchBase {
      top: 2.2px;
      left: 1px;
    }

    .MuiSwitch-switchBase.Mui-checked {
      transform: translateX(19px);
    }
  }

  &.tagsListSwitch {
    .MuiSwitch-root {
      padding: 0;

      .MuiButtonBase-root {
        padding: 2px;
      }
    }
  }

  .MuiSwitch-root {
    width: 55px;
    height: 40px;
  }

  .MuiSwitch-switchBase {
    top: 6px;
    left: 5px;
    color: #fafafa;
  }

  .MuiSwitch-thumb {
    width: 10px;
    height: 10px;
  }

  .MuiSwitch-colorSecondary.Mui-checked {
    color: #fff;
  }

  .MuiSwitch-colorSecondary.Mui-checked+.MuiSwitch-track {
    background-color: #5062f0 !important;
    opacity: 1;
  }

  .MuiSwitch-switchBase.Mui-checked {
    transform: translateX(17px);
  }

  .MuiSwitch-track {
    background-color: #000 !important;
    opacity: 1;
    border-radius: 25px;
  }

  .MuiFormControlLabel-label {
    font-size: 12px;
    font-family: 'Lato';
    font-weight: bold;
  }
}

.toggleDefaultSquares {
  &.small {
    .MuiSwitch-root {
      width: 35px;
      height: 18px;
    }

    .MuiSwitch-switchBase {
      top: 2.2px;
      left: 1px;
    }

    .MuiSwitch-switchBase.Mui-checked {
      transform: translateX(19px);
    }
  }

  &.tagsListSwitch {
    .MuiSwitch-root {
      padding: 0;

      .MuiButtonBase-root {
        padding: 2px;
      }
    }
  }

  .MuiSwitch-root {
    width: 53px;
    height: 44px;
  }

  .MuiSwitch-switchBase {
    top: 12%;
    right: 14%;
    color: #fafafa;
  }

  .MuiSwitch-thumb {
    width: 15.6px;
    height: 15.6px;
  }

  .MuiSwitch-colorSecondary.Mui-checked {
    color: #fff;
  }

  .MuiSwitch-colorSecondary.Mui-checked+.MuiSwitch-track {
    background-color: #5062f0 !important;
    opacity: 1;
  }

  .MuiSwitch-switchBase.Mui-checked {
    transform: translateX(8px);
  }

  .MuiSwitch-track {
    background-color: #000 !important;
    opacity: 1;
    border-radius: 25px;
  }

  .MuiFormControlLabel-label {
    font-size: 12px;
    font-family: 'Lato';
    font-weight: bold;
  }
}

.toggleDefaultSquaresProjection {
  &.small {
    .MuiSwitch-root {
      width: 35px;
      height: 18px;
    }

    .MuiSwitch-switchBase {
      top: 2.2px;
      left: 2px;
    }

    .MuiSwitch-switchBase.Mui-checked {
      transform: translateX(19px);
    }
  }

  &.tagsListSwitch {
    .MuiSwitch-root {
      padding: 0;

      .MuiButtonBase-root {
        padding: 2px;
      }
    }
  }

  .MuiSwitch-root {
    width: 52px;
    height: 44px;
  }

  .MuiSwitch-switchBase {
    top: 12%;
    right: 14%;
    color: #fafafa;
  }

  .MuiSwitch-thumb {
    width: 15.6px;
    height: 15.6px;
  }

  .MuiSwitch-colorSecondary.Mui-checked {
    color: #fff;
  }

  .MuiSwitch-colorSecondary.Mui-checked+.MuiSwitch-track {
    background-color: #5062f0 !important;
    opacity: 1;
  }

  .MuiSwitch-switchBase.Mui-checked {
    transform: translateX(8px);
  }

  .MuiSwitch-track {
    background-color: #000 !important;
    opacity: 1;
    border-radius: 25px;
  }

  .MuiFormControlLabel-label {
    font-size: 12px;
    font-family: 'Lato';
    font-weight: bold;
  }
}

.toggleDefaultProcess {
  &.small {
    .MuiSwitch-root {
      width: 35px;
      height: 18px;
    }

    .MuiSwitch-switchBase {
      top: 2.2px;
      left: 1px;
    }

    .MuiSwitch-switchBase.Mui-checked {
      transform: translateX(19px);
    }
  }

  &.tagsListSwitch {
    .MuiSwitch-root {
      padding: 0;

      .MuiButtonBase-root {
        padding: 2px;
      }
    }
  }

  &.withWhiteBorder {
    .MuiSwitch-track {
      border: solid 1px #FFF;
    }

    .MuiTypography-root {
      color: #FFF;
      font-weight: normal;
      margin-left: -3px;
    }
  }

  .MuiSwitch-root {
    width: 53px;
    height: 44px;
  }

  .MuiSwitch-switchBase {
    top: 12%;
    right: 14%;
    color: #fafafa;
  }

  .MuiSwitch-thumb {
    width: 15.6px;
    height: 15.6px;
  }

  .MuiSwitch-colorSecondary.Mui-checked {
    color: #fff;
  }

  .MuiSwitch-colorSecondary.Mui-checked+.MuiSwitch-track {
    background-color: #5062f0 !important;
    opacity: 1;
  }

  .MuiSwitch-switchBase.Mui-checked {
    transform: translateX(8px);
  }

  .MuiSwitch-track {
    background-color: #000 !important;
    opacity: 1;
    border-radius: 25px;
  }

  .MuiFormControlLabel-label {
    font-size: 12px;
    font-family: 'Lato';
    font-weight: bold;
  }
}

.toggleDefaultBlueContainer {
  &.small {
    .MuiSwitch-root {
      width: 35px;
      height: 18px;
    }

    .MuiSwitch-switchBase {
      top: 2.2px;
      left: 1px;
    }

    .MuiSwitch-switchBase.Mui-checked {
      transform: translateX(19px);
    }
  }

  .MuiSwitch-root {
    width: 50px;
    height: 40px;
  }

  .MuiSwitch-switchBase {
    top: 6px;
    left: 5px;
    color: #fafafa;
  }

  .MuiSwitch-thumb {
    width: 8px;
    height: 8px;
    margin-top: 1px;
    margin-left: 1px;
  }

  .MuiSwitch-colorSecondary.Mui-checked {
    color: #fff;
  }

  .MuiSwitch-colorSecondary.Mui-checked+.MuiSwitch-track {
    background-color: #5062f0 !important;
    opacity: 1;
  }

  .MuiSwitch-switchBase.Mui-checked {
    transform: translateX(12px);
  }

  .MuiSwitch-track {
    background-color: #1f2445 !important;
    opacity: 1;
    border-radius: 25px;
  }

  .MuiFormControlLabel-label {
    font-size: 12px;
    font-family: 'Lato';
    font-weight: bold;
  }
}

.noStyle {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}