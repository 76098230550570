.modalWrap {
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: none;
  top: 0px;
  left: 0px;
  z-index: 9999;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);

  &.is-active {
    display: flex;
  }
}

.backgroundModal {
  display: block;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
}

.simpleModal {
  z-index: 2;
  display: flex;
  width: 90%;
  max-width: 540px;
  padding: 12px;
  flex-direction: column;
  border-radius: 8px;
  background-color: #5062F0;

  &--header {
    border-bottom: 2px solid #F7D56E;
    position: relative;
    padding-bottom: 8px;

    &__title {
      color: #F7D56E;
      font-size: 16px;
      font-family: Lato;
      font-weight: 700;
      text-transform: uppercase;
    }

    &__closeBtn {
      position: absolute;
      top: 0px;
      right: 0px;
      width: 24px;
      height: 24px;
      background-color: transparent;
      border: none;
      outline: none;
      cursor: pointer;

      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 8px;
        left: 50%;
        width: 20px;
        height: 1px;
        background-color: #F7D56E;
        transform: translate(-50%, -50%) rotate(45deg);
      }

      &::after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }

  &--content {
    margin-top: 14px;

    h1,
    h2,
    h3,
    h4 {
      color: #F2F3F8;
      font-size: 16px;
      font-family: Lato;
      font-weight: 700;
    }

    p {
      color: #F2F3F8;
      font-size: 14px;
      font-family: Lato;
      font-weight: 400;
    }
  }
}