.updateDiscardDialog {
  .cardDefault {
    .textFieldSmall {
      width: 40%;
      margin-left: 30px;

      .MuiInputBase-root {
        margin-top: 6px;
      }
    }
  }
}
