.salesNfePage {
  display: flex !important;

  .pageTitleAndFilterMainContainer {
    .pageTitleAndFilterTitleCard {
      position: initial !important;
    }

    h2 {
      left: 11px;
      position: absolute;
    }
  }
}

/* Sales Nfe Score Board Infos */
.salesScoreCardInfosContainer {
  margin: 0 0 15px 0;
  max-height: 83vh;
  height: auto;
  width: 30%;
  margin-top: 38px;

  .cardDefault {
    padding-bottom: 5px !important;
    padding-top: 12px !important;

    .MuiInputBase-root {
      margin-bottom: 10px !important;
    }

    .dateSmall {
      width: 35%;

      &:first-of-type {
        margin-right: 20px;
      }

      .MuiInputBase-root {
        margin: 0 0 16px 0;
      }
    }
  }

  table {
    margin-top: 22px !important;
  }
}