.searchInLineBox {
    position: absolute !important;
    width: 425px;
    height: 113px;

    .submitButton {
        padding: 8px !important;
        margin-top: 10px;
        width: 80%;
        margin-left: auto;
    }

    .MuiPopover-paper {
        width: 100%;
        max-width: 100%;
        height: 100%;
        max-height: 100%;
    }
    
    .boxItem {
        .textFieldSmall {
            .MuiInputBase-root {
                height: 30.75px;
            }
        }
    }
}