/* Brand and Ean Suggestion dialogs*/
.brandSuggestionDialog,
.eanSuggestionDialog {
  .modalHeader {
    p {
      text-transform: inherit;
    }
  }

  .MuiPaper-root {
    width: 500px;
  }

  .suggestionItem {
    padding: 8px 0;
    border-bottom: solid 1px #D0D3E2;

    &:first-of-type {
      padding-top: 0;
    }

    &:last-of-type {
      padding-bottom: 0;
      border-bottom: none;
    }
  }

  .suggestionMainInfo {
    align-items: center;

    .suggestionName {
      margin-left: 5px;
      text-transform: uppercase;

      &.relevant {
        color: #5062F0;
      }
    }

    fieldset {
      .MuiSvgIcon-root {
        color: #5062F0;
      }

      label {
        margin: 0;
      }

      span {
        display: flex;
        align-items: center;
        padding: 0;
      }
    }

    img {
      &.rotate {
        transform: rotate(180deg);
      }
    }
  }

  .suggestionLinks {
    a {
      margin-bottom: 5px;

      &:first-of-type {
        margin-top: 8px;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

/* GENERAL SUGGESTIONS DIALOG */
.generalSuggestionsDialog {
  width: 95%;

  .radioMainInfo {
    align-items: center;

    fieldset {
      .MuiSvgIcon-root {
        color: #FFFFFF;
      }

      label {
        margin: 0;
      }

      span {
        display: flex;
        align-items: center;
        padding: 0;
        text-decoration: none !important;
      }
    }

    .MuiFormControlLabel-label {
      &.Mui-disabled {
        color: #FFF !important;
      }
    }
  }

  .modalHeader {
    p {
      text-transform: inherit;
      display: flex;
      align-items: center;

      span {
        text-decoration: underline;
        font-weight: normal;
        font-size: 12px;
      }
    }
  }

  .MuiPaper-root {
    width: 100%;
    max-width: unset;
    margin: 0;
  }

  .generalSuggestionModalContent {
    padding: 0;
  }

  .brandSuggestionDialog,
  .eanSuggestionDialog,
  .googleSearchImageDialog {
    .modalHeader {
      padding: 8px 16px !important;

      p {
        font-size: 12px;
      }
    }
  }

  .googleSearchImageDialog {
    .imageOptionsContainer {
      max-height: 430px;
      overflow: auto;
      margin-right: -3px;
      overflow-x: hidden;

      .imageOptionBox {
        width: 174px;
        float: left;
        margin: 0 12px 12px 0;
  
        &:last-of-type,
        &:nth-of-type(3n) {
          margin-right: 0;
        }
      }
    }

    .searchBox {
      .textFieldSmall {
        width: 16vw;

        input {
          padding-right: 10px;
        }
      }
    }

    .MuiLinearProgress-root {
      height: 5px;
      width: 97%;
    }
  }
}