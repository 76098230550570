.productionPage {
  .transferReportHeader {
    width: 100%;
    background: #FFFFFF;
    padding: 10px 12px;
    box-shadow: 0px 0px 8px 0px rgba(4, 6, 26, 0.25);
    border-radius: 4px 4px 0 0;
    overflow: hidden;

    span {
      font-size: 12px;
    }

    .totalInfosValue {
      border-radius: 4px;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      padding: 3px 5px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .MuiSvgIcon-fontSizeSmall {
      font-size: 1rem;
    }
  }
} 