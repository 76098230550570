/* Quotation Manager - Dialog Option Valid Column */
.customValidTooltipDialogOption {
  padding: 2px 6px;
  border-radius: 4px;
  margin: -2px -6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 10px;
}

/* Indicators Values */
.indicatorValueTooltips {
  padding: 2px 6px;
  border-radius: 4px;
  margin: -2px -6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #D0D3E2;
  color: #000319 !important;
  font-weight: bold;
}