.cmvTable {
  table {
    .linearProgressBar {
      border-left: 1px solid #9296AC;
    }

    thead {
      .defaultHeader {
        text-align: center;
      }

      .defaultSubtitle {
        border-right: 1px solid #9296AC;
      }

      #restaurants {
        width: 20%
      }

      .column-item {
        width: 8%;
      }

      .cmvSwitchRealPercent {
        text-align: right;

        label {
          margin-bottom: 0;

          span {
            font-size: 11px;
            font-weight: bold;
          }

          &:last-of-type {
            margin-right: 0;
          }
        }
      }
    }

    tbody {
      td {
        &.right {
          span {
            display: block;
            text-align: right;
          }
        }
      }
    }
  }
}

/* List */
.menuListTableCmv {
  padding: 0;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 0px 8px rgba(4, 6, 26, 0.25);

  .column-cmv-final,
  .column-item {
    min-width: 92px;
  }

  .toggleDefault {
    position: absolute;
    top: -5px;
    right: 10px;
  }

  .blackLink,
  .whiteLink {
    text-decoration: underline;
    padding: 8px 0;

    td {
      text-decoration: underline;
    }
  }

  .borderRightBlack {
    border-right: solid 1px #000 !important
  }

  .borderBottomBlack {
    border-bottom: solid 2px #010311 !important
  }

  .borderTopBlack {
    border-top: solid 2px #010311 !important
  }

  .blackLink {
    color: #313347;

    td {
      color: #313347;
    }
  }

  .whiteLink {
    color: #FFF;

    td {
      color: #FFF;
    }
  }

  table {
    width: 100%;
    border-spacing: 0;
    font-family: "Lato";
    font-size: 11px;
    background: #fff;
    border-collapse: collapse;

    .defaultHeader {
      background: #313347;
      color: #FFF;
      position: sticky;
      top: -10px;
      z-index: 999;
      padding: 8px 12px;
      border-right: 1px solid #9296AC;
    }

    tr {
      :last-child {
        td {
          background: #fff;
          border-bottom: 0;
        }
      }

      &:hover {
        td {
          background: #A8B1F8 !important;
          color: #FFF !important;

          td {
            background: transparent;
          }
        }
      }
    }

    thead {
      tr {
        th {
          &:first-of-type {
            border-top-left-radius: 4px;
          }

          &:last-of-type {
            border-top-right-radius: 4px;
          }
        }

        th,
        td {
          &.defaultSubtitle {
            border-bottom: 1px solid #9296AC;
            border-left: transparent;
            position: sticky;
            top: 21px;
            z-index: 999;
            background: #F6F6F8;
            color: #77798B;
            text-align: left;
            vertical-align: bottom;
            text-align: right;

            p {
              text-align: right;
            }

            &.column-sales-average {
              border-left: 1px solid #001373;
            }

            &.column-total-cost {
              border-left: 1px solid #73004C;
            }

            &.column-cmv-final {
              background: #D9E1F2;
              color: #000;
              font-weight: bold;
            }

            &.column-sales-average-discount,
            &.column-percentage-discount,
            &.column-quantity,
            &.column-total,
            &.column-margin,
            &.column-cost,
            &.column-cmv {
              border-left: none !important;
            }
          }
        }
      }
    }

    tbody {
      tr {
        height: 41px;

        &.rowDepth0 {
          td {
            background: rgb(48, 84, 150);
          }

          td,
          p {
            font-weight: bold;
            color: #FFF;
          }
        }

        &.rowDepth1 {
          td {
            background: rgb(180, 198, 231);

            // &:first-of-type {
            //   padding-left: 30px;
            // }
          }

          td,
          p {
            font-weight: bold;
          }
        }

        &.rowDepth2 {
          td {
            // &:first-of-type {
            //   padding-left: 60px;
            // }
          }
        }
      }

      td {
        &.defaultSubtitle {
          .MuiGrid-item {
            span {
              display: none;
            }
          }
        }
      }
    }

    th,
    td {
      background: #fff;
      margin: 0;
      padding: 4px 7px;
      border-bottom: 1px solid #9296AC;
      border-right: 1px solid #9296AC;

      span {
        display: flex;
        align-items: center;
      }

      &.withoutLeftBorder {
        border-left: none !important;
      }

      &.withoutRightBorder {
        border-right: none !important;
      }

      &.column-cmv-final {
        background: #D9E1F2;
        color: #000;
        font-weight: bold;
      }

      &.withoutRightBorder {
        border-right: none !important;
      }

      &.withRightDefaultBorder {
        border-right: solid 1px #9296AC !important;
      }

      &.withLeftDefaultBorder {
        border-left: solid 1px #9296AC !important;
      }
    }
  }

  .action {
    background: #fff;
    width: 100px;
  }

  .column-total-cost,
  .column-cost,
  .column-mkp,
  .column-cmv {
    border-left: 1px solid #73004C !important;
    border-right: none !important;
  }

  .column-sales-average,
  .column-sales-average-discount,
  .column-percentage-discount,
  .column-quantity,
  .column-total,
  .column-margin {
    border-left: 1px solid#001373 !important;
    border-right: none !important;
  }

  .column-item {
    a {
      display: inline-block;
      vertical-align: -1px;
      margin-left: 5px;
    }
  }

  .column-sales-average,
  .column-sales-average-discount {
    width: 6%;
  }

  .column-total-cost {
    width: 7%;
  }

  .column-percentage-discount,
  .column-quantity,
  .column-margin,
  .column-cmv,
  .column-mkp {
    width: 5%;
  }

  .column-total,
  .column-cost {
    width: 7%;
  }

  .column-mkp {
    border-left: 1px solid #9296AC;
  }
}

