/* Register */
.salesProjectionForm {
  .fakeRadioBox {
    cursor: pointer;

    a {
      color: #5062F0;

      &.positive {
        color: #009934;
      }

      &.negative {
        color: red;
      }

      &.incomplete {
        color: gray;
      }

      span {
        color: #000;
      }
    }
  }

  .fakeRadio {
    width: 15px;
    height: 15px;
    background: transparent;
    border: solid 1px #0B0D1D;
    border-radius: 20px;
    margin: 3px 10px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &.active {
      border: solid 4px #5062F0;
    }
  }

  .headerCell {
    font-size: 12px;
    font-weight: normal;
    border-left: solid 1px #BFC0CB;
    font-family: "Lato";
    padding: 10px 12px;

    &:first-of-type {
      border-left: none;
    }
  }

  .headerCell-projection {
    font-size: 12px;
    font-weight: normal;
    border-left: solid 1px #666770;
    font-family: "Lato";
    padding: 0 12px;

    &:first-of-type {
      border-left: none;
    }
  }

  .weekCell {
    border-top: solid 1px #333541;
    max-width: 166px;

    .dayBox {
      position: absolute;
      height: 100px;
      width: 19px;
      left: 0;
      top: 0;
      background: #ECEDF1;
      color: #000;
      display: flex;
      align-items: start;
      justify-content: center;
    }

    .colorDay {
      color: #77798B;
    }

    .valueBox {
      width: 100%;
      display: flex;
      padding: 0 !important;

      div {
        width: 86%;

        @media (max-width: 1500px) {
          width: 86%;
        }
      }

      img {
        width: 30px;
        z-index: 1;
      }
    }

    .projectionValue {
      display: flex;
      width: 64%;
      align-items: center;
      justify-content: space-between;
    }

    .cardProjection {
      border-left: 1px solid #666770;
      position: relative;
      width: 100%;
      height: 100px;
      flex-direction: column;
    }

    .valueForecast {
      padding: 0;
      z-index: 1;
      width: 100%;
      display: flex;
      align-items: center;
      text-align: start;
      background-color: #99a4ff6e;
      font-size: 16px;
      font-weight: 700;
      margin-top: 27px;
      margin-bottom: 8px;

      p {
        margin: 0 !important;
        padding: 0 2px;
        text-align: center;
        width: 19px;
      }

      span {
        margin-left: 5px;
        margin-right: 10px;
        font-size: 12px;
        opacity: 1;
      }
    }

    .disabledValue {
      color: #9296AC
    }

    .adjustInput {
      font-size: 16px;
      color: #5062F0;

      fieldset {
        border: none;
        border-bottom: solid 1px #5062F0;
      }

      input {
        margin-bottom: 0;
        padding: 0 !important;
      }
    }

    .borderNone {
      fieldset {
        border-bottom: none !important;
      }
      input {
        padding: 0 !important;
      }
    }
    
    .positionFlex {
      width: 64px;
      display: flex;
      margin-bottom: 7px;
      padding: 0 0 0 5px;

      div {
        div {
          width: 100%;
        }
      }
    }

        .disabledColor {
          div {
            div {
              input:disabled {
                color: #000319 !important;
              }
            }
          }
        }
.disabledColorChanged {
  div {
    div {
      input:disabled {
        color: #5062F0 !important;
      }
    }
  }
}
    .coloredValue {
      div {
        div {
          input {
            color: #5062F0 !important;
          }
        }
      }
    }

        .coloredValueBlack {
          div {
            div {
              input {
                color: #000319 !important;
              }
            }
          }
        }

    .fontSize {
      div {
        div {

          input {
            font-size: 16px !important;
          }
        }
      }
    }
  }


  tr {
    td {
      border-left: solid 1px #BFC0CB;

      &:first-of-type {
        border-left: none;
      }
    }
  }
}

.containerDescription {
  display: flex;
  gap: 10px;

  div {
    width: 20px;
    height: 20px;
  }

  p {
    margin: 0;
  }
}

.dollarSign {
  width: 20px;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  color: #5062F0
}

.metaStore {
  align-items: flex-start;
  padding-bottom: 5px;
}

.descriptionFont {
  font-size: 12px;
  font-weight: 400;
  color: #666770;
  &.blue{
    color: #5062F0;
  }
}

.positiveValue {
  width: 100%;
  color: #5062F0 !important;
  font-size: 12px !important;
}

.negativeValue {
  width: 100%;
  color: #F53D4C !important;
  font-size: 12px !important;
}

.dateWeather{
  display: flex;
  justify-content: space-between;
  gap: 10px;
  min-height: 25px;
  width: 100%;
  position: absolute;
  top: 7px;
  span{
    display: flex;
    img{
      width: 16px;
      height: 16px;
      margin-right: 4px;
    }
  }
  .holiday{
    margin-left: 24px;
  }
  .weather{
    display: flex;
    justify-content: space-between;
    margin-right: 5px;
    gap: 15px;
    font-size: 10px;
    min-width: 50%;
  }
}

.rSubtitle{
  margin-left: 36px;
  .boxIcon{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
    img{
      width: 14px;
    }
  }
}

.isHoliday{
  background-color: #DADDF3 !important;
}

.alertRain{
  color: #F53D4C;
  font-weight: 700;
}