
.sidebar {
  max-width: 100%;
  height: 100vh;
  border: 0;
  padding-top: 2px;
  display: flex;
  overflow: hidden;
}

.sidebar-list-menu {
  // width: 40%;
  width: 100%;
  padding-bottom: 60px !important;
  overflow: auto;
}

.sidebar-notifications-box {
  width: 60%;
  background: #FFF;
  padding: 20px 20px 100px 20px;
  display: flex;
  flex-direction: column;
  overflow: auto;

  .main-notification {
    background: #FCC558;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 12px;
    margin-bottom: 12px;

    span {
      font-size: 16px;
      color: #FFF;
    }
  }

  thead,
  tfoot {
    background: #F5F5FA;
  }
  
  thead,
  tbody,
  tfoot {
    padding: 5px;
    display: table-caption;

    tr {
      display: table;
      width: 100%;
    }
  }

  td {
    p {
      color: #000;

      &.positive {
        color: #6BE394;
      }

      &.negative {
        color: #EE6F6F;
      }

      &.done {
        color: #5062F0;
      }
    }
  }

  .topic-name {
    color: #000;
    font-size: 16px;
    display: flex;
    align-items: center;

    .card-alert {
      background: #EE6F6F;
      width: 12px;
      height: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #FFF;
      margin-left: 2px;
      font-size: 10px;
      padding: 5px;
      border-radius: 50px;
    }
  }

  td {
    p {
      font-size: 14px;
    }
  }
}

.sidebar-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px 15px !important;
}

.sidebar-item-fixed {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px 15px 2px 10px !important;
}

.sidebar-item-fixed:hover {
  background: #5062F0 !important;
  padding: 2px 15px 2px 10px !important;
}

.sidebar-item-img {
  max-width: 18px;
}

.sidebar-item:hover {
  background: #5062F0 !important;
  padding: 2px 15px !important;
}

.sidebar-subitem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px 15px !important;
}

.sidebar-subitem:hover {
  background: #5062F0 !important;
  padding: 2px 15px !important;
}

.sidebar-item:hover .sidebar-item-content .sidebar-item-text .sidebar-label {
  color: #FFF !important;
  font-weight: bold;
}

.sidebar-item:hover .sidebar-item-expand-arrow {
  color: #FFF !important;
  font-weight: bold;
}

.sidebar-subitem:hover .sidebar-item-content .sidebar-item-text .sidebar-label {
  color: #FFF !important;
  font-weight: bold;
}

.sidebar-subitem:hover .sidebar-item-expand-arrow {
  color: #FFF !important;
  font-weight: bold;
}

.sidebar-item-content {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: flex;
  align-items: center;
  width: 100%;
}

.sidebar-item-text {
  display: flex;
  align-items: center;
}

.sidebar-item-text svg {
  color: #FFF;
}

.sidebar-item-text .notifications-number {
  color: #fff;
  font-size: 10px;
  background: #EE6F6F;
  width: 15px;
  height: 15px;
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  font-weight: bold;
}

.sidebar-item-text .notifications-number.closed {
  top: 5px;
  right: 7px;
}

.sidebar-item-text .notifications-number.opened {
  top: auto;
  right: 90px;
}

.sidebar-item-text.active {
  background: #5062F0;
}

.sidebar-item-icon {
  font-size: 32px; 
  margin-right: 15px;
  flex: 1;
  display: flex;
}

.sidebar-item-icon-home {
  font-size: 32px; 
  margin-right: 10px;
  flex: 1;
  display: flex;
}

.sidebar-label {
  width: 100%;
  font-family: 'Lato';
  font-size: 14px;
  color: #FFF !important;
  line-height: 38px !important;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  flex: 1;
  display: flex;
}

.sidebar-label:hover {
  color: #FFF !important;
  font-weight: bold;
}

.sidebar-subitem .sidebar-label {
  width: 100%;
  font-family: 'Lato';
  font-size: 12px;
  color: #FFF !important;
  line-height: 33px !important;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  flex: 1;
  display: flex;
}

.sidebar-subitem .sidebar-label:hover {
  color: #FFF !important;
  font-weight: bold;
}

.sidebar-item-expand-arrow {
  font-size: 1.2rem !important;
  cursor: pointer;
}

.sidebar-item-expand-arrow-expanded {
  color: #FFF !important;
  font-weight: bold;
}

.MuiCollapse-wrapperInner {
  margin-left: 40px;
}

.MuiDrawer-docked {
  display: contents;
}

.MuiAppBar-root {
  width: 100% !important;
}

.MuiDrawer-paper {
  top: 65px !important;
  flex: 1 0 auto;
  height: 100%;
  display: flex;
  outline: 0;
  z-index: 1200;
  overflow-y: auto;
  flex-direction: column;
  -webkit-overflow-scrolling: touch;
  padding-top: 29px;
  position: sticky;
}

.MuiCollapse-wrapperInner {
  margin-left: 0 !important;
}
