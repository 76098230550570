
.rankingContainer {
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  box-shadow: 0px 0px 8px rgba(4, 6, 26, 0.25);
  margin-top: 2%;
  border-radius: 4px;

  .cmvMkpRadio {
    border: none;
    padding-top: 0;
  }
}

// .generalOrders {
//   .content {
//     .qtdeContent { margin-left: 1px; }
//     .valueContent { margin-right: 10px; width: 20.5%; }
//     .infContent { margin-right: 3px; width: 13%; }
//     .vxcContent { margin-left: 5.5px; width: 18%; }
//   }
// }

.generalTicket {
  .content {
    .deliveryContent { 
      // width: 22%; 
      // span { 
        // margin-right: 17px; 
      // } 
    }
  }
}

#popOver {
  box-shadow: 0px 0px 8px rgba(4, 6, 26, 0.25);

  .MuiPopover-paper {
    overflow-x: hidden !important;
    min-height: 195px !important;
  }

  div {
    width: 130px !important;
    border-radius: 4px !important;
  }
}

.inputDiv {
  margin-top: 10px;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  width: 130px;
  height: 120px;
  border-radius: 4px;
  background: #FFFFFF;  
  margin-bottom: 10px;
  $checkbox-width: 18px;
  input[type="checkbox"] {
    position: absolute;
    left: -9999px;

    & + label {
      position: relative;
      display: inline-block;
      padding: 6px 10px 6px ($checkbox-width + 4);
      cursor: pointer;
      margin-left: 2px;
      font-family: Lato;
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      line-height: 16px;
      font-feature-settings: 'tnum' on, 'lnum' on;
      color: #313347;

      &:before {
        display: block;
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        margin: (-$checkbox-width/2) 0 0;
        width: $checkbox-width;
        height: $checkbox-width;
        background-image: url("../../images/app-icons.png");
        background-repeat: no-repeat;
        background-size: 72px auto;
        -webkit-background-size: 72px auto;
        -moz-background-size: 72px auto;
      }
    }

    &:checked + label {
      &:before {
        background-position: (-$checkbox-width) 0;
      }
    }

    &:disabled {
      & + label {
        cursor: not-allowed;

        &:before {
          background-position: (-$checkbox-width*2) 0;
        }
      }

      &:checked + label {
        &:before {
          background-position: (-$checkbox-width*3) 0;
        }
      }
    }
  }

  .form-checkbox{
    position: relative;
    display: block;
  }
}

.RankingMainInformation {
  width: 1700px;
  display: flex;
  flex-direction: row;
  border-radius: 4px;

  div {
    .rankingContent, .fixed {
     
      width: 18px !important;
      display: flex;
      align-items: center;
      align-items: center !important;
      text-align: center !important;
      justify-items: center !important;
      justify-content: center !important;
      justify-content: space-around;
      background: #313347;
      border-radius:0 !important ;

      strong {
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 11px;
        line-height: 11px;
        display: flex;
        align-items: center !important;
        text-align: center !important;
        justify-items: center !important;
        justify-content: center !important;
        text-transform: uppercase;
        font-feature-settings: 'tnum' on, 'lnum' on;
        height: 100%;
        border-bottom: 1px solid #ECEDF1;
        width: 16px;
        color: #ECEDF1 !important;
      }
    }

    

    .restContent {

      border-radius: 0;

      span {
        background: none;
        border-right: 1px solid #313347;
        color: #313347;
        font-size: 11px;
        width: 100%;
        text-align: left;
        border-bottom: 1px solid #ECEDF1;
      }

      
    }

    .content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      div {
       
        border-right: 1px solid #ECEDF1;
        align-items: center;
      }

      span {
        color: #313347;
        font-size: 11px;
        background: none;
        width: 100%;
        text-align: right;
        // min-width: 72.8px;
        border-bottom: 1px solid #ECEDF1;
      }
    }


    display: flex;
    flex-direction: column;

    .divisor0,  .divisor1,  .divisor2,  .divisor3,
      .divisor4,  .divisor5,  .divisor6,  .divisor7,  .divisor8 {
      background: #77798B;
      height:25px;
      border: none;

      span {
        font-family: 'Lato';
        font-style: normal;
        // font-weight: bold;
        font-size: 11px;
        line-height: 11px;
        text-transform: uppercase;
        font-feature-settings: 'tnum' on, 'lnum' on;
        color: #FFFFFF;
        background: #77798B;
        padding: 8px;
      }

      strong {
        font-family: 'Lato';
        font-style: normal;
        font-weight: bold;
        font-size: 11px;
        line-height: 11px;
        text-transform: uppercase;
        font-feature-settings: 'tnum' on, 'lnum' on;
        color: #FFFFFF;
        padding: 8px;
      }
    }

    .eye {
      margin-right: 5px;
      margin-bottom: 3px;
      width: 12px;
      height: 12px;
      cursor: pointer;
    }
    
    span {
      font-family: 'Lato';
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      line-height: 11px;
      text-transform: uppercase;
      font-feature-settings: 'tnum' on, 'lnum' on;
      color: #FFFFFF;
      height: 28px;
      background: #313347;
      padding: 5px;    
    } 

    small {
      background: #F6F6F8;
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 11px;
      line-height: 12px;
      text-transform: uppercase;
      font-feature-settings: 'tnum' on, 'lnum' on;
      color: #77798B;
      cursor: pointer;
      transition: color 0.2s;

      img {
        width: 8px;
        height: 8px;
        margin-left: 5px;
        margin-bottom: 3px;

        &:hover {
          fill: #313347;
        }
      }
      
      &:hover {
        color: #313347;
      }
    }
    
  }

  .ranking {
    width: 220px;
    //box-shadow: 0px 6px 6px rgba(4, 6, 26, 0.25);
    border-radius: 4px 0 0 0;
    span:first-child {
      border-radius: 4px 0 0 0;
    }

    .divisor0 {
      margin-top: 5px;
    }

    small {
      border-right: 1px solid #313347;
      background: none;
    }
  }

  .first {
    border-right: 1px solid #313347;
    border-top: 3px solid #313347;
    
    span {
      background: none;
      margin-top: 10px;
     
      align-items: flex-end;
      font-family: 'Lato';
      font-style: normal;
      font-weight: bold;
      font-size: 11px;
      line-height: 12px;
      text-align: right;
      font-feature-settings: 'tnum' on, 'lnum' on;
      color: #313347;
    }
  }

  .total {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top: 3px solid #313347;
   
    strong {
      width: 16px;
      background: #313347;

    }
 
    span, small {
      width: 100%;
      height: 100%;
      margin-top: 12px;
      margin-left: 10px;
      background: none;
      text-align: center;
      font-weight: bold;
      
      cursor: default;
      font-family: 'Lato';
      font-style: normal;
      font-size: 11px;
      line-height: 12px;
      font-feature-settings: 'tnum' on, 'lnum' on;
      color: #313347;

    }
  }

 

  .vendas {
    //box-shadow: 0 4px 8px 0px rgba(4, 6, 26, 0.25);
    width: 310px;
    border-right: 1px solid #BFC0CB;
    .divisor0 { 
      margin-top: 5px; 
      width: 104%;
      margin-left: -7px;
 }
    .divisor1, .divisor2, .divisor3, .divisor4, .divisor5 { margin-top: 4px;
      width: 104%;
      margin-left: -7px;
    }
    
  }

  .ticket {
    //box-shadow: 0 4px 8px 0px rgba(4, 6, 26, 0.25);
    width: 200px;
    border-right: 1px solid #BFC0CB;
    .divisor0 { margin-top: 5px; 
    
      width: 104%;
      margin-left: -7px;
}
    .divisor1, .divisor2, .divisor3, .divisor4, .divisor5 { margin-top: 4px;
    
      width: 104%;
      margin-left: -7px;
    }
  }

  .pedidos {
    //box-shadow: 0 4px 8px 0px rgba(4, 6, 26, 0.25);
    width: 320px;
    border-right: 1px solid #BFC0CB;


    .divisor0 { 
      
      width: 104%;
      margin-left: -7px;
      margin-top: 5px; }
    .divisor1, .divisor2, .divisor3, .divisor4, .divisor5 { 
      
      width: 104%;
      margin-left: -7px;
      margin-top: 11px; }

  }

  .estoque {
    //box-shadow: 0 4px 8px 0px rgba(4, 6, 26, 0.25);
    width: 210px;
    border-right: 1px solid #BFC0CB;
    
    .divisor0 { margin-top: 5px; 
    
      width: 104%;
      margin-left: -7px;

    }
    .divisor1, .divisor2, .divisor3, .divisor4, .divisor5 {
      
      width: 104%;
      margin-left: -7px;
      margin-top: 11px; }
  }

  .cmv {
    width: 300px;
    border-right: 1px solid #BFC0CB;
    //box-shadow: 6px 6px 6px 0px rgba(4, 6, 26, 0.25);

    .cmvAndMkpCase {
      display: flex !important;
      flex-direction: row !important;
      background: #313347;

      .toggleDefault {
        position: relative;
        margin: 0;
        width: 60%;

        .MuiFormGroup-row {
          flex-direction: row;

          span {
            background: transparent;
            font-size: 11px;
            margin: 0 !important;
            padding: 2px;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          label {
            margin: 0;
          }

          .MuiIconButton-label {
            span {
              height: 5px;
              width: 5px;
              border: solid 1px;
              padding: 6px;
              display: block;

              &:before {
                margin-top: -5px;
                margin-left: -5px;
                height: 10px;
                width: 10px;
              }
            }
          } 
        }
      }
    }

    .divisor0 { 
      margin-top: 5px;
      width: 102.5%;
      margin-left: -7px;
    }
    .divisor1, .divisor2, .divisor3, .divisor4, .divisor5 { 
      width: 102.5%;
      margin-left: -7px;
      margin-top: 11px; }
      border-radius: 0 4px 4px 0;

    span:first-child {
      border-radius: 0 4px 0 0;
    }
  }

  .rowed {
    display: flex;
    margin-top: 9px;
    flex-direction: row;
    justify-content: space-around;
    padding-bottom: 3px;

    small {
      background: none;
    }
  }

  
  .bolded {
    font-weight: bold;
  }
  
  .firstContent {
    background: linear-gradient(180deg, rgba(80, 98, 240, 0.75) 27px, #FFFF 1%);
    background-size: 40px;    
  
    div {
      
      span:first-child {
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 16px;
        text-align: right;
        font-feature-settings: 'tnum' on, 'lnum' on;
        color: #FFFFFF;
      }

    }
  }

  .restFirstContent {
    background: linear-gradient(180deg, #5062F0 27px, #FFFF 1%);
    background-size: 40px; 

    div {
      span:first-child {
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 12px;
        font-feature-settings: 'tnum' on, 'lnum' on;
        color: #FFFFFF;
      }
       
    }
  
  }
  
}

.hoveredLine {
  background-color: red;
  &:hover{ 
    background-color: blue;
  }
}

