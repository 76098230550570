.cmvDashboardPage {
  .fakeHeaderAndFilterBox {
    display: flex;
    flex-direction: column;

    .fakeHeaderBox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin: 5px 0 8px 0;
      
      h1 {
        font-weight: bold;
        font-size: 16px;
        margin: 0;
      }
    }

    .fakeFilterBox {
      box-shadow: 0px 0px 8px 0px #04061A40;
      display: flex;
      align-items: center;
      border-radius: 4px;
      background-color: #FFF;
      margin-bottom: 16px;
      max-height: 500px;
      transition: all 0.3s;

      &.closedFilter {
        max-height: 0;
        transition: all 0.3s;
        margin-bottom: 0;
        overflow: hidden;
        padding: 0;
      }

      label {
        font-size: 11px;
      }
  
      .dateSmall {
        width: 5%;
        margin-bottom: 0;

        .MuiInputBase-root {
          margin-top: 0;
          padding-top: 0 !important;

          .MuiInputAdornment-root {
            margin: 5px -1px 0 0;

            svg {
              width: 30px;
            }
          }
        }

        &:last-of-type {
          margin-left: 10px;
        }
      }
    }
  }
  
  .cmvDashboardContainer {
    width: 45%;
    
    .cmvSalesCard,
    .cmvTheoreticalCard,
    .cmvPerformedInfosCard {
      box-shadow: 4px 4px 4px 0px #04061A80;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      border-radius: 12px;
      padding: 10px;
      min-height: 70px;
      
      .cardTitle {
        font-size: 12px;
        margin-bottom: 10px;
      }

      span {
        font-size: 16px;
      }
    }

    .cmvTheoreticalCard,
    .cmvPerformedInfosCard {
      width: calc(100% / 2 - 5px);
    }

    .cmvSalesCard,
    .cmvPerformedInfosCard {
      p,
      span {
        color: #FFFFFF;
      }
    }

    .cmvSalesCard {
      background: #5062F0;
      margin-bottom: 10px;

      .toggleDefault {
        position: absolute;
        right: 0;

        .MuiFormControlLabel-label {
          font-size: 12px;
        }

        .MuiSwitch-track {
          border: solid 1px #FFF;
        }
      }
    }

    .cmvTheoreticalCard {
      background: #F2B90D;
      margin-right: 10px;

      p,
      span {
        color: #454A67;
      }
    }

    .cmvPerformedInfosCard {
      background: #0B1A8E;
    }

    .percentChip {
      border-radius: 50px;
      box-shadow: 4px 4px 4px 0px #00000080;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-weight: bold;
      width: 65px;
      padding: 2px 4px;
      position: absolute;
      right: 0;
      font-size: 12px;

      &.positive {
        background: #D2F9D6;
        color: #006608;
      }

      &.negative {
        background: #F7D4D7;
        color: #F53D4C;
      }

      &.disabledTheoretical {
        background: #D0D3E2 !important;
        color: #454A67 !important;
      }

      &.disabled {
        background: #D0D3E2 !important;
        color: #006608 !important;
      }
    }

    .cmvChartInfosCard {
      box-shadow: 3px 3px 5px 1px #04061A80;
      border-radius: 10px;
      background: #FFF;
      margin-top: 10px;
      padding: 15px 10px 0px;
    }
  }
}

.salesTargetAndDaysBox {
  border-top: dashed 1px #FFF;
  border-bottom: dashed 1px #FFF;
  margin: 7px 0;
  padding: 7px 0;

  p {
    display: flex;
    margin-bottom: 3px;
    font-weight: bold;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  span {
    margin: 0 3px;
    font-weight: 400;

    &:first-of-type {
      min-width: 60px;
      display: block;
      text-align: right;
    }
  }
}