.labelFileToPrint {
  width: 220px;
  height: 95px;
  // border: solid 1px #d6d8d9;
  display: flex;
  padding: 2px 2px;
  background: #FFF;
  position: relative;
  z-index: 999999;
  // transform: translateY(-100%) rotate(90deg);
  // transform-origin: bottom left;

  .testClass {
    display: flex;
    flex-direction: column;
    height: 100%;

    .optionsBox {
      margin-top: 3px;
    }
  }

  .labelFileTitle {
    p {
      margin-bottom: 0px;
      font-size: 10px;
    }
  }

  .item {
    margin-top: 0px;
  }

  p,
  span {
    font-size: 8px;

    td {
      font-size: 8px !important;
      margin-bottom: 0;
    }
  }

  p {
    margin-bottom: 0;
  }

  input {
    width: 10px;
    height: 10px;
  }
}
