.alert-error {
  color: #FFF;
  background: #f44336;
}

.snackBarError {
  .alert-success {
    background: rgba(244, 67, 54, .8);
    color: #FFF;
    border: 1px solid rgb(229, 115, 115);
  }
}

.snackBarWarning {
  .alert-success {
    background: rgb(255, 226, 183);
    color: rgb(102, 60, 0);
    border: 1px solid rgb(255, 183, 77);
  }
}