.registerStoreDialog {
  width: 90%;

  .storeCloneStepViewBox {
    padding-right: 6px;
  }

  .cardTitle {
    width: 180px;
    border-radius: 4px 4px 0px 0px;
    margin-top: 15px;
  }

  .mainCardBox,
  .footerActions {
    background: #F2F2F4;
  }

  .currencyTextFieldSmall {
    .MuiInputBase-root {
      margin-top: 7px;
    }
  }
}

.confirmCancelStoreCreationDialog {
  .modalContent,
  .MuiDialogActions-root {
    background-color: rgba(255, 242, 204, .5) !important
  }
}

.storeCloneStepViewBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #F2F3F8;
  border-radius: 4px;
  box-shadow: 0px 4px 4px 2px #00000040;
  padding: 0px 8px 0px 10px;

  span {
    font-size: 12px;
    margin-right: 10px;
  }

  .stepBox {
    display: flex;
    align-items: center;
    padding: 4px 6px;

    &.active {
      background: rgba(117, 131, 240, .4)
    }
  }
}

.createStoreStepTwo,
.createStoreStepThree,
.createStoreStepFour {
  .modalContent {
    padding: 0 !important;

    .storeCloneStepViewBox {
      margin-bottom: 15px;
      width: 100%;
      justify-content: space-between;
    }

    p {
      padding: 0 20px;
      margin-bottom: 10px;
    }
  }

  .defaultButton {
    &:first-of-type {
      margin-right: 0 !important;
    }
  }
}