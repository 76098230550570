/* Modals */
.addressTable {
  table {
    thead {
      th {
        background: #313347 !important;
        color: #FFF !important;
      }
    }
  }
}

.providerRestaurantModal {
  .MuiDialog-paper {
    height: 99.5%
  }

  .modalContent {
    overflow: hidden;
  }

  .providerRestaurantModalBox {
    width: 43%;

    .boxTitle {
      font-weight: bold;
      font-size: 16px;
      margin-bottom: 0;
      color: #000319;
    }

    .cardDefault {
      max-height: 0;
      overflow: hidden;
      transition: all .5s ease-in-out;
      padding: 0;
      margin: 0;

      &.showFilter {
        max-height: 20vh;
        overflow: hidden;
        transition:all .5s ease-in-out;
        padding-top: 30px;
        margin-bottom: 10px;
      }
    }
  }

  .MuiFormControlLabel-root {
    .MuiButtonBase-root {
      padding: 0 9px
    }

    svg {
      width: 17px;
    }

    .MuiFormControlLabel-label {
      font-size: 12px;
    }
  }

  table {
    .addIcon,
    .deleteIcon {
      cursor: pointer;
    }

    .addIcon {
      height: 20px;
    }

    .deleteIcon {
      margin-left: 5px;
      margin-right: -5px;
    }

    tbody {
      max-height: 34.5vh;
      display: flex;
      flex-direction: column;
      overflow: auto;
      transition: max-height .5s ease-in-out;

      &.expand {
        max-height: 54vh;
        transition: max-height .5s ease-in-out;
      }

      td {
        padding-top: 2px !important;
        padding-bottom: 2px !important;
        display: flex;

        .MuiCheckbox-root,
        td {
          padding-top: 0;
          padding-left: 0;
          padding-bottom: 0;
        }
      }
    }
  }

  .providerRestaurantModalActions {
    margin-top: 24.7vh;

    button {
      padding: 10px 15px !important;
    }
    
    span {
      font-size: 12px;
    }
  }
}

.providerAndStoreDialog {
  .toggleDefault {
    .MuiFormControlLabel-label {
      width: 108px;
      text-align: left;
    }
  }
}