.quoteSolicitationsActionButtonsContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;

  .start-date, .end-date {
    width: 118px;
    margin: 0px 20px 0px 0px;
  }

  .start-date input, .end-date input {
    font-size: 12px;
  }

  .start-date label, .end-date label {
    font-family: 'Lato';
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: #00020F;
  }
  
  button {
    padding: 5px 10px 5px 9px !important;
    margin-right: 5px;

    &:last-of-type {
      margin-right: 0;
    }

    span {
      font-size: 12px;
      font-family: 'Lato';
      padding-right: 5px;
    }
  }
}

.quoteSolicitationsTable {
  td {
    .fileActionsBox {
      display: flex; 

      span {
        padding: 0;
        margin: 5px 5px 5px 0;

        .MuiIconButton-label {
          margin-right: 0;
        }
      }
    }

    .statusBox {
      padding: 0 10px;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;

      &.notDownloaded {
        background: #F2F2F4;
        
        p {
          color: #A0A1A7;
        }
      }

      &.fullUploaded {
        background: #DFF7E8;
        
        p {
          color: #3DCC6E;
        }
      }

      &.partialUploaded {
        background: #FCF5E3;
        
        p {
          color: #FFBF3E;
        }
      }

      &.downloaded {
        background: #F7DFDF;
        
        p {
          color: #E22121;
        }
      }
    }
  }
}

/* Modals */
.prioritySkusDialog {
  .filterButton {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #333541;
    border-radius: 4px;
    padding: 4px 5px;
    width: 20px;
    height: 20px;
    margin: 0 auto;
    cursor: pointer;
  }

  .linkItem {
    color: #5062F0;
    cursor: pointer;
  }
}