.headerTopInfo{
  display: flex;
  align-items: center;
  gap: 28px;
  background-color: #DADDF3;
  padding: 5px 13px;
  p{
    margin: 0px;
    display: flex;
    align-items: center;
    span{
      display: inline-flex;
      align-items: center;
      gap: 5px;
      margin-right: 10px;
    }
    &.weatherInfo{
      font-size: 10px;
    }
    &.holidayInfo{
      font-size: 9px;
    }
  }
  
}

.content{
  padding: 15px 9px;
  .controllers{
    display: flex;
    gap: 29px;
    align-items: center;
    .fixValue{
      margin-top: 15px;
      width: 107px;
      position: relative;
      span{
        display: block;
        &.label{
          position: absolute;
          top: -20px;
          width: 107px;
          font-family: Lato;
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: 16px;
          text-align: right;
        }
        &.value{
          font-family: Lato;
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: 16px;
          color: #5062F0;
          height: 15px;
          display: flex;
          align-items: end;
          justify-content: flex-end;
          width: 107px;
          cursor: pointer;
        }
      }
    }
    .controllerInput{
      width: 100px;
      input{
        padding-right: 5px;
      }
    }
    .btnSuggestion{
      button{
        width: 115px;
        height: 32px;
        border-radius: 8px;
        background-color:#5062F0;
        border: none;
        font-family: Lato;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        color: #fff;
        cursor: pointer;
      }
    }
  }
  .table{
    max-width: 470px;
    margin-top:19px;
    position: relative;
    .trDefault{
      height: 32px;
      border-bottom: 1px solid #D2D3D7;
      &:last-of-type{
        border-bottom: none;
      }
    }
    .projectionTd{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .negativeValue{
      color: #F53D4C;
      font-weight: 700;
    }
    .positiveValue{
      color: #089163;
      font-weight: 700;
    }
  }
}