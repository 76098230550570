@font-face {
  font-family: 'iconoz';
  src:  url('iconoz.eot?pvylo3');
  src:  url('iconoz.eot?pvylo3#iefix') format('embedded-opentype'),
    url('iconoz.ttf?pvylo3') format('truetype'),
    url('iconoz.woff?pvylo3') format('woff'),
    url('iconoz.svg?pvylo3#iconoz') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="ico-"], [class*=" ico-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'iconoz' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.MuiSvgIcon-root {
  color: #8d8d8d;
}

.ico-home:before {
  content: "\e902";
  color: #8d8d8d;
}
.ico-home_active:before {
  content: "\e902";
  color: #5062F0;
}
.ico-catalog:before {
  content: "\e903";
  color: #8d8d8d;
}
.ico-catalog_active:before {
  content: "\e903";
  color: #5062F0;
}
.ico-cataloguingSku:before {
  content: "\e903";
  color: #8d8d8d;
}
.ico-cataloguingSku_active:before {
  content: "\e903";
  color: #5062F0;
}
.ico-menu:before {
  content: "\e904";
  color: #8d8d8d;
}
.ico-orders:before {
  content: "\e905";
  color: #8d8d8d;
}
.ico-receipts:before {
  content: "\e903";
  color: #8d8d8d;
}
.ico-receipts_active:before {
  content: "\e906";
  color: #5062F0;
}
.ico-stocks:before {
  content: "\e907";
  color: #8d8d8d;
}
.ico-productions:before {
  content: "\e908";
  color: #8d8d8d;
}
.ico-inventories:before {
  content: "\e909";
  color: #8d8d8d;
}
.ico-adminRestaurant:before {
  content: "\e90a";
  color: #8d8d8d;
}
.ico-adminPlatform:before {
  content: "\e90a";
  color: #8d8d8d;
}
.ico-cards:before {
  content: "\e908";
  color: #8d8d8d;
}
