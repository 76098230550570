.title {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
}

.cards {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 30px;
}