.addTagContainer {
  display: flex;
  flex-direction: column;
  width: 100%;

  .addTagTable {
    .headerSubtitle {
      .subtitle {
        font-size: 9px;
      }
    }
  }

  .createTagButton {
    border-radius: 8px;
    border: 1px solid #5062F0;
    padding: 0 6px 0 0 !important;

    p {
      font-size: 12px;
      color: #5062F0;
      font-weight: bold;
    }

    img {
      height: 10px;
      width: 10px;
    }
  }

  .submitButton {
    font-size: 12px !important;
    padding: 6.5px 6px !important;
    margin-left: 5px;
  }
}
